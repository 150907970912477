import { useState, useEffect } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, showSuccess } from '../../../common/Common';
import { ChangeAutopayMethod } from '../../../services/PaymentServices';
import { LoaderContents } from "../../layout/LoaderContents";

export default function CreditCardForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");

    };

    const UpsertCreditCardPaymentInfo = () => {
        setIsLoading(true);
        const cardElement = elements.getElement(CardElement);

        stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        }).then(function (result) {
            if (typeof result.paymentMethod !== 'undefined') {
                // Handle result.error or result.paymentMethod
                ChangeAutopayMethod(result.paymentMethod.id, JSON.parse(localStorage.getItem("UserCredential"))?.CustomerId).then(data => {
                    let response = data;
                    if (response.status === true) {
                        window.$('#modalChangeAccountInfo').modal('hide');
                        showSuccess(response.message);
                        cardElement.clear();
                        setIsLoading(false);
                        $("#radio-CreditCard").prop("checked", true);
                        $("#radio-ACH").prop("checked", false);
                        $("#radio-CreditCard").prop("disabled", true);
                        $("#radio-ACH").prop("disabled", true);
                    }
                });
            }
            else {
                setIsLoading(false);
            }
        });
        
    }

    return (
        <>
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <br />
            <div className="input-wrapper-payment mb-3 text-center">
                <Button disabled={isLoading} type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => UpsertCreditCardPaymentInfo()}>
                    <span id="button-text">
                        {isLoading ? <div className="spinner-border"></div> : "Submit"}
                    </span>
                </Button>
            </div>
        </>
    )
}