import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import {InternalLayoutHeader} from "../../layout/InternalLayoutHeader";

class PrivacyPolicy extends Component {
    //this for agent user
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        var SubDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain;
        return (
            <>
                <main>
                    <InternalLayoutHeader />
                    <div className="main-wrapper-newDesign padding-space">
                        <div className="row justify-content-md-center">
                            <div className="col-md-auto">
                                <div className="contain-main">
                                    <div className="section-gapping">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h1 className="termsofuseheader">
                                                        {SubDomain} Privacy Policy
                                                    </h1>
                                                    <p>{SubDomain} Insurance takes your privacy very seriously. Please take a moment to review our Privacy Policy to see how we protect the information you provide to us via this website.</p>
                                                    <p>Effective Date: July 1, 2012</p>
                                                    <p>This Privacy Notice applies to personal information collected on or from www.{SubDomain}insurance.com and www.InsurEntertainment.com (the “Site”) and email correspondence sent by or to {SubDomain} Insurance and insurentertainment.com.</p>
                                                    <p>By visiting and using our Site, you indicate that you accept the practices described in this Privacy Notice.</p>
                                                    <p> Please remember that whenever you leave our Site to visit a linked website, you are subject to the terms and policies of that other website. We are not responsible for the privacy practices of the other website. Please review those other terms and policies before proceeding.</p>
                                                    <p>We may have collected personal information from you before this Privacy Notice took effect. By using this Site, you consent to our continued use of previously collected personal information under these new terms. If you do not want us to do so, please contact us.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Policy Summary</p>
                                                    <p>
                                                        This Privacy Notice explains what personal information we collect; what we do with it; and options you can exercise.
                                                    </p>
                                                    <ul>
                                                        <li>We collect personal information through our Site and from email correspondence related to our Site.</li>
                                                        <li>Some of this personal information is associated with a specific person. We use it to respond to specific requests made by that person. We may also use it to personalize our Site or our emails for that person.</li>
                                                        <li>We do not share personal information with non-affiliated entities for their marketing purposes without your consent.</li>
                                                        <li>We may share personal information with selected service providers that assist us with the management and operation of our Site.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">What Personal Information We Collect</p>
                                                    <p className="lblSubHeader">Children</p>
                                                    <p>We do not intend to collect personal information from children. This Site is not intended for children, and no one who is under the age of 13 should submit personal information to this Site. We will promptly delete any personal information that we might have unknowingly collected from a child.</p>
                                                    <p className="lblSubHeader">For All Site Visitors</p>
                                                    <p>When a visitor accesses our Site, we collect the visitor’s zip code, IP address, and browser information. An IP address is a unique locator for a visitor’s computer that is used to find the visitor’s computer on a network. A website uses the IP address to send information to the visitor’s computer. The visitor’s computer or Internet service provider may assign a different IP address to the computer for different Internet sessions. Browser information includes the type and version of the Internet browser that is being used, such as Internet Explorer version 6.0. If one of our online marketing initiatives leads a visitor to our Site, we also identify the website or location from which that visitor came. We also collect information regarding the visitor’s traffic patterns and Site usage, such as the time spent on a page, or the links or advertisements on which the visitor has clicked.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">If You Fill Out a Form or a Questionnaire</p>
                                                    <p>If you fill out one of the forms or questionnaires posted on our Site, we collect the personal information that you provide in response to the questions. We may combine it with information, if any, that we may have in our database and that may be associated with your name or email address. The questions we ask are necessary for us to understand your needs, so that we can respond accordingly.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">If You Login</p>
                                                    <p>Customers who have registered with our Site may have access to products or services and other benefits that are available only in restricted areas of our Site. On these pages, our members can also perform certain activities, such as making payments or changing passwords, policy details or preferences. To access these restricted areas, the registered user must use a login ID and a password.</p>
                                                    <p>If you attempt to login on our Site and you have previously deleted our registration cookie, you will have to type in your login ID, and a new cookie will be created. The information we collect about your traffic patterns or Site usage may be linked to you through that cookie so that we may present products and services that may interest you. You can prevent the use of your website session information for such personalized advertising.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Emails</p>
                                                    <p>For service emails that we exchange with you, we collect only the personal information needed to handle your request, and retain it only for statistical purposes.</p>
                                                    <p>We track the open and click through rate of our promotional emails for statistical purposes and to identify those emails that a specific recipient has opened. If you respond to one of our promotional emails, we record the information you provide with other data that we have and that is associated with that same email address.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How We Collect Personal Information Directly From You</p>
                                                    <p>Some of the personal information that we collect is provided directly by you, when you send us emails or fill out the forms displayed on our Site.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Cookies</p>
                                                    <p>Like most websites, we use cookies. Cookies are small files that a website sends to a visitor’s computer when the visitor lands on a particular area of the Site. Cookies serve to identify a specific computer, so that we can recognize a returning visitor.</p>
                                                    <p>While most browsers (Explorer, Safari, Firefox, etc.) accept cookies automatically, you can choose to reject cookies or to have your computer warn you each time a cookie is being sent. Cookies are browser specific. If you use different browsers, you will have to define your preferences on each browser.</p>
                                                    <p>You can delete all or some of these cookies from your computer by activating the “delete cookies” feature in your browser. If you have several browsers, you must reiterate the deletion request for each browser.</p>
                                                    <p>Since cookies help identify a computer, without a cookie, each time you open a new page, the server for that page cannot recognize you, and treats you as a new visitor. As a result, you may not have access to some of the features offered on our Site if you refuse the use of cookies.</p>
                                                    <p>Cookies are stored on a specific computer. If you use different computers, you will need to configure each computer to suit your cookie preferences.</p>
                                                    <p>There are two types of cookies: session cookies and permanent cookies.</p>
                                                    <p className="lblSubHeader">Session Cookies</p>
                                                    <p>
                                                        Session cookies last only for the duration of one visit. They are deleted when you close your browser. Our session cookies are not associated with personal information. They are used to facilitate navigation of the Site; for example so that any information you have entered on one page is remembered when you move to a different page. We also use session cookies to track the usage of our Site, for example, the time spent on a page.
                                                    </p>
                                                    <p className="lblSubHeader">Permanent Cookies</p>
                                                    <p>
                                                        Permanent cookies remain on the computer’s hard drive until the visitor deletes them, or until the cookie expires. Permanent cookies enable us to know aspects of a visitor’s use of our Site, such as whether the user is a new or a returning visitor. These cookies are associated with information about a visitor’s use of the Site. They allow the combination of data about that person with other data about that person.
                                                    </p>
                                                    <p className="lblSubHeader">Action Tags</p>
                                                    <p>
                                                        Like many websites, we also use action tags to identify some of the pages that a user visits, and how he or she uses the content on these pages. Action tags are also placed in advertisements that are served on our behalf on our Site and third party websites. We also use action tags in our emails, to determine whether an email was opened, or whether it was forwarded to someone else. Action tags may collect and transmit this information in a manner that identifies you personally, only if you have registered with, or are logged in to our Site.
                                                    </p>
                                                    <p>You may block the linking of your session information with your personal information for purposes of personalized advertising.</p>
                                                    <p className="lblSubHeader">Third Party Cookies and Action Tags</p>
                                                    <p>
                                                        We use service providers to help us with our electronic communications, analyze the use of our Site, and serve advertisements on our behalf on our Site and on other websites across the Internet. These third parties use cookies and action tags to track activities on our Site and other websites in order to produce user profiles and aggregate information about the use of our Site. User profiles may be used to personalize advertising, including e-mails, based on the user’s interests and activities identified in the user’s profile. You may block the linking of your session information with your personal information for purposes of personalized advertising.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">What Personal Information We Disclose to Third Parties</p>
                                                    <p>We will not disclose personal information to other companies outside of our family of companies for their marketing purposes without your consent. We will not disclose any personal information about our visitors, customers and former customers except as set forth below or as permitted or required by law.</p>
                                                    <p>Persons or organizations to which we may disclose personal information include:</p>
                                                    <p className="lblSubHeader">Affiliated Third Parties</p>
                                                    <p>
                                                        We may share personal information within our family of companies in order to market products and services to our customers.<br />
                                                        Service Providers
                                                    </p>
                                                    <p>We may provide personal information to our service providers so that they can perform business, professional or insurance functions for us. For example, we use third parties to assist us in hosting and operating our Site, managing our online advertising, or handling responses to surveys or sweepstakes. Our service providers are contractually bound to protect the personal information of our visitors, members, and customers with measures substantially similar to those outlined in this Privacy Notice and to use this personal information only for agreed upon purposes.</p>
                                                    <p className="lblSubHeader">Successor Companies</p>
                                                    <p>
                                                        We may disclose personal information to a party or a representative of a party to a proposed or consummated sale, transfer, merger, or consolidation of all or part of our organization.
                                                    </p>
                                                    <p>Before the consummation of any such transaction, we will disclose only such personal information as is reasonably necessary to enable the recipient to make business decisions about the proposed transaction. We will require the recipient to agree not to disclose the information unless the disclosure would otherwise be permitted by this Privacy Notice.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader" >Other Disclosures</p>
                                                    <p>We also may disclose personal information:</p>
                                                    <ul>
                                                        <li>To a law enforcement or other governmental authority pursuant to law; or in response to a facially valid administrative or judicial order, including a search warrant or subpoena;</li>
                                                        <li>To establish or exercise our legal rights; to defend against legal claims; or as required by law.</li>
                                                        <li>To detect or prevent criminal activity, fraud, material misrepresentation, or material nondisclosure; to protect and defend the rights, property, or safety of our organization or others; or in connection with the enforcement of our Terms & Conditions and other agreements.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">California Notice of Information-Sharing Disclosure</p>
                                                    <p>
                                                        We do not share personal information with a third party for that third party’s direct marketing purpose other than as permitted by law. If we decide that we wish to provide business partners with contact details for direct marketing purposes of relevant services, products, and programs, we will provide a method for you to opt-out of having your personal information shared in such a manner. If you have further inquiries regarding our personal information sharing practices, please let us know.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Opt-Out–Electronic Communications</p>
                                                    <p>There are many opportunities for you to provide us with your email address. For example, if you contact us via email; if you fill out a form to enter a contest or request a quote; or if you elect to register with our Site.</p>
                                                    <p>Opt-out requests will be processed in a timely manner; however, you may continue to receive materials while we are updating our lists.</p>
                                                    <p>Please note that even if you have elected to unsubscribe from all of our e-newsletters and updates, we may still contact you from time to time about your account, or to provide other important notices.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How We Protect Your Personal Information</p>
                                                    <p>We use physical, electronic, and procedural safeguards to protect your personal information. We review the information security practices of companies with whom we share personal information. We require our employees to keep personal information confidential. Access to such information is provided only to those employees who need personal information to perform their duties.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Email and Telephone Fraud – A Warning About "Phishing"</p>
                                                    <p>Fraudulent emails, also known as “phishing” or “spoofing” are communications that appear to be from an insurance company, a regulatory agency, a retailer, but are not. These emails attempt to lure you into sending your personal information to a website without questioning or challenging the legitimacy of the request.</p>
                                                    <p>We do not, and will not, at any time, request your credit information, personal account information, login name or password in an unsecure or unsolicited email or telephone communication.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Changes to This Privacy Notice</p>
                                                    <p>We may revise this Privacy Notice from time to time. Each time changes are made they will be posted prominently on our Site. Your continued use of our Site following the posting of any changes to this Privacy Notice constitutes your acceptance to these changes.</p>
                                                    <p>If there is a material change to the terms of this Privacy Policy, {SubDomain} Insurance and Insurentertainment.com will provide notice and choice in accordance with the then-applicable legal requirements.</p>
                                                    <p>Other Privacy Policies</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Surveys and/or Research Invitations</p>
                                                    <p>Some surveys may be subject to a different privacy policy. Please review the privacy policy that is linked to a survey form before filling out a survey form. In addition, some invitations to join our customer research groups or panels may also have a different privacy policy.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Linking to Other Sites</p>
                                                    <p>Several pages of our Site may include links to third parties’ websites, such as in the case of credit card or auto loan applications, or portions of our travel site. While these websites may have a similar look and feel as our Site, they are separate websites operated by separate entities. You may also be transported to a third party’s website if you click on an advertisement that is displayed on our Site. When you choose to access these third party websites, a separate window will launch, and you will leave our Site.</p>
                                                    <p>Please remember that whenever you leave our Site to visit a linked website, you are subject to the terms, conditions, and policies of that other website. We are not responsible for the terms and conditions, or the privacy practices of other websites. Please review these provisions before proceeding.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How to Contact Us</p>
                                                    <p>The Site www.{SubDomain}insurance.com and www.insurEntertainment.com are operated by {SubDomain} Insurance Services, LLC.</p>
                                                    <p>If you have any questions or feedback about this Privacy Notice, our Site, or how our online practices affect you, please do not hesitate to contact us at service@{SubDomain}insurance.com. We will get back to you promptly.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How to Retain a Copy of Our Privacy Notice</p>
                                                    <p>To retain a copy of this Privacy Notice, you may use the print function of your browser.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Supplemental Privacy Provisions</p>
                                                    <p className="lblSubHeader">For Visitors Who Request an Insurance Quote</p>
                                                    <p>The privacy practices that apply to visitors who request an insurance quote, include, in addition to the practices described in the Privacy Notice above, the Supplemental Privacy Provisions below:</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">What Additional Personal Information We Collect</p>
                                                    <p className="lblSubHeader">What Information We Use to Issue a Quote</p>
                                                    <p>In order to request an insurance quote from our Site, you must fill out a questionnaire posted on the Site. We collect the personal information that you provide in response to the questions. The questions we ask are necessary for us to understand your insurance needs, so that we can provide the quote. We use the personal information that you provide and information that we might have previously obtained from you through your prior business dealings with us or one of our affiliated entities.</p>
                                                    <p>What Information We Collect from Third Parties</p>
                                                    <p>We may also obtain additional information from third parties. This additional information includes, for example, motor vehicle reports, or claims history reports. It may also include credit scores, if permitted by applicable State law, or if we have obtained your permission to do so. We do not ask for, or receive a full credit report, but only a credit score (also known as an insurance score).</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How We Use the Personal Information We Collect</p>
                                                    <p className="lblSubHeader">After You Request a Quote</p>
                                                    <p>We provide you with a quote that is based on the information identified above. We also send the quote and the information used to complete the quote (for example, credit score) to our local representative for follow-up contact.</p>
                                                    <p className="lblSubHeader">To Call, Email or Fax</p>
                                                    <p>We may contact you about your policy and/or insurance needs. By filling out an insurance quote to our Site, you authorize representatives of the {SubDomain} Insurance products and services to contact you via email, phone or fax in order to respond to your request for insurance quotes and your insurance needs.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">What Personal Information We Disclose to Third Parties</p>
                                                    <p className="lblSubHeader">Credit Reporting Agency</p>
                                                    <p>Where permitted by law, we may obtain credit information associated with your name from an insurance-support organization, such as a credit reporting agency. To do so, we provide your name and social security number to the credit-reporting agency so that it can adequately identify you and provide us the insurance score we requested. We do not further disclose this personal information to third parties.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Your Rights</p>
                                                    <p className="lblSubHeader">Access to Your Information</p>
                                                    <p>You may have access to personal information that we have recorded about you and that is related to our insurance service by submitting a written request to us at:</p>
                                                    <p>{SubDomain} Insurance Services</p>
                                                    <p>P.O. Box 61102</p>
                                                    <p>Pasadena, California 91116</p>
                                                    <p>You must describe the kind of personal information that you want to review, and state that your request is made pursuant to this Privacy Notice. Include your full name, mailing address, and policy number if applicable. Within 30 business days from the receipt of your letter, we will respond and describe what is available, and how you may request corrections.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">How to Change Your Information</p>
                                                    <p>If you wish to correct, amend, or delete personal information in our files, you must write to us as described above, explaining your requested correction. Within 30 business days from receipt of your letter, we will make the requested correction, amendment or deletion, or we may notify you of our refusal, as permitted by law.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </main>
            </>
        );
    }
}
export default PrivacyPolicy;