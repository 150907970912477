import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { showError, showSuccess, subDomain, Button } from '../../../common/Common';
import { LoaderContents } from "../../layout/LoaderContents";
import { GetAllRoles, AddEditRole, GetRoleByRoleId } from '../../../services/UserServices';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
class ManageRoles extends Component {
    //this for agent user
    constructor(props) {
        super(props);
        this.state = {
            Errors: {},
            AllRoleData: [],
            FilterRoleData: [],
            SaveLoaderStatus: false,
            ContentsLoaderStatus: false,
            RoleListMdl: {
                id: 0,
                name: null
                
            },
            searchMdl: {
                Id: null, //use for user id
                GeneralSearch: null,
                LimitTo: 5,
                PageNumber: 0,
                SelectedDate: null,
                ToDate: null,
                ContentsLoaderStatus: false,
                SaveLoaderStatus: false
            },
            Pagecount: 0,
            ModelTitle: null,
            SubDomain: subDomain(),
            PageLoaderStatus: false,
        }

    }
    componentDidMount() {
        this.setState({ PageLoaderStatus: true }); // show loader befour load data
        this._GetAllRoles(this.state.SubDomain.SubDomainId);

    };
    handlePageClick = (data) => {
        let PageNumber = data.selected;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
      
    }
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: 0 }));
        let count = this.state.AllRoleData.length;
        if (event.target.value > count) {
            this.setState(Object.assign(this.state.searchMdl, { LimitTo: count }));
        }
        else {
            this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        }
       
        //this._GetAllRoles();
        this.setState({ Pagecount: Math.ceil((count / this.state.searchMdl.LimitTo)) });
        
       
    }

    handleSearchData = (event) => {
        if (this.state.AllRoleData !== null && this.state.AllRoleData.length > 0) {
            let filterData = this.state.AllRoleData.filter(s => s.normalizedName.toLowerCase().startsWith(event.target.value.toLowerCase()));
            //let filterData = this.state.AllRoleData.filter(s => s.normalizedName.toLowerCase().match(new RegExp(event.target.value, 'i')));
            this.setState({ FilterRoleData: filterData });
            this.setState({ Pagecount: Math.ceil((filterData.length / this.state.searchMdl.LimitTo)) });
        } 
    }
    //form submit on enter key down
    _AddEditRole_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._AddEditRole(this.state.SubDomain.SubDomainId);
        };
    };
    // call api for add and edit new user by super admin

    _AddEditRole = (subDomainId) => {          
        let RoleListMdl = {
            Id: this.state.RoleListMdl.id,
            Name: this.refs.RoleName.value,
            SubDomainId: subDomainId
        }
        let status = this.Validation(RoleListMdl);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); //loader before  data save  
            AddEditRole(RoleListMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#modalAddNewRole').modal('hide');
                        this.resetRoleListMdl();
                        this._GetAllRoles(this.state.SubDomain.SubDomainId);
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                }
            });
        }
    }
    //validation for registration 
    Validation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.Name === null || mdl.Name === undefined || mdl.Name.trim() === "") {
            errors['IsEmpty_RoleName'] = true;
            errors['RoleNameEmptyMsg'] = "Please fill the required fields : Role Name";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    resetRoleListMdl = () => {
        this.setState({
            Errors: [],
            RoleListMdl: {
                id: 0,
                name: null 
            },
            ModelTitle:"Add New Role"
        });
        $("#frmaddnewrole").trigger("reset");
        window.$('#modalAddNewRole').modal('show');
    }
    editRole = (roleId) => {
        this.resetRoleListMdl();
        this.setState({ ModelTitle: "Update Role" });
        this.setState({ SaveLoaderStatus: true }); // show loader before  data edit
        GetRoleByRoleId(roleId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ RoleListMdl: response.data });
                    window.$('#modalAddNewRole').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
            }
        });
    }

    _GetAllRoles = (subDomainId) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllRoles(subDomainId).then(res => {
            let response = res;
             if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllRoleData: response.data, FilterRoleData: response.data });
                    let count = this.state.AllRoleData.length;
                    this.setState({ Pagecount: Math.ceil((count / this.state.searchMdl.LimitTo)) });
                    } else {
                    showError(response.message);
                }
                 this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
                 this.setState({ PageLoaderStatus: false });
            }
        });
    }
    render() {
        const { FilterRoleData } = this.state;
        let data = FilterRoleData.slice(this.state.searchMdl.PageNumber * this.state.searchMdl.LimitTo, (this.state.searchMdl.PageNumber + 1) * this.state.searchMdl.LimitTo);
         return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-5 col-12">
                                 <h1 className="h3 mb-0 fw-normal"><strong>Manage Roles</strong></h1>
                            </div>
                             <div className="col-xl-4 col-xxl-4  col-lg-4 col-sm-7 col-12 text-end">
                                 <button type="button" id="addNewUser" className="btn-newDesign btn-header center-aligned" onClick={() => this.resetRoleListMdl()}>
                                    <span className="label">Add New Role</span>
                                 </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                 <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Role Name" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>

                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Sno</th>
                                                        <th>Role Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((items, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            {/*<td>{(items.userInfoMdl !== null && items.userInfoMdl.fullName !== null) && items.userInfoMdl.fullName}</td>*/}
                                                            <td>{items.name}</td>
                                                            {items.id !== 3 && <td>
                                                                <button type="button" id={items.id} className="btn btn-newDesign" onClick={() => this.editRole(items.id)}>
                                                                    <span>Edit</span>
                                                                </button>
                                                            </td>}
                                                            {items.id === 3 && <td></td>}
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
    
                                             <p className="text-center">
                                                 {(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
                                             </p>
                                             <div className="pt20px">
                                                 { /*loader show befoure load contents*/}
                                                 {this.state.PageLoaderStatus && <LoaderContents />}
                                             </div>
                                        </div>
                                         <div className="d-flex paginate-div">
                                             <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={this.state.Pagecount ?? 0}
                                                marginPagesDisplayed={"4"}
                                                pageRangeDisplayed={"3"}
                                                forcePage={this.state.pageNumber}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination justify-content-start"}
                                                pageClassName={"page-item page-item-none"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item page-item-none"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledLinkClassName={"disabled"} />
                                                <div className="align-items-end">
                                                    Page {this.state.searchMdl.PageNumber > 0 ?  this.state.searchMdl.PageNumber : 1} of {this.state.Pagecount}
                                                </div>
                                         </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>
                { /*model for create payment link */}
                <div className="modal fade" tabIndex="-1" id="modalAddNewRole" aria-labelledby="AddNewRole" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">{this.state.ModelTitle ?? ""}</h5>
                            </div>
                            <div className="modal-body">
                                <div>
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="position-relative step-register w-75 m-auto" id="frmaddnewrole" onKeyDown={this._AddEditRole_EnterButton}>
                                    <fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
                                        {/*<h2 className="mb-5 text-center">Enter role name. </h2>*/}
                                        <p>Enter new role</p>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_RoleName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Role name" ref="RoleName" type="text" defaultValue={this.state.RoleListMdl.name ?? ""} id="RoleName" required />
                                            <label htmlFor="RoleName">Role name</label>
                                            {this.state.Errors['IsEmpty_RoleName'] === true ? <div className="has-err-msg">{this.state.Errors['RoleNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <div className="text-center d-flex justify-content-center">
                                                <button type="button" id="btnclosePaymentMethodMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                <button type="button" className="btn btn-newDesign" onClick={() => this._AddEditRole(this.state.SubDomain.SubDomainId)}>Submit</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ManageRoles;