import { authHeader } from '../common/Common';
//AddProductTourData
async function AddProductTourData(productTourMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(productTourMdl)
    };
    const GetResponse = await fetch('/api/ProductTour/AddProductTourData', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//GetProductTourDataByUserId
async function GetProductTourDataByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/ProductTour/GetProductTourDataByUserId?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
export { AddProductTourData, GetProductTourDataByUserId };