import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { FlowGroups } from "../../../common/Enum";
import { showError, showSuccess, subDomain, Button } from '../../../common/Common';
import { LoaderContents } from "../../layout/LoaderContents";
import { GetAllUser, GetState } from '../../../services/UserServices';
import { AddEditUser, GetAllRoles, GetUserProfileByEmail, CheckEmailAreadyexists, DeleteUserByEmailId } from '../../../services/UserServices';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import validator from 'validator';
import $ from 'jquery';
class ManageUsersBySuperAdmin extends Component {
	//this for agent user
	constructor(props) {
		super(props);
		this.state = {
			Errors: {},
			AllUserData: [],
			reponseMessage: '',
			IsEmailAreadyexists: null,
			EmailAreadyexistsLoaderStatus: false,
			SaveLoaderStatus: false,
			ContentsLoaderStatus: false,
			deleteLoader: false,
			selectedRoles: null,
			RoleList: [],
			searchMdl: {
				Id: null, //use for user id
				GeneralSearch: null,
				LimitTo: 5,
				PageNumber: 1,
				SelectedDate: null,
				ToDate: null,
				ContentsLoaderStatus: false,
				SaveLoaderStatus: false
			},
			Pagecount: 0,
			ModelTitle: null,
			UserProfileMdl: {
				id: null,
				email: null,
				confirmEmail: null,
				flowGroupId: 0,
				userRoleIds: [],
				userInfoMdl: {
					id: 0,
					fullName: null,
					isActive: false,
					address: null,
					city: null,
					state: null,
					zip: null,
					phoneNumber: null
				}
			},
			SubDomain: subDomain(),
			PageLoaderStatus: false,
			StateList: [],
			selectedState: null,
			editStateValue:[]
		}
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		this.setState({ PageLoaderStatus: true }); // show loader befour load data
		this._GetStateList();
		//for phone number  formatting
		$("input[name='phoneNumber']").keyup(function () {
			$(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"));
		});
		this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
		this._GetAllRoles(this.state.SubDomain.SubDomainId);

	};
	//form submit on enter key down
	_AddEditUser_EnterButton = (event) => {
		let keyCode = event.which || event.keyCode;
		if (keyCode === 13) {
			this._AddEditUser();
		};
	};

	_GetStateList = () => {
		GetState().then(data => {
			let response = data.data;
			let listOption = [];
			if (response.status === true) {
				for (let i = 0; i < response.data.length; i++) {
					listOption.push({ 'value': response.data[i].code, 'label': response.data[i].description });
				}
				this.setState({ StateList: listOption });
			}
			else {
				showError(response.message);
			}
		});
	}
	stateHandleChange = (selectedState) => {
		this.setState({ selectedState: selectedState });
	};

	_DeleteUserByEmailId = () => {
		this.setState({ deleteLoader: true });
		DeleteUserByEmailId(this.state.userEmail).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					showSuccess(response.message);
				}
				else {
					showError(response.message);
				}
				this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
				this.setState({ userEmail: null, deleteLoader: false })
				window.$('#confirmdelete').modal('hide');

			}
		});
	}
	_CheckEmailAreadyexists = (event) => {
		if (this.state.UserProfileMdl.id === null) {
			let email = event.target.value;
			this.setState({ reponseMessage: null, IsEmailAreadyexists: null });
			if (email !== null && email !== undefined && email.trim() !== "") {
				let status = this.EmailValidation(email);
				if (status) {
					this.setState({ EmailAreadyexistsLoaderStatus: true });
					CheckEmailAreadyexists(email).then(data => {
						let response = data;
						if (response.message !== Error) {
							if (response.status === true) {
								this.setState({ reponseMessage: response.message, IsEmailAreadyexists: true });
								//showSuccess(response.message);
							}
							else {
								this.setState({ reponseMessage: response.message, IsEmailAreadyexists: false });
								//showError(response.message);
							}
							this.setState({ EmailAreadyexistsLoaderStatus: false }); // hide loader after  data save
						}
					});
				}
			}
		}

	}
	EmailValidation = (email) => {
		let errors = {};
		let count = 0;
		if (!validator.isEmail(email)) {
			errors['IsEmpty_Email'] = true;
			errors['EmailEmptyMsg'] = "Please fill the Valid fields : Email";
			count++;
		}
		this.setState({ Errors: errors });
		if (count === 0) {
			return true;
		}
	}

	_AddEditUser = () => {
		let UserRoleIds = [];

		if (this.state.selectedRoles != null && this.state.selectedRoles.length > 0) {
			for (let i = 0; i < this.state.selectedRoles.length; i++) {
				UserRoleIds.push(this.state.selectedRoles[i].value);
			}
		}
		let AspNetUsersMdl = {
			Id: this.state.UserProfileMdl.id,
			Email: this.refs.Email.value,
			ConfirmEmail: this.refs.ConfirmEmail.value,
			FlowGroupId: FlowGroups.Users,
			UserRoleIds: UserRoleIds,
			userInfoMdl: {
				Id: this.state.UserProfileMdl.userInfoMdl.id,
				FullName: this.refs.FullName.value,
				IsActive: this.state.UserProfileMdl.userInfoMdl.isActive,
				Address: this.refs.Address.value,
				City: this.refs.City.value,
				//State: this.refs.State.value,
				State: this.state.selectedState !== null ? this.state.selectedState.value : this.state.editStateValue.value,
				Zip: this.refs.Zip.value,
				PhoneNumber: this.refs.PhoneNumber.value,
			},
			roleListMdl: [],
			SubDomainId: this.state.SubDomain.SubDomainId,
			Url: this.state.SubDomain.SubDomain,
			Logo: this.state.SubDomain.LogoPath
		}

		let status = this.AddEditUserValidation(AspNetUsersMdl);
		if (status) {
			this.setState({ SaveLoaderStatus: true }); //  loader before  data save
			AddEditUser(AspNetUsersMdl).then(data => {
				let response = data;
				if (response.message !== Error) {
					if (response.status === true) {
						window.$('#modalAddNewUser').modal('hide');
						this.resetUserProfileMdl();
						this.setState({ selectedRoles: null });
						this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
						showSuccess(response.message);
					}
					else {
						showError(response.message);
					}
					this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
				}
			});
		}
	}
	//validation for registration 
	AddEditUserValidation = (AspNetUsersMdl) => {
		let errors = {};
		let count = 0;
		if (AspNetUsersMdl.userInfoMdl.FullName === null || AspNetUsersMdl.userInfoMdl.FullName === undefined || AspNetUsersMdl.userInfoMdl.FullName.trim() === "") {
			errors['IsEmpty_FullName'] = true;
			errors['FullNameEmptyMsg'] = "Please fill the required fields : Full Name";
			count++;
		}
		if (AspNetUsersMdl.Email === null || AspNetUsersMdl.Email === undefined || AspNetUsersMdl.Email.trim() === "") {
			errors['IsEmpty_Email'] = true;
			errors['EmailEmptyMsg'] = "Please fill the required fields : Email";
			count++;
		}
		else {
			if (!validator.isEmail(AspNetUsersMdl.Email)) {
				errors['IsEmpty_Email'] = true;
				errors['EmailEmptyMsg'] = "Please fill the Valid fields : Email";
				count++;
			}
		}
		if (AspNetUsersMdl.ConfirmEmail === null || AspNetUsersMdl.ConfirmEmail === undefined || AspNetUsersMdl.ConfirmEmail.trim() === "") {
			errors['IsEmpty_ConfirmEmail'] = true;
			errors['ConfirmEmailEmptyMsg'] = "Required fields : Confirm email";
			count++;
		}
		else {
			if (!validator.isEmail(AspNetUsersMdl.ConfirmEmail)) {
				errors['IsEmpty_ConfirmEmail'] = true;
				errors['ConfirmEmailEmptyMsg'] = "Please fill the Valid fields : Confirm email";
				count++;
			}
			else if (!validator.equals(AspNetUsersMdl.Email, AspNetUsersMdl.ConfirmEmail)) {
				errors['IsEmpty_ConfirmEmail'] = true;
				errors['ConfirmEmailEmptyMsg'] = "Email doesn't match : Confirm email";
				count++;
			}
		}
		//if (AspNetUsersMdl.FlowGroupId === 0 || AspNetUsersMdl.FlowGroupId === null || AspNetUsersMdl.FlowGroupId === undefined) {
		//    errors['IsEmpty_Role'] = true;
		//    errors['RoleEmptyMsg'] = "Please Select the required fields : Group";
		//    count++;
		//}
		if (AspNetUsersMdl.UserRoleIds === null || AspNetUsersMdl.UserRoleIds.length === 0) {
			errors['IsEmpty_UserRoleIds'] = true;
			errors['UserRoleIdsEmptyMsg'] = "Please Select the required fields : User Role";
			count++;
		}

		if (AspNetUsersMdl.userInfoMdl.Address === null || AspNetUsersMdl.userInfoMdl.Address === undefined || AspNetUsersMdl.userInfoMdl.Address.trim() === "") {
			errors['IsEmpty_Address'] = true;
			errors['AddressEmptyMsg'] = "Please fill the required fields : Address";
			count++;
		}
		if (AspNetUsersMdl.userInfoMdl.City === null || AspNetUsersMdl.userInfoMdl.City === undefined || AspNetUsersMdl.userInfoMdl.City.trim() === "") {
			errors['IsEmpty_City'] = true;
			errors['CityEmptyMsg'] = "Please fill the required fields : City";
			count++;
		}
		if (AspNetUsersMdl.userInfoMdl.State === null || AspNetUsersMdl.userInfoMdl.State === undefined || AspNetUsersMdl.userInfoMdl.State.trim() === "") {
			errors['IsEmpty_State'] = true;
			errors['StateEmptyMsg'] = "Please fill the required fields : State";
			count++;
		}
		if (AspNetUsersMdl.userInfoMdl.Zip === null || AspNetUsersMdl.userInfoMdl.Zip === undefined || AspNetUsersMdl.userInfoMdl.Zip.trim() === "") {
			errors['IsEmpty_Zip'] = true;
			errors['ZipEmptyMsg'] = "Please fill the required fields : Zip";
			count++;
		}

		if (AspNetUsersMdl.userInfoMdl.PhoneNumber === null || AspNetUsersMdl.userInfoMdl.PhoneNumber === undefined || AspNetUsersMdl.userInfoMdl.PhoneNumber.trim() === "") {
			errors['IsEmpty_PhoneNumber'] = true;
			errors['PhoneNumberEmptyMsg'] = "Please fill the required fields : Phone Number";
			count++;
		}


		this.setState({ Errors: errors });
		if (count === 0) {
			return true;
		}
	}
	resetUserProfileMdl = () => {
		this.setState({
			Errors: [],
			reponseMessage: '',
			IsEmailAreadyexists: null,
			ModelTitle: "Add New User",
			UserProfileMdl: {
				id: null,
				email: null,
				flowGroupId: 0,
				confirmEmail: null,
				userRoleIds: [],
				userInfoMdl: {
					id: 0,
					fullName: null,
					isActive: false,
					address: null,
					city: null,
					state: null,
					zip: null,
					phoneNumber: null
				},

			}
		});
		this.setState({ selectedRoles: null });
		this.setState({ selectedState: null, editStateValue: [] });
		$("#frmaddnewuser").trigger("reset");
		window.$('#modalAddNewUser').modal('show');
	}
	editUserInfo = (email) => {
		this.resetUserProfileMdl();
		this.setState({ ModelTitle: "Update User" });
		this.setState({ SaveLoaderStatus: true }); // show loader before  data edit
		GetUserProfileByEmail(email).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ selectedState: null, editStateValue: [] });
					this.setState({ UserProfileMdl: response.data });
					this.setState(Object.assign(this.state.UserProfileMdl, { flowGroupId: response.data.flowGroupId }));
					let roleList = [];
					if (response.data.roleListMdl != null && response.data.roleListMdl.length > 0) {
						for (let i = 0; i < response.data.roleListMdl.length; i++) {
							roleList.push({ 'value': response.data.roleListMdl[i].id, 'label': response.data.roleListMdl[i].normalizedName });
						}
						this.setState({ selectedRoles: roleList });
					}

					//let editState = [];
					//for (let i = 0; i < this.state.StateList.length; i++) {
					//	if (this.state.UserProfileMdl.userInfoMdl.state === this.state.StateList[i].value) {
					//		editState.push({ 'value': this.state.StateList[i].value, 'label': this.state.StateList[i].label });
					//	}
					//}
					//this.setState({ editStateValue: editState });
					this.setState({ editStateValue: this.state.StateList.find(state => state.value === this.state.UserProfileMdl.userInfoMdl.state) });

					window.$('#modalAddNewUser').modal('show');
				}
				else {
					showError(response.message);
				}
				this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
			}
		});
	}
	handlePageClick = (data) => {
		let PageNumber = data.selected + 1;
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
		this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
	}
	handleShowChange = (event) => {
		this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
		this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
	}
	handleSearchData = (event) => {

		this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
		this._GetAllUser(this.state.searchMdl, this.state.SubDomain.SubDomainId);
	}
	handleChange = (selectedRoles) => {
		this.setState({ selectedRoles });
	};
	_GetAllUser = (searchMdl, subDomainId) => {
		this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
		GetAllUser(searchMdl, subDomainId).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllUserData: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ ContentsLoaderStatus: false }); // hide loader after load data

				} else {
					showError(response.message);
					this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
				}
				this.setState({ PageLoaderStatus: false }); // show loader befour load data
				this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
			}
		});
	}
	_GetAllRoles = (subDomainId) => {
		this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
		GetAllRoles(subDomainId).then(res => {
			let response = res;
			let roleList = [];
			if (response.message !== Error) {
				if (response.status === true) {
					if (response.data.length > 0) {
						for (let i = 0; i < response.data.length; i++) {
							roleList.push({ 'value': response.data[i].id, 'label': response.data[i].normalizedName });
						}
						this.setState({ RoleList: roleList });
					}
				} else {
					showError(response.message);
				}
				this.setState({ ContentsLoaderStatus: false }); // hide loader after load data

			}
		});
	}
	render() {
		const { AllUserData, RoleList, selectedRoles, Pagecount } = this.state;
		//let data = AllUserData.slice(this.state.searchMdl.PageNumber * this.state.searchMdl.LimitTo, (this.state.searchMdl.PageNumber + 1) * this.state.searchMdl.LimitTo);
		return (
			<>
				<div className="main-wrapper-newDesign w-100">
					<InternalLayoutHeader />
					<div className="content-main p-lg-4 p-3">
						<div className="row align-items-center">
							<div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-5 col-12">
								<h1 className="h3 mb-0 fw-normal"><strong>Manage Users</strong></h1>
							</div>
							<div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-7 col-12 text-end">
								<button type="button" id="addNewUser" className="btn-newDesign btn-header center-aligned" onClick={() => this.resetUserProfileMdl()}>
									<span className="label">Add New User</span>
								</button>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12 col-xxl-12">
								<div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
									<div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Name" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>

                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
												<thead>
													<tr>
														<th>Sno</th>
														<th>Full Name</th>
														<th>Address</th>
														<th>Phone Number</th>
														<th>Email</th>
														<th>Roles</th>
														<th>Account Status</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{AllUserData.map((items, index) => (
														<tr key={index}>
															<td>{index + 1}</td>
															{/*<td>{(items.userInfoMdl !== null && items.userInfoMdl.fullName !== null) && items.userInfoMdl.fullName}</td>*/}
															<td>{items.fullName}</td>
															<td>{((items.address === '' || items.address === null) ? ' ' : items.address + ' ') + ((items.city === '' || items.city === null) ? ' ' : items.city + ' ') + ((items.state === '' || items.state === null) ? ' ' : items.state + ' ') + ((items.zip === '' || items.zip === null) ? ' ' : items.zip + ' ')}</td>
															<td>{items.phoneNumber}</td>
															<td>{items.email}</td>
															<td>{items.roleListMdl.map((task, index) => {
																return (
																	<div key={index}>
																		{task.name}
																	</div>
																);
															})}</td>
															<td>
																{
																	items.isActive === true ? <span className='text-success'>Active</span> : <span className='text-danger'>Pending</span>
																}
															</td>
															<td>
																<div className="button-container">
																	<button type="button" id={items.userId} className="btn btn-newDesign" onClick={() => this.editUserInfo(items.email)}>
																		<span>Edit</span>
																	</button>
																	<button type="button" id={items.userId} className="btn btn-newDesign-foil" data-bs-toggle="modal" data-bs-target="#confirmdelete" onClick={() => this.setState({ userEmail: items.email })}>
																		<span>Delete</span>
																	</button>
																</div>
															</td>
														</tr>
													))
													}
												</tbody>
											</table>
	
											<p className="text-center">
												{(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
											</p>
											<div className="pt20px">
												{ /*loader show befoure load contents*/}
												{this.state.PageLoaderStatus && <LoaderContents />}
											</div>
										</div>
										<div className="d-flex paginate-div">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={Pagecount ?? 0}
												marginPagesDisplayed={"4"}
												pageRangeDisplayed={"3"}
												onPageChange={this.handlePageClick}
												containerClassName={"pagination justify-content-start"}
												pageClassName={"page-item page-item-none"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item page-item-none"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
												disabledLinkClassName={"disabled"} />
											<div className="pagination-page">
												Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<SiteFooter /> {/*this call for site footer */}
				</div>
				{ /*model for create payment link */}
				<div className="modal fade" tabIndex="-1" id="modalAddNewUser" aria-labelledby="AddNewUser" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header justify-content-center pt-4 border-0">
								<h5 className="modal-title text-center">{this.state.ModelTitle ?? ""}</h5>
							</div>
							<div className="modal-body">
								<div className="pt20px">
									{ /*loader show befoure load contents*/}
									{this.state.SaveLoaderStatus && <LoaderContents />}
								</div>
								<form className="position-relative step-register w-75 m-auto" id="frmaddnewuser" onKeyDown={this._AddEditUser_EnterButton}>
									<fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
										<p>Enter Full Name</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_FullName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Full name" ref="FullName" type="text" defaultValue={this.state.UserProfileMdl.userInfoMdl.fullName ?? ''} id="FullName" required />
											<label htmlFor="FullName">Full name</label>
											{this.state.Errors['IsEmpty_FullName'] === true ? <div className="has-err-msg">{this.state.Errors['FullNameEmptyMsg']}</div> : ''}
										</div>

										<p>Enter Email</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={(this.state.Errors['IsEmpty_Email'] === true || this.state.IsEmailAreadyexists === false) ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Email" ref="Email" type="text" name="Email" autoComplete="off" defaultValue={this.state.UserProfileMdl.email ?? ''} id="email" onBlur={() => this._CheckEmailAreadyexists(event)} required />
											{this.state.EmailAreadyexistsLoaderStatus &&
												<span style={{ position: "absolute", right: "20px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
											}
											<label htmlFor="email">Email</label>
											{this.state.Errors['IsEmpty_Email'] === true ? <div className="has-err-msg">{this.state.Errors['EmailEmptyMsg']}</div> : ''}
											{this.state.IsEmailAreadyexists === false && <span className="has-err-msg">{this.state.reponseMessage ?? ""}</span>}
										</div>
										
										<p>Re-enter Email</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_ConfirmEmail'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Confirm email" ref="ConfirmEmail" type="text" name="ConfirmEmail" autoComplete="off" defaultValue={this.state.UserProfileMdl.email ?? ''} id="ConfirmEmail" required />
											<label htmlFor="ConfirmEmail">Confirm email</label>
											{this.state.Errors['IsEmpty_ConfirmEmail'] === true ? <div className="has-err-msg">{this.state.Errors['ConfirmEmailEmptyMsg']}</div> : ''}
										</div>
										
										<p>Select User Role</p>
										<div className="input-wrapper-payment text-start mb-3">
											<Select
												value={selectedRoles}
												onChange={this.handleChange}
												options={RoleList}
												isMulti
												placeholder='Select user role'
												closeMenuOnSelect={false}
												isSearchable
												className={this.state.Errors['IsEmpty_UserRoleIds'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control'}
											/>
											{this.state.Errors['IsEmpty_UserRoleIds'] === true ? <div className="has-err-msg">{this.state.Errors['UserRoleIdsEmptyMsg']}</div> : ''}
										</div>
										
										<p>Enter Address</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_Address'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Address" ref="Address" type="text" name="Address" defaultValue={this.state.UserProfileMdl.userInfoMdl.address ?? ''} id="Address" required />
											<label htmlFor="Address">Address</label>
											{this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressEmptyMsg']}</div> : ''}
										</div>
										
										<p>Enter City</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_City'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="City" ref="City" type="text" name="City" defaultValue={this.state.UserProfileMdl.userInfoMdl.city ?? ''} id="City" required />
											<label htmlFor="City">City</label>
											{this.state.Errors['IsEmpty_City'] === true ? <div className="has-err-msg">{this.state.Errors['CityEmptyMsg']}</div> : ''}
										</div>
										
										<p>Select State</p>
										<div className="input-wrapper-payment text-start mb-3">
											<Select
												value={this.state.selectedState === null ? this.state.editStateValue : this.state.selectedState}
												onChange={this.stateHandleChange}
												options={this.state.StateList}
												false
												placeholder={'State'}
												closeMenuOnSelect={true}
												true
												id={'State'}
												ref={'State'}
												className={(this.state.Errors['IsEmpty_CompanyName'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
											/>
											{this.state.Errors['IsEmpty_State'] === true ? <div className="has-err-msg">{this.state.Errors['StateEmptyMsg']}</div> : ''}
										</div>


										{/*<div className="input-wrapper mx-auto mb-3">*/}
										{/*    <input className={this.state.Errors['IsEmpty_State'] === true ? 'has-err' : ''} placeholder="State" ref="State" type="text" name="State" defaultValue={ ?? ''} id="State" required />*/}
										{/*    <label htmlFor="State">State</label>*/}
										{/*    {this.state.Errors['IsEmpty_State'] === true ? <div className="has-err-msg">{this.state.Errors['StateEmptyMsg']}</div> : ''}*/}
										{/*</div>*/}
										<p>Enter Zip</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_Zip'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Zip" ref="Zip" type="text" defaultValue={this.state.UserProfileMdl.userInfoMdl.zip ?? ''} id="Zip" required />
											<label htmlFor="Zip">Zip</label>
											{this.state.Errors['IsEmpty_Zip'] === true ? <div className="has-err-msg">{this.state.Errors['ZipEmptyMsg']}</div> : ''}
										</div>
										
										<p>Enter Phone Number</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={this.state.Errors['IsEmpty_PhoneNumber'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Phone Number" ref="PhoneNumber" type="text" defaultValue={this.state.UserProfileMdl.userInfoMdl.phoneNumber ?? ''} id="PhoneNumber" name="phoneNumber" maxLength="14" required />
											<label htmlFor="PhoneNumber">Phone Number</label>
											{this.state.Errors['IsEmpty_PhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PhoneNumberEmptyMsg']}</div> : ''}
										</div>
										<div className="text-center d-flex justify-content-center">
											<button type="button" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
											<button type="button" className="btn btn-newDesign" onClick={() => this._AddEditUser()}>Submit</button>
										</div>
									</fieldset>
								</form>

							</div>
						</div>
					</div>
				</div>
				{ /*model for delete user  */}
				<div className="modal fade" id="confirmdelete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="staticBackdropLabel">Delete user account</h5>
								{/*    <Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
							</div>
							<div className="modal-body">
								<p>Do you want to delete this user account ?</p> {this.state.deleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
								<div className="text-center d-flex justify-content-center">
									<button type="button" id="btncanclemail" className="btn btn-newDesign-reverse me-2" data-bs-dismiss="modal">Cancel</button>
									<button type="button" id="btnsendmail" className="btn btn-newDesign" onClick={() => this._DeleteUserByEmailId()}>Delete</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ManageUsersBySuperAdmin;