import React, { Component, useState, useEffect } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { authHeader, showError, subDomain, StyledActiveLink, ExternalDomainNavLink } from "../../../common/Common";
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { FppPayment, FppPaymentSuccess, FppPaymentSuccessACH } from '../../../services/PaymentServices';
import { Buffer } from 'buffer';

export default function FppPaymentStatus() {
	const UserDetails = authHeader();
	const SubDomain = subDomain();
	const queryParams = new URLSearchParams(window.location.search);
	const subDomainUrl = (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "fpp-payment-status" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1'));
	let paymentId = (new URLSearchParams(window.location.search)).get("paymentId");
	const paymentStatus = (new URLSearchParams(window.location.search)).get("status");
	const fppid = (new URLSearchParams(window.location.search)).get("id");
	const amount = (new URLSearchParams(window.location.search)).get("amount");
	const policyNumber = (new URLSearchParams(window.location.search)).get("pN");
	const fullName = (new URLSearchParams(window.location.search)).get("fN");
	const textActive = Boolean((new URLSearchParams(window.location.search)).get("textActive"));
	const transferGroup = (new URLSearchParams(window.location.search)).get("tG");
	const customerId = (new URLSearchParams(window.location.search)).get("cId");
	const isAutoPay = false; //(new URLSearchParams(window.location.search)).get("iAp");
	const invoiceNumber = (new URLSearchParams(window.location.search)).get("iN");
	const params = (new URLSearchParams(window.location.search)).get("params");
	let details;
	if (params !== null && params !== undefined && params!=='') {
		let paramDetails = Buffer.from(params, 'base64').toString()
		 details = JSON.parse(paramDetails);
		if (paymentId !== '' && paymentId !== undefined && details !== '' && details.paymentId !== '') {
			paymentId = details.paymentId
		}
	}
	
	useEffect(() => {
		if (paymentStatus === 'Success') {
			_FppPaymentSuccess();
		}
		else if (paymentStatus === 'complete') {
			_FppPaymentSuccessAch();
		}
	}, []);


	const _FppPaymentSuccess = async () => {
		let stripePaymentMdl = {
			amount: (parseInt(amount * 100)),
			paymentId: paymentId,
			paymentIntentMethod: "card",
			policyNumber: policyNumber,
			fullName: fullName,
			status: paymentStatus !== 'Success' ? false : true,
			isTextActive: textActive,
			confirmationNumber: fppid,
			transferGroup: transferGroup,
			paymentIntentId: fppid,
			logo: null,
			subDomain: null,
			subDomainId: 0,
			customerId: customerId,
			isAutoPay: isAutoPay === 'true' ? true : false,
			invoiceNumber: invoiceNumber,
		}

		if (UserDetails !== null) {
			stripePaymentMdl.subDomain = SubDomain.SubDomain;
			stripePaymentMdl.logo = SubDomain.LogoPath;
			stripePaymentMdl.subDomainId = parseInt(SubDomain.SubDomainId);
		}
		else {
			stripePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
			stripePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
			stripePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
		}
		await FppPaymentSuccess(stripePaymentMdl);

	}

	const _FppPaymentSuccessAch = async () => {
		let stripePaymentMdl = {
			amount: (parseInt(details.amount * 100)),
			paymentId: details.paymentId,
			paymentIntentMethod: "ach",
			policyNumber: details.policyNumber,
			fullName: details.fullName,
			status: paymentStatus !== 'complete' ? false : true,
			isTextActive: textActive,
			confirmationNumber: details.id,
			transferGroup: transferGroup,
			paymentIntentId: details.id,
			logo: null,
			subDomain: null,
			subDomainId: 0,
			customerId: customerId,
			isAutoPay: isAutoPay === 'true' ? true : false,
			invoiceNumber: details.invoiceNumber,
		}

		if (UserDetails !== null) {
			stripePaymentMdl.subDomain = SubDomain.SubDomain;
			stripePaymentMdl.logo = SubDomain.LogoPath;
			stripePaymentMdl.subDomainId = parseInt(SubDomain.SubDomainId);
		}
		else {
			stripePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
			stripePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
			stripePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
		}
		await FppPaymentSuccessACH(stripePaymentMdl);

	}

	return (
		<>
			{UserDetails !== null ?
				<div className="main-wrapper w-100">
					<InternalLayoutHeader />	 {/*internal header user wise */}
					<div className="content-main p-lg-4 p-3">
						<div className="row">
							<div className="col-xl-12 col-xxl-12 text-center">
								<div className="Paymentcard">
									{subDomainUrl.toLowerCase() === 'fpp-payment-status' &&
										<>
											<div>
												<svg id="done" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="green">
													<path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z" />
												</svg>
											</div>
											{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
													<p className="text-center">Your confirmation number is {paymentId}</p>
												</>
											}
											{paymentStatus === 'error' &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
													<p className="text-center">Your confirmation number is {paymentId}</p>
												</>
											}

											<p className="text-center">
												<StyledActiveLink to="/login" className="btn btn-primary btn-sm">Back to Login</StyledActiveLink>
											</p>
										</>
									}
									{subDomainUrl.toLowerCase() === 'xgen' &&
										<>
											<div className="PaymentLogoWrapper">
												<img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
											</div>
											{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
													<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
												</>
											}
											{paymentStatus === 'error' &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
													<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
												</>
											}

											<p className="text-center">
												<NavLink to="/" className="btn btn-primary btn-sm">Back to Login</NavLink>
											</p>
										</>
									}
									{subDomainUrl.toLowerCase() !== 'fpp-payment-status' && subDomainUrl.toLowerCase() !== 'xgen' &&
										<>
											<div className="PaymentLogoWrapper">
												<img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
											</div>
											{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
													<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
												</>
											}
											{paymentStatus === 'error' &&
												<>
													<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
													<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
												</>
											}
										</>
									}
								</div>
							</div>
						</div>
					</div>
					<SiteFooter />  {/*this call for site footer */}
				</div>
				:
				<>
					<main>
						<div className="container form-space p-space">
							<div className="row justify-content-md-center">
								<div className="col-md-auto">
									<div className="Paymentcard">
										{subDomainUrl.toLowerCase() === 'fpp-payment-status' &&
											<>
												<div>
													<svg id="done" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="green">
														<path d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z" />
													</svg>
												</div>
												{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
														<p className="text-center">Your confirmation number is {paymentId}</p>
													</>
												}
												{paymentStatus === 'error' &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
														<p className="text-center">Your confirmation number is {paymentId}</p>
													</>
												}

												<p className="text-center">
													<StyledActiveLink to="/login" className="btn btn-primary btn-sm">Back to Login</StyledActiveLink>
												</p>
											</>
										}
										{subDomainUrl.toLowerCase() === 'xgen' &&
											<>
												<div className="PaymentLogoWrapper">
													<img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
												</div>

												{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
														<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
													</>
												}
												{paymentStatus === 'error' &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
														<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
													</>
												}

												<p className="text-center">
													<NavLink to="/" className="btn btn-primary btn-sm">Back to Login</NavLink>
												</p>
											</>
										}
										{subDomainUrl.toLowerCase() !== 'fpp-payment-status' && subDomainUrl.toLowerCase() !== 'xgen' &&
											<>
												<div className="PaymentLogoWrapper">
													<img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} width="" height="" alt="Partner Logo" className="PaymentLogoImg" />
												</div>
												{(paymentStatus === 'Success' || paymentStatus === 'complete') &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is successful !</h1>
														<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. We are so excited to have you on board. You can download a copy of your receipt <NavLink target="_blank" to={subDomainUrl.toLowerCase() === 'flux' ? "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinderflux.pdf" : "/assets/Content/dummy/ShowMeAlternativesLLC-DOBinder.pdf"} download>here</NavLink>. If you have any questions, please contact our agency. </p>
													</>
												}
												{paymentStatus === 'error' &&
													<>
														<h1 className="pt-2 pb-2 text-center">Your payment is processing !</h1>
														<p className="text-center">Thank you so much for your payment! Your confirmation number is {paymentId}. Your Payment is still processing, So Please refresh this page after sometime. If you have any questions, please contact our agency. </p>
													</>
												}
											</>
										}
									</div>
								</div>
							</div>
						</div>
					</main>
					<SiteFooter /> {/*this call for site footer */}
				</>
			}
		</>
	)
}