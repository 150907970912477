import { useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js';
import { Button, showSuccess } from '../../../common/Common';
import { AddACHDebit, ChangeACHAutopayMethod } from '../../../services/PaymentServices';

export default function ACHForm(props) {
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState(false);

    const UpsertACHPaymentInfo = () => {
        setIsLoading(true);
        AddACHDebit().then(data => {
            let response = data;
            if (response.status === true) {
                var t = response.data.split(",");
                // Calling this method will open the instant verification dialog.
                stripe.collectBankAccountForPayment({
                    clientSecret: t[0],
                    params: {
                        payment_method_type: 'us_bank_account',
                        payment_method_data: {
                            billing_details: {
                                name: JSON.parse(localStorage.getItem("UserCredential"))?.FullName,
                                email: JSON.parse(localStorage.getItem("UserCredential"))?.UserName,
                            },
                        },
                    },
                    expand: ['payment_method'],
                })
                    .then(({ paymentIntent, error }) => {
                        if (error) {
                            console.error(error.message);
                            setIsLoading(false);
                            // PaymentMethod collection failed for some reason.
                        } else if (paymentIntent.status === 'requires_payment_method') {
                            // Customer canceled the hosted verification modal. Present them with other
                            // payment method type options.
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(true);
                            ChangeACHAutopayMethod(paymentIntent.payment_method.id, t[1]).then(data => {
                                let response = data;
                                if (response.status === true) {
                                    window.$('#modalChangeAccountInfo').modal('hide');
                                    showSuccess(response.message);
                                    $("#radio-CreditCard").prop("checked", false);
                                    $("#radio-ACH").prop("checked", true);
                                    $("#radio-CreditCard").prop("disabled", true);
                                    $("#radio-ACH").prop("disabled", true);
                                    setIsLoading(false);
                                }
                                else {
                                    setIsLoading(false);
                                }
                            });
                        }
                    });
            }
        });
    }

    return (
        <>

            <div className="input-wrapper-payment mb-3 text-center">
                <Button disabled={isLoading} type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => UpsertACHPaymentInfo()}>
                    <span id="button-text">
                        {isLoading ? <div className="spinner-border"></div> : "Complete ACH Process"}
                    </span>
                </Button>
            </div>
        </>
    )
}