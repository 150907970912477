import React, { Component } from 'react';
export class LoaderContents extends Component {
    render() {
        return (
            <>
                <div className="loader-contents">
                    <div className="loader-contents-body">
                        <span className="dot"></span>
                        <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
