import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
export class SiteFooter extends Component {
    render() {
        return (
            <>
                <footer className="footer-newDesign">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center footer-links-newDesign">

                                    {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl !== "Xgen" && JSON.parse(localStorage.getItem("SubDomain"))?.SubDomain === "rt" &&
                                        <>
                                            <a href="https://www.automatedinstallment.com/privacy-policy" target="_blank">Privacy Policy</a>
                                        </>
                                    }
                                    {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl !== "Xgen" && JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl !== "rt" &&
                                        <>

                                            <a href={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl + "/termsofuse"}>Terms and Conditions</a>
                                            <a href={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl + "/privacypolicy"}>Privacy Policy</a>
                                        </>
                                    }
                                    {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl === "Xgen" &&
                                        <>
                                            <a href={`/termsofuse`}>Terms and Conditions</a>
                                            <a href={`/privacypolicy`}>Privacy Policy</a>
                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}
