import { showError } from '../common/Common';
import { authHeader } from '../common/Common';

//get carriers
async function GetAllCarrier(searchMdl) {
    searchMdl = searchMdl == undefined ? searchMdl = {} : searchMdl;
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/managecarrier/GetAllCarriers', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//add and edit carrier by ga
async function AddEditCarrier(carrierMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(carrierMdl)
    };
    const GetResponse = await fetch('/api/managecarrier/AddEditCarrier', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get carrier for edit
async function GetCarrierById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/managecarrier/GetCarrierById/?id=' + id, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function DeleteCarrierById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/managecarrier/DeleteCarrierById/?id=' + id, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllBlockedCarriers(searchMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/managecarrier/GetAllBlockedCarriers', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpsertBlockedCarrier(blockedCarrierMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(blockedCarrierMdl)
    };
    const GetResponse = await fetch('/api/managecarrier/UpsertBlockedCarrier', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function DeleteBlockedCarrier(blockedCarrierId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/managecarrier/DeleteBlockedCarrier/?blockedCarrierId=' + blockedCarrierId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
export { GetAllCarrier, GetCarrierById, AddEditCarrier, DeleteCarrierById, GetAllBlockedCarriers, UpsertBlockedCarrier, DeleteBlockedCarrier };