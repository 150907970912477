import { showError } from '../common/Common';
import { authHeader } from '../common/Common';
//Add/Edit Dynamic Tags
async function AddEditTag(TagMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(TagMdl)
    };
    const GetResponse = await fetch('/api/template/SaveTag', requestOptions)
        .then((response) => {
            return response.json();
        });

    if (GetResponse.status === true) {
        return GetResponse;
    }
    else {

        showError(GetResponse.message);
    }
}

//get Get All Tags
async function GetAllTag(searchMdl, SubDomainId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/template/GetAllTag?SubDomainId=${SubDomainId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get Get All Templates
async function GetAllTemplates(searchMdl, SubDomainId, Is_EmailMessageTemplete = false, Is_TextMessageTemplete = false) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/template/GetAllTemplates?SubDomainId=${SubDomainId}&Is_EmailMessageTemplete=${Is_EmailMessageTemplete}&Is_TextMessageTemplete=${Is_TextMessageTemplete}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get Template By Id
async function GetTemplateNameById(id, SubDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/template/GetTemplateNameById/?id=${id}&SubDomainId=${SubDomainId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get tag info for edit
async function GetTagNameById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }  
    };
    const GetResponse = await fetch('/api/template/GetTagNameById/?id=' + id, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function DeleteTagById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/template/DeleteTagById/?id=' + id, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllCompleteTag(SubDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/template/GetAllCompleteTag?SubDomainId=${SubDomainId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Save Template
async function SaveTemplate(TemplateMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(TemplateMdl)
    };
    const GetResponse = await fetch('/api/template/SaveTemplate', requestOptions)
        .then((response) => {
            return response.json();
        });

    if (GetResponse.status === true) {
        return GetResponse;
    }
    else {

        showError(GetResponse.message);
    }
}

export { AddEditTag, GetAllTag, GetTagNameById, DeleteTagById, GetAllTemplates, GetTemplateNameById, GetAllCompleteTag, SaveTemplate};