import React from 'react';
import { Route, NavLink, Redirect } from 'react-router-dom';
import { FlowGroups } from '../../common/Enum';
import { authHeader } from '../../common/Common';
/*import { grantPermission } from '../../common/GrantPermission';*/
import { LiveChat } from '../../services/LiveChat';
class CommonLayoutComponent extends React.Component {
	render() {
return (
	<>
		{this.props.children}
		<LiveChat />
    </>   
        );
	}
	
}

const InternalLayout = ({ component: Component, props: Props, ...rest }) => {
	
	return (

			<Route {...rest} render={matchProps => (
			<CommonLayoutComponent {...matchProps}>
				<Component props={Props} {...matchProps} />
				</CommonLayoutComponent>
			)} />
		)
};
//const InternalLayout = ({ component: Component, roles, ...rest }) => {
//	return (
//		<>
//			{grantPermission(roles) && (
//				<Route {...rest} render={matchProps => (
//					<CommonLayoutComponent {...matchProps}>
//						<Component {...matchProps} />
//					</CommonLayoutComponent>
//				)} />

//			)}
//			{
//				!grantPermission(roles)  && (
//					<Route
//						render={() => (
//							<>
//								<Redirect to="/" />

//							</>
//						)}
//					/>
//				)
//			}
//		</>


//	)
//};
export default InternalLayout;