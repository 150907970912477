import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { subDomain } from '../../../common/Common';
import { GetUserProfile } from '../../../services/UserServices';

class XgenOwnerDashboard extends Component {
    //this is for insured user
    constructor(props) {
        super(props);
        this.state = {
            userId: null
        };
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("UserCredential"));
        if (user != null) {
            this._GetUserProfile();
        }
    };

    // get user profile
    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ userId: response.data.id });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });

    }

    render() {
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <h1 className="h3 mb-0 fw-normal"><strong>Account Summary</strong></h1>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100 dashboard-card">
                                    <div className="card-header bg-white border-white p-3">
                                        <h5 className="card-title mb-0 text-muted">Please Add your New Partners from Manage Partners Tab</h5>
                                    </div>
                                    <div className="card-body d-flex">
                                        You can modify as well as delete them too.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
            </>
        );
    }
}
export default XgenOwnerDashboard;