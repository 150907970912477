import { authHeader } from '../common/Common';
async function DownloadInsuranceCertificate() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
     const GetResponse = await fetch('/api/pdf/DownloadInsuranceCertificate/', requestOptions)
        .then((response) => {
            return response.blob();
        });
    return GetResponse;
}
export { DownloadInsuranceCertificate };