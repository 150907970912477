import { authHeader } from '../common/Common';
//Get All Access Modules
async function GetAllAccessModules() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/AccessModules/GetAllAccessModules', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Get All Access Modules by role id
async function GetAllAccessModulesBYRoleId(RoleId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/AccessModules/GetAllAccessModules?RoleId=' + RoleId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Get Access Modules By RoleId
async function GetAccessModulesByRoleId(RoleIds) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(RoleIds) // this as object type for multiple roleids

    };
    const GetResponse = await fetch('/api/AccessModules/GetAccessModulesByRoleId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
} 

//Get External Access Modules
async function GetExternalAccessModules() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/AccessModules/GetExternalAccessModules', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Add Access Modules To Role
async function AddAccessModulesToRole(roleAccessModulesMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(roleAccessModulesMdl)
    };
    const GetResponse = await fetch('/api/AccessModules/AddAccessModulesToRole', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

export { GetAllAccessModules, GetAccessModulesByRoleId, GetAllAccessModulesBYRoleId, AddAccessModulesToRole, GetExternalAccessModules};