import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { GetUserProfile } from '../../../services/UserServices';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BaseUrl } from '../../../common/Setting';
import { showError, subDomain } from "../../../common/Common";
import $ from 'jquery';

class ManageWidget extends Component {
    //this for agent user
    constructor(props) {
        super(props);
        this.state = {
            Errors: {},
            value: '',
            copied: false,
            framecodewoparameters: '',
            framecodeparameters: '',
            UserId: '',
            activeButton: {
                1: 'active',
            },
            SubDomain: subDomain(),
        }
    }
    componentDidMount() {
        this._GetUserProfile();
        $("#viewloadiframe").css("display", "none");
    };

    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ UserId: response.data.id });
                        this._generateWidget();
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });

    }

    _ViewWidget = () => {
        $('#loadiframe').html(this.state.value);
        $("#viewloadiframe").css("display", "block");
    }

    _generateWidget = () => {
        if (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl.toLowerCase() === "xgen") {
            var iframetestcode = '<iframe id="paymentresult" src="' + BaseUrl + '/process-payment?&user.userId=' + this.state.UserId + '&user.isShowPolicy=true" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            var iframecode = '<iframe id="iframecodewoparameters" src="' + BaseUrl + '/process-payment?&user.userId=' + this.state.UserId + '" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            this.setState({ framecodewoparameters: iframecode });
            $('#iframeHolder').html(iframetestcode);
            var iframecodeparameters = '<iframe id="iframecodeparameters" src="' + BaseUrl + '/process-payment?&user.userId=' + this.state.UserId + '&user.email=john.doe@email.com&user.full_name=johndoe&user.policy_number=po123&user.payment_amount=2150.00&user.invoice_number=IN123&user.insurance_company=XYZ&user.down_payment=650.00&user.monthly_amount=167.22&user.term_length=9&user.apr=0.83&user.service_fee=5.00&user.payment_expiry_date=2022-11-20&user.address=123 Schedule&user.coverage_type=18&user.city=Reno&user.state=CA&user.zip=85412&user.phone_number=1234567899&user.due_date=2022-11-22&user.policy_effective_date=2022-11-25" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            this.setState({ framecodeparameters: iframecodeparameters });
        }
        else {
            var iframetestcode = '<iframe id="paymentresult" src="' + BaseUrl + '/' + (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl) + '/process-payment?&user.userId=' + this.state.UserId + '&user.isShowPolicy=true" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            var iframecode = '<iframe id="iframecodewoparameters" src="' + BaseUrl + '/' + (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl) + '/process-payment?&user.userId=' + this.state.UserId + '" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            this.setState({ framecodewoparameters: iframecode });
            $('#iframeHolder').html(iframetestcode);
            var iframecodeparameters = '<iframe id="iframecodeparameters" src="' + BaseUrl + '/' + (JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl) + '/process-payment?&user.userId=' + this.state.UserId + '&user.email=john.doe@email.com&user.full_name=johndoe&user.policy_number=po123&user.payment_amount=2150.00&user.invoice_number=IN123&user.insurance_company=XYZ&user.down_payment=650.00&user.monthly_amount=167.22&user.term_length=9&user.apr=0.83&user.service_fee=5.00&user.payment_expiry_date=2022-11-20&user.address=123 Schedule&user.coverage_type=18&user.city=Reno&user.state=CA&user.zip=85412&user.phone_number=1234567899&user.due_date=2022-11-22&user.policy_effective_date=2022-11-25" border="0" frameborder="0" marginwidth="0" marginheight="0" allow="geolocation *;" width="100%" height="470px" ></iframe>';
            this.setState({ framecodeparameters: iframecodeparameters });
        }
    }

    handleButton = (id) => {
        this.setState({
            activeButton: {
                [id]: "active"
            }
        })
    }
    render() {
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="manage-widget p-4">
                        <div className="row">
                            <div className="content-main-widget pt-4 pb-4 ps-2 pe-2 col-md-7">
                                <div>
                                    <h1> XGen has the solution for brokers to easily accept payments from your insureds </h1>
                                </div>
                                <div>
                                    <h3>Our widget allows your insureds to purchase insurance directly from your website</h3>
                                    <ul className="manage-widget-ul">
                                        <li>Its easy to implement</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-main-widget pt-4 pb-4 col-md-5 content-main-widget-space">
                                {/*<div id="iframeHolder" className="iframeHolder-iframe">*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="row how-to-use">

                            <div className="col-lg-3 col-sm-4" id="MainNav">
                                <ul id="db_nav_list" className="manage-sidebar-list">
                                    <li><a className={`btn once ${this.state.activeButton[1]}`} href="#embedding" onClick={() => this.handleButton(1)}>Embedding the Widget</a></li>
                                    <li><a className={`btn once ${this.state.activeButton[2]}`} href="#passing-parameters" onClick={() => this.handleButton(2)}>Passing Parameters</a></li>
                                    <li><a className={`btn once ${this.state.activeButton[3]}`} href="#working-example" onClick={() => this.handleButton(3)}>Working Example</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-9 col-sm-8 ps-space" >
                                <h1>How to use our widget</h1>
                                <section id="embedding" className="embedding-wrapper">
                                    <h3 className="mt-5"> Embedding the widget on your website </h3>
                                    <p>
                                        1. Copy and page the HTML below onto a page of your website
                                    </p>
                                    <div className="row">
                                        <div className="col-lg-12  col-md-12 col-sm-12  col-12">
                                            <div className="manage-widget-iframes p-3">
                                                <p>
                                                    {this.state.framecodewoparameters}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <p>
                                        2. Your branded widget will now show up on your website
                                    </p>
                                    <p>
                                        3. All payment transactions will be tracked on your portal.Just login to <a href="/Fairmatic" target="_blank">www.app-uat.xgenbilling.com</a> anytime to get your update
                                    </p>
                                </section>
                                <div className="border-divider"></div>

                                <section id="passing-parameters" className="passing-parameters-wrapper">
                                    <h1 className="mt-3">Passing Parameters into the widget</h1>
                                    <p>
                                        If someone is a user of your platform, you already know some of the information that they’ll need to give Xgen to get insured. If a customer decides to use our system to process payments, they need a quick and easy way to do so that, you can supply some of the user information via parameters to the Payment widget.

                                        The iframe you use to embed Widget contains a URL that loads the iframe. You may add some or all of the parameters listed below to that URL, via javascript or other methods. That information will be used to pre-fill their application.
                                    </p>
                                    <h3>
                                        <span className="count-teal-color">1.</span> Parameters:
                                    </h3>
                                    <ul className="manage-widget-ul">
                                        <li>(Required) userId: Should be the AgentId</li>
                                        <li>(Required) email : The user’s email address in standard mailto:a@b.c format.</li>
                                        <li>(Required) full_name : The Insured user full name.</li>
                                        <li>(Required) policy_number : The user’s policy number for which the user has to make the payment.</li>
                                        <li>(Required) payment_amount : Total Premium Amount.</li>
                                        <li>(Optional) invoice_number : The user’s invoice number.</li>
                                        <li>(Optional) insurance_company : The Insured user Insurance Company.</li>
                                        <li>(Required) down_payment : Down Payment Amount. If not given use system settings.</li>
                                        <li>(Required) monthly_amount : Monthly Payment Amount. If not given use system settings.</li>
                                        <li>(Required) term_length : The Insured user policy term length.</li>
                                        <li>(Optional) apr : Annual percentage rate.</li>
                                        <li>(Optional) service_fee : Service Fee for the Payment.</li>
                                        <li>(Optional) payment_expiry_date : The Expiry Date of the Payment. format should be YYYY-MM-DD</li>
                                        <li>(Required) address : Address of the Insured.</li>
                                        <li>(Required) coverage_type : Coverage Type for now use 18.</li>
                                        <li>(Required) city : City of the Insured.</li>
                                        <li>(Required) state : State of the Insured.</li>
                                        <li>(Required) zip : Zip of the Insured.</li>
                                        <li>(Optional) phone_number : The user's phone number.</li>
                                        <li>(Required) due_date : the current policy first payment date. format should be YYYY-MM-DD</li>
                                        <li>(Required) policy_effective_date : the date which the current policy enters into force. format should be YYYY-MM-DD</li>
                                    </ul>
                                    <h3>
                                        <span className="count-teal-color">2.</span> Examples:
                                    </h3>
                                    {/*<p>*/}
                                    {/*    Example agent iframe code with no pre-filled data:*/}
                                    {/*</p>*/}

                                    {/*<div className="row">*/}
                                    {/*    <div className="col-lg-12  col-md-12 col-sm-12  col-12">*/}
                                    {/*        <div className="manage-widget-iframes p-3">*/}
                                    {/*            <p>*/}
                                    {/*                {this.state.framecodewoparameters}*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <p>Example agent iframe code with parameters added:</p>
                                    <div className="row">
                                        <div className="col-lg-12  col-md-12 col-sm-12  col-12">
                                            <div className="manage-widget-iframes p-3">
                                                <p>
                                                    {this.state.framecodeparameters}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="border-divider"></div>
                                <section id="working-example" className="working-example-wrapper">
                                    <h1 className="mt-3"> Working Example </h1>
                                    <p>Copy Your iframe in the textarea and check your Widget below.</p>

                                    <div className="row">
                                        <div className="col-lg-12  col-md-12 col-sm-12  col-12">
                                            <div className="manage-widget-iframes textarea-wrapper">
                                                <div className="embed-form">
                                                    <textarea value={this.state.value}
                                                        onChange={({ target: { value } }) => this.setState({ value, copied: false })} id="iframevalue"></textarea>

                                                    <CopyToClipboard text={this.state.value}
                                                        onCopy={() => this.setState({ copied: true })}>
                                                        <button className="btn btn-primary btn-large">Copy</button>
                                                    </CopyToClipboard>
                                                    <button className="btn btn-primary btn-large" onClick={() => this._ViewWidget()}>View</button>
                                                    {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" id="viewloadiframe">
                                        <div className="col-lg-12  col-md-12 col-sm-12  col-12">
                                            <div className="manage-widget-iframes textarea-wrapper">
                                                <div id="loadiframe">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>

            </>
        );
    }
}
export default ManageWidget;