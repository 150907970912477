import React  from 'react';
import { ExternalPopupModel } from "./ExternalPopupModel";
import { Route, NavLink } from 'react-router-dom';
import { authHeader, Logout } from '../../common/Common';

class CommonLayoutComponent extends React.Component {

    render() {
        let UserDetails = authHeader();
        return (
            <>
                <div className="main-wrapper-newDesign h-100 d-flex">
                <div className="header d-flex justify-content-md-between align-items-center border-bottom bg-white">
                        <div className="d-flex align-items-center ps-3 header-left">
                            {UserDetails !== null ? (<NavLink to="#" className="d-inline-block me-3 menu-open">
                                <svg version="1.2" viewBox="0 0 40 30" width="20" height="20">
                                    <g>
                                        <g>
                                            <path className="s0" fill="#000000" d="m37.61 5.63h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z" />
                                            <path className="s0" fill="#000000" d="m37.61 16.14h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z" />
                                            <path className="s0" fill="#000000" d="m37.61 26.64h-34.93c-0.72 0-1.3-0.58-1.3-1.3c0-0.72 0.58-1.3 1.3-1.3h34.93c0.72 0 1.3 0.58 1.3 1.3c0 0.72-0.58 1.3-1.3 1.3z" />
                                        </g>
                                    </g>
                                </svg>
                            </NavLink>) : (<span></span>)}
                        
                            {/*<NavLink to="#"><img src={"assets/Content/images/" + logoUrl + "-logo.png"} alt="Xgenpayment" className={logoUrl === 'Xgen' ? 'logo' : 'logo-100p'} /></NavLink>*/}
                            <NavLink to="#"><img src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} alt="XgenPayment" className="logo-100p" /></NavLink>
                        </div>
                        {UserDetails === null ? (<span></span>) : (<ul className="nav nav-pills my-3 me-3">
                            <li className="nav-item">
                                <NavLink to="#" className="p-1 me-2" aria-current="page">
                                    <svg width="20" height="20" viewBox="0 0 35 35" data-bs-toggle="tooltip" data-bs-placement="bottom" title="StartOver">
                                        <g>
                                            <g>
                                                <path className="s0" fill="#000000" d="m15.28 27.44l0.15 0.04c5.27 1.61 11.01-1.14 13.07-6.25c2.2-5.48-0.46-11.74-5.95-13.94c-5.07-2.04-10.85 0.05-13.43 4.87l-0.07 0.13l-2.85-1.15l0.08-0.16c1.55-3.05 4.18-5.41 7.41-6.63c3.24-1.23 6.8-1.19 10.01 0.1c7.05 2.83 10.48 10.87 7.64 17.92c-2.69 6.69-10.17 10.19-17.03 7.97l-0.17-0.05l1.14-2.84z" />
                                                <path className="s0" fill="#000000" d="m3.49 14.95l-0.69-5.51l5.12 2.16l5.12 2.16l-4.43 3.36l-4.43 3.36l-0.69-5.51z" />
                                            </g>
                                        </g>
                                    </svg>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="p-1 me-1" aria-current="page" to="#" data-bs-toggle="modal" data-bs-target="#modalHelp" >
                                    <svg version="1.2" viewBox="0 0 40 40" width="20" height="20" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Tooltip on top">
                                        <g id="Master Layer 7">
                                            <path id="&lt;Compound Path&gt;" fillRule="evenodd" className="s0" fill="#000000" d="m20 0c11 0 20 9 20 20c0 11-9 20-20 20c-11 0-20-9-20-20c0-11 9-20 20-20zm0 2.22c-9.78 0-17.78 8-17.78 17.78v0c0 9.78 8 17.78 17.78 17.78h0c9.78 0 17.78-8 17.78-17.78v0c0-9.78-8-17.78-17.78-17.78h0z" />
                                        </g>
                                        <g id="Layer 1">
                                            <path id="&lt;Path&gt;" className="s0" fill="#000000" d="m17.9 31.45v-3.59c0-0.2 0.17-0.37 0.37-0.37h3.59c0.2 0 0.37 0.17 0.37 0.37v3.59c0 0.2-0.17 0.37-0.37 0.37h-3.59c-0.2 0-0.37-0.17-0.37-0.37z" />
                                            <path id="&lt;Path&gt;" className="s0" fill="#000000" d="m21.91 26.68h0h-4.1c-0.2 0-0.37-0.17-0.37-0.37c0-0.01 0-0.02 0-0.03c-0.01-0.28-0.01-0.51-0.01-0.68c0-0.24-0.01-0.37-0.01-0.37c0-0.68 0.06-1.31 0.17-1.89c0.12-0.58 0.29-1.1 0.53-1.56l0 0l0 0l0 0c0 0 0-0.01 0.01-0.01c0.23-0.46 0.58-0.93 1.04-1.44c0.45-0.49 1.02-1.02 1.69-1.57c0.65-0.53 1.17-0.97 1.57-1.32c0.38-0.34 0.62-0.58 0.73-0.73l0 0c0.01-0.01 0.02-0.02 0.03-0.03c0.16-0.22 0.29-0.45 0.37-0.69c0.09-0.25 0.13-0.52 0.13-0.79c0-0.77-0.31-1.43-0.93-1.99l0 0l0 0l0 0c0 0-0.01-0.01-0.01-0.01c-0.31-0.27-0.68-0.48-1.09-0.62c-0.44-0.14-0.93-0.22-1.49-0.22c-0.53 0-1.02 0.08-1.46 0.23c-0.44 0.15-0.83 0.37-1.18 0.67c-0.35 0.3-0.65 0.67-0.89 1.13c-0.25 0.47-0.44 1.02-0.58 1.66l0 0c-0.04 0.19-0.23 0.32-0.42 0.29l-4.14-0.51l0 0c-0.2-0.03-0.35-0.21-0.32-0.41l0 0c0.06-1 0.31-1.93 0.74-2.78c0.43-0.86 1.04-1.64 1.84-2.34l0.24 0.28l-0.24-0.28c0 0 0.01-0.01 0.01-0.01c0.79-0.7 1.71-1.22 2.75-1.57c1.04-0.35 2.19-0.52 3.46-0.52c1.33 0 2.54 0.18 3.61 0.53c1.08 0.36 2.03 0.89 2.83 1.6c0.8 0.71 1.41 1.48 1.81 2.32c0.41 0.84 0.61 1.74 0.61 2.7c0 0.53-0.08 1.05-0.23 1.56c-0.15 0.5-0.38 0.99-0.68 1.47l-0.31-0.2l0.31 0.2c0 0.01-0.01 0.01-0.01 0.01c-0.3 0.46-0.75 1.01-1.37 1.63c-0.61 0.61-1.38 1.3-2.31 2.08c-0.46 0.39-0.84 0.73-1.13 1.04c-0.27 0.29-0.45 0.54-0.55 0.75c-0.1 0.21-0.17 0.5-0.22 0.89c-0.05 0.42-0.07 0.93-0.06 1.53l0 0c0 0.2-0.16 0.37-0.36 0.38h-0.01z" />
                                        </g>
                                    </svg>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="p-1 me-1" aria-current="page" onClick={() => Logout()} to="#" >
                                    <svg version="1.2" width="20" height="20" viewBox="0 0 24 24" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Logout">
                                        <path className="s0" fill="#000000" d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" /></svg>
                                </NavLink>
                            </li>
                        </ul> ) }
                    {/* <img src="images/christina.jpeg" className="header-avatar rounded-circle" width="50" height="50" alt="" /> */}

                        {UserDetails === null ? (<div></div>) : (
                            <div id="sidebar" className="fixed-top d-flex flex-wrap py-3 px-2 h-100">
                        <button type = "button" className = "btn-close position-absolute btn-close-white" aria-label="Close"></button>
                    <ul className="list-group list-group-flush w-100 main-nav pt-2">
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="Home">Home</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="FAQ">FAQ</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="Claims">Claims</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="Giveback">Giveback</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="Blog">Blog</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="API">API</NavLink></li>
                        <li className="list-group-item border-0"><NavLink to="#" className="text-decoration-none text-white d-block" title="Start Over">Start Over</NavLink></li>
                    </ul>
                    <ul className="list-inline align-self-end d-flex justify-content-center w-100 social-links">
                        <li className="list-inline-item"><NavLink to="#" className="d-inline-block bg-secondary p-3 rounded rounded-circle" title="Facebook">
                            <svg viewBox="0 0 100 100" width="35" height="35">
                                <use id="Background" href="#img1" x="0" y="0" />
                                <path className="s0" fill="#FFFFFF" d="m57.56 54.63v37.33h-16.7v-37.33h-13.57v-15.44h13.57v-11.77c0-13.39 7.98-20.79 20.18-20.79c3.9 0 7.88 0.35 11.96 1.04v13.15h-6.74c-6.64 0-8.71 4.12-8.71 8.34v10.02h14.82l-2.37 15.44h-12.45z" />
                            </svg>
                        </NavLink></li>
                        <li className="list-inline-item">
                            <NavLink to="#" className="d-inline-block bg-secondary p-3 rounded rounded-circle" title="Instagram">
                                <svg viewBox="0 0 100 100" width="35" height="35">
                                    <g>
                                        <path className="s0" fill="#ffffff" d="m73.13 32.17c0 2.29-1.86 4.15-4.15 4.15c-2.29 0-4.15-1.86-4.15-4.15c0-2.29 1.86-4.15 4.15-4.15c2.29 0 4.15 1.86 4.15 4.15z" />
                                        <path fillRule="evenodd" className="s0" fill="#ffffff" d="m67.96 50.77c0 9.61-7.82 17.43-17.43 17.43c-9.61 0-17.43-7.82-17.43-17.43c0-9.61 7.82-17.43 17.43-17.43c9.61 0 17.43 7.82 17.43 17.43zm-6.26 0c0-6.16-5.01-11.16-11.16-11.16c-6.16 0-11.16 5.01-11.16 11.16c0 6.16 5.01 11.16 11.16 11.16c6.16 0 11.16-5.01 11.16-11.16z" />
                                        <path fillRule="evenodd" className="s0" fill="#ffffff" d="m64.37 86.15h-28.25c-11.72 0-21.26-9.53-21.26-21.26v-28.25c0-11.72 9.53-21.26 21.26-21.26h28.25c11.72 0 21.26 9.53 21.26 21.26v28.25c0 11.72-9.54 21.26-21.26 21.26zm-28.25-64.11c-8.05 0-14.6 6.55-14.6 14.6v28.25c0 8.05 6.55 14.6 14.6 14.6h28.25c8.05 0 14.6-6.55 14.6-14.6v-28.25c0-8.05-6.55-14.6-14.6-14.6h-28.25z" />
                                    </g>
                                </svg>
                            </NavLink>
                        </li>
                        <li className="list-inline-item"><NavLink to="#" className="d-inline-block bg-secondary p-3 rounded rounded-circle" title="Twitter">
                            <svg viewBox="0 0 100 100" width="35" height="35">
                                <use id="Background" href="#img1" x="0" y="0" />
                                <path className="s0" fill="#ffffff" d="m95.23 23.06c0 0.02 0 0.05 0 0.07c0 0.22-0.01 0.76-0.46 1.37c-2.01 2.7-4.18 5.12-6.87 7.15c-0.39 0.29-1.05 0.6-1.05 1.16c0 14.2-3.63 28.38-13.98 38.66c-13.18 13.09-32.95 16.65-50.5 11.81c-3.4-0.94-6.65-2.25-9.77-3.91c-1.03-0.56-2.05-1.15-3.04-1.8c-0.25-0.2-0.44-0.39-0.23-0.52c0.21-0.11 0.38-0.12 1.16 0c4.91 0.48 9.97-0.25 14.61-1.87c2.55-0.89 8.04-2.69 9.62-5.1c0.04-0.06 0.08-0.06 0.11-0.11c-0.64-0.15-1.28-0.15-1.92-0.26c-4.77-1.36-11.14-3.76-14.09-11.79c-0.19-0.47 0-0.71 0.49-0.62c3.92 0.31 5.33 0.09 6.7-0.21c-0.77-0.13-1.56-0.39-2.27-0.7c-6.01-2.29-10.4-8.14-11.19-14.48c-0.09-0.73-0.15-1.35-0.11-2.03c0.04-0.47 0.25-0.62 0.66-0.36c3.09 1.6 6.56 1.97 6.84 1.92c-0.77-0.6-1.48-1.26-2.18-1.92c-5.21-4.93-7.23-14.97-3.06-21.22c0.34-0.51 0.56-0.51 0.98-0.04c9.53 10.86 21.26 15.92 35.42 18.03c0.37 0.06 0.41 0 0.36-0.41c-0.37-2.49-0.42-4.93 0.19-7.4c0.63-2.56 1.85-4.97 3.5-7.01c1.6-1.97 3.61-3.61 5.86-4.79c2.25-1.17 4.73-1.89 7.27-2.05c2.6-0.17 5.24 0.25 7.66 1.23c1.84 0.75 3.47 1.76 4.99 3.06c0.32 0.26 0.6 0.58 0.9 0.92c0.19 0.19 0.41 0.26 0.71 0.19c3.62-0.91 7.02-2.18 10.31-3.94c0.15-0.07 0.27-0.11 0.46-0.04c0.15 0.11 0.09 0.3 0.02 0.45c-0.59 1.92-1.63 3.7-2.89 5.26c-0.82 1.02-2.88 3.56-4.28 3.79c2.95-0.49 5.97-1.24 8.7-2.48c0.35-0.16 0.35-0.23 0.35 0z" />
                            </svg>
                        </NavLink></li>
                    </ul>
                </div>)}
                    
                </div>
                    {this.props.children}
                    {UserDetails === null ? (<span></span>) : (<ExternalPopupModel />)}
                    
                </div>
            </>
        );
    }
}
const ExternalLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <CommonLayoutComponent {...matchProps}>
                <Component {...matchProps} />
            </CommonLayoutComponent>
        )} />
    )
};
export default ExternalLayout;