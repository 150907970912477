import React, {Component} from "react";
import {InternalLayoutHeader} from "../../layout/InternalLayoutHeader";
import {LoaderContents} from "../../layout/LoaderContents";
import ReactPaginate from "react-paginate";
import {SiteFooter} from "../../layout/SiteFooter";
import ReactCrop from "react-image-crop";
import Select from "react-select";
import { GetAllBlockedCarriers, UpsertBlockedCarrier, DeleteBlockedCarrier} from "../../../services/ManageCarrierService";
import {showError, showSuccess} from "../../../common/Common";
import validator from "validator";

class BlockedCarriers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blockedCarrierMdl : {
                carrierId: null,
                carrierName: null

            },
            searchMdl: {
                Id: null, 
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1,
                SelectedDate: null,
                ToDate: null,
                ContentsLoaderStatus: false,
                SaveLoaderStatus: false
            },
            Pagecount: 0,
            AllBlockedCarriers: [],
            Errors: {},
            BlockedCarrierId: null,
        }
    }
    
    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        this._GetAllBlockedCarriers(this.state.searchMdl);
    };
    
    _GetAllBlockedCarriers = (searchMdl) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllBlockedCarriers(searchMdl).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllBlockedCarriers: response.data.data });
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data

                } else {
                    showError(response.message);
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
                }
                this.setState({ PageLoaderStatus: false });
            }
        });
    }
    
    _AddEditBlockedCarrier_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this.UpsertBlockedCarrier();
        }
    };
    
    UpsertBlockedCarrier = () => {
        let status = this.BlockedCarrierValidation();
        if (status) {
            this.setState({ SaveLoaderStatus: true }); //  loader before  data save
            UpsertBlockedCarrier(this.state.blockedCarrierMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#modalAddNewBlockedCarrier').modal('hide');
                        this.resetBlockedCarrierMdl();
                        this._GetAllBlockedCarriers(this.state.searchMdl);
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                }
            });
        }
    }
    
    EditBlockedCarrier = () => {
        let carrier = this.state.AllBlockedCarriers.filter(carrier => carrier.Id === this.state.blockedCarrierId);
        this.setState({ blockedCarrierMdl: carrier[0] });
        window.$('#modalAddNewBlockedCarrier').modal('show');
    }
    
    BlockedCarrierValidation = () => {
        let errors = {};
        let count = 0;
        
        if (this.state.blockedCarrierMdl.carrierId === null || this.state.blockedCarrierMdl.carrierId === undefined || this.state.blockedCarrierMdl.carrierId.trim() === "") {
            errors['IsEmpty_CarrierId'] = true;
            errors['CarrierIdEmptyMsg'] = "Please fill the required fields : Carrier Id";
            count++;
        }
        
        if (this.state.blockedCarrierMdl.carrierName === null || this.state.blockedCarrierMdl.carrierName === undefined || this.state.blockedCarrierMdl.carrierName.trim() === "") {
            errors['IsEmpty_CarrierName'] = true;
            errors['CarrierNameEmptyMsg'] = "Please fill the required fields : Carrier Name";
            count++;
        }
        
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    
    _DeleteBlockedCarrier = () => {
        this.setState({ deleteLoader: true });
        DeleteBlockedCarrier(this.state.BlockedCarrierId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    showSuccess(response.message);
                }
                else {
                    showError(response.message);
                }
                this._GetAllBlockedCarriers(this.state.searchMdl);
                this.setState({ BlockedCarrierId: null, deleteLoader: false })
                window.$('#confirmdelete').modal('hide');

            }
        });
    }
    
    addBlockedCarrierMdl = () => {
        this.setState({Errors: []})
        this.setState({
            blockedCarrierMdl: {
                carrierId: null,
                carrierName: null
            }
        });
        window.$('#modalAddNewBlockedCarrier').modal('show');
    }
    
    resetBlockedCarrierMdl = () => {
        this.setState({Errors: []})
        this.setState({
            blockedCarrierMdl: {
                carrierId: null,
                carrierName: null
            }
        });
    }
    
    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'carrierId') {
            this.setState((prevState) => ({
                blockedCarrierMdl: {
                  ...prevState.blockedCarrierMdl,
                  carrierId: value,
                },
            }));
        }
        if (name === 'carrierName') {
            this.setState((prevState) => ({
                blockedCarrierMdl: {
                  ...prevState.blockedCarrierMdl,
                  carrierName: value,
                },
            }));
        }
    };
    
    handlePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this._GetAllBlockedCarriers(this.state.searchMdl);
    }
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this._GetAllBlockedCarriers(this.state.searchMdl);
    }
    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this._GetAllBlockedCarriers(this.state.searchMdl);
    }
    render() {
        const { AllBlockedCarriers, Pagecount } = this.state;
        let data = AllBlockedCarriers.slice(this.state.searchMdl.PageNumber * this.state.searchMdl.LimitTo, (this.state.searchMdl.PageNumber + 1) * this.state.searchMdl.LimitTo);
        return (
            <>
                <div className="main-wrapper-newDesign">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-5 col-12">
                                <h1 className="h3 mb-0 fw-normal"><strong>Blocked Carriers</strong></h1>
                            </div>
                            <div className="col-xl-4 col-xxl-4  col-lg-4 col-sm-7 col-12 text-end">
                                <button type="button" id="addNewBlockedCarrier" className="btn-newDesign btn-header center-aligned" onClick={() => this.addBlockedCarrierMdl()}>
                                    Add New Blocked Carrier
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search..." />
                                                {this.state.ContentsLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "200px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>

                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Carrier Id</th>
                                                        <th>Carrier Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AllBlockedCarriers.map((items, index) => (
                                                        <tr key={index}>
                                                            <td>{items.carrierId}</td>
                                                            <td>{items.carrierName}</td>
                                                            <td>
                                                                <div class="button-container">
                                                                    {items.carrierId !== 1 && <button type="button" id={items.carrierId} className="btn-newDesign center-aligned copylinkbutton" onClick={() => this.setState({ BlockedCarrierId: items.carrierId  }, () => this.EditBlockedCarrier()  )}>Edit</button>}
                                                                        {items.carrierId !== 1 && <button type="button" id={items.carrierId} className="btn-newDesign-foil center-aligned copylinkbutton" data-bs-toggle="modal" data-bs-target="#confirmdelete" onClick={() => this.setState({ BlockedCarrierId: items.carrierId })}>Delete</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
    
                                            <p className="text-center">
                                                {(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
                                            </p>
                                            <div className="pt20px">
                                                { /*loader show befoure load contents*/}
                                                {this.state.PageLoaderStatus && <LoaderContents />}
                                            </div>
                                        </div>
                                        <div className="d-flex paginate-div">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                onPageChange={this.handlePageClick}
                                                pageCount={Pagecount ?? 0}
                                                containerClassName={"pagination justify-content-start"}
                                                pageClassName={"page-item page-item-none"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item page-item-none"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledLinkClassName={"disabled"} />
                                                <div className="pagination-page">
                                                    Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>
                { /*model for adding blocked carrier */}
                <div className="modal fade" tabIndex="-1" id="modalAddNewBlockedCarrier" aria-labelledby="AddNewBlockedCarrier" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-3 border-0">
                                <h5 className="modal-title">{this.state.ModelTitle ?? ""}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="position-relative step-register w-75 m-auto" id="frmaddnewblockedcarrier" onKeyDown={this._AddEditBlockedCarrier_EnterButton}>
                                    <fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
                                        <p className="modal-header-newDesign">Carrier Id</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input name="carrierId" className={(this.state.Errors['IsEmpty_CarrierId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} type="text" ref="CarrierId" id="CarrierId" placeholder="Carrier Id" value={this.state.blockedCarrierMdl.carrierId ?? ''} onInput={this.handleInputChange} required />
                                            <label htmlFor="CarrierId">Carrier Id</label>
                                            {this.state.Errors['IsEmpty_CarrierId'] === true ? <div className="has-err-msg">{this.state.Errors['CarrierIdEmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">Carrier Name</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input name="carrierName" className={(this.state.Errors['IsEmpty_CarrierName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="CarrierName" type="text" value={this.state.blockedCarrierMdl.carrierName ?? ''} placeholder="Carrier Name" id="CarrierName" onInput={this.handleInputChange} required />
                                            <label htmlFor="CarrierName">Carrier name</label>
                                            {this.state.Errors['IsEmpty_CarrierName'] === true ? <div className="has-err-msg">{this.state.Errors['CarrierNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <div className="text-center d-flex justify-content-center">
                                                <button type="button" id="btncloseMdl" data-bs-dismiss="modal" onClick={() => this.resetBlockedCarrierMdl()} className="btn btn-newDesign-reverse me-2">Close</button>
                                                <button type="button" className="btn btn-newDesign" onClick={() => this.UpsertBlockedCarrier()}>Submit</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                { /*model for delete Blocked Carrier  */}
                <div className="modal fade" id="confirmdelete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title" id="staticBackdropLabel">Delete Blocked Carrier</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body text-center">
                                Do you want to delete this carrier, allowing it to receive payment links ? {this.state.deleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="button-container">
                                <button type="button" id="btncanclemail" className="btn-newDesign-reverse" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" id="btnsendmail" className="btn-newDesign" onClick={() => this._DeleteBlockedCarrier()}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    
}

export default BlockedCarriers