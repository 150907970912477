import React, { Component, PureComponent, useRef } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { showError, showSuccess, Button, subDomain } from '../../../common/Common';
import { LoaderContents } from "../../layout/LoaderContents";
import { GetAllPartners, AddPartner, UpdatePartner, DeletePartnerById, GetPartnerById, GetAllPaymentProcess } from '../../../services/PartnerServices';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import validator from 'validator';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Select from 'react-select';

class ManagePartnersByXgenOwner extends Component {
    //this for agent partner
    constructor(props) {
        super(props);
        this.state = {
            PartnerMdl: {
                id: 0,
                brandName: null,
                color: null,
                hoverFocus: null,
                logo: null,
                fontFamily: null,
                label1: null,
                label2: null,
                emailAddress: null,
                url: null,
                blobImage: null,
                premiumFinance: false,
                directBillingFinance: false,
                multiplePolicies: false,
                paymentProcess: null,
                paymentProcessId: null,
                PfpaymentMethod: null,
                DbpaymentMethod: null,
                isAISDb: false,
                isAISPF: false,
                isBDSecurePF: false,
                isFinanceProPF: false,
            },
            AllPaymentProcess: [],
            partnerId: 0,
            Errors: {},
            AllPartnerData: [],
            reponseMessage: '',
            IsEmailAreadyexists: null,
            EmailAreadyexistsLoaderStatus: false,
            SaveLoaderStatus: false,
            ContentsLoaderStatus: false,
            deleteLoader: false,
            SubDomain: subDomain(),
            searchMdl: {
                Id: null, //use for partner id
                GeneralSearch: null,
                LimitTo: 5,
                PageNumber: 1,
                SelectedDate: null,
                ToDate: null,
                ContentsLoaderStatus: false,
                SaveLoaderStatus: false
            },
            Pagecount: 0,
            ModelTitle: null,
            brandName: null,
            color: null,
            hoverFocus: null,
            image: null,
            fontFamily: null,
            label1: null,
            label2: null,
            emailAddress: null,
            //premiumFinance: false,
            blobImage: null,
            url: null,
            value: null,
            src: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 16 / 9,
                height: 30
            },
            PageLoaderStatus: false,
            selectedPaymentProcess: null,
            selectedPaymentProcessType: 0

        }
        this.handlePfPaymentMethodChange = this.handlePfPaymentMethodChange.bind(this);
        this.handleDbPaymentMethodChange = this.handleDbPaymentMethodChange.bind(this);
        this.IsPremiumFinance = this.IsPremiumFinance.bind(this);
        this.IsDirectBillingmFinance = this.IsDirectBillingmFinance.bind(this);
    }

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);

            let img = event.target.files[0];
            this.setState({
                //image: URL.createObjectURL(img)
                image: img
            });
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        const img = new Image()
        img.src = this.state.src;
        img.className = "ReactCrop__image";
        this.imageRef = img;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1
            );
        });
    }


    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        this._GetAllPartner(this.state.searchMdl);
        this._GetAllPaymentProcess();
    };
    //form submit on enter key down
    _AddEditPartner_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._AddEditPartner();
        };
    };

    //onChangeUrl = event => {
    //    this.setState({ url: event.target.value.replace(/[^\w\s]/gi, "") });
    //};
    CheckSpecialCharacters = (value) => {
        var nospecialcharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (nospecialcharacters.test(value)) {
            return true;
        } else {
            return false;
        }
    };
    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            this.setState({
                //image: URL.createObjectURL(img)
                image: img
            });
        }
    };

    _DeletePartnerById = () => {
        this.setState({ deleteLoader: true });
        DeletePartnerById(this.state.partnerId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    showSuccess(response.message);
                }
                else {
                    showError(response.message);
                }
                this._GetAllPartner(this.state.searchMdl);
                this.setState({ partnerId: null, deleteLoader: false })
                window.$('#confirmdelete').modal('hide');

            }
        });
    }

    _AddPartner = (formData) => {
        let status = this.AddEditPartnerValidation(formData);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); //  loader before  data save

            AddPartner(formData).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#modalAddNewPartner').modal('hide');
                        this.resetPartnerProfileMdl();
                        this._GetAllPartner(this.state.searchMdl);
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                }
            });
        }
    }

    _UpdatePartner = (formData) => {
        let status = this.AddEditPartnerValidation(formData);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); //  loader before  data save
            UpdatePartner(formData).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#modalAddNewPartner').modal('hide');
                        this.resetPartnerProfileMdl();
                        this._GetAllPartner(this.state.searchMdl);
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                }
            });
        }
    }
    onUrlChange = event => {
        this.setState({ value: event.target.value.replace(/[^\w\s]/gi, "") });
    };

    IsPremiumFinance = (event) => {
        if (event.target.checked) {
            this.setState(Object.assign(this.state.PartnerMdl, { premiumFinance: true }));
        }
        else {
            this.setState(Object.assign(this.state.PartnerMdl, { premiumFinance: false, PfpaymentMethod:null, isAISPF: false, isBDSecurePF: false, isFinanceProPF: false }));
        }
    }
    IsDirectBillingmFinance = (event) => {
        if (event.target.checked) {
            this.setState(Object.assign(this.state.PartnerMdl, { directBillingFinance: true }));
        }
        else {
            this.setState(Object.assign(this.state.PartnerMdl, { directBillingFinance: false, DbpaymentMethod: null, isAISDB: false }));
        }
    }
    IsMultiplePolicies = (event) => {
        if (event.target.checked) {
            this.setState(Object.assign(this.state.PartnerMdl, { multiplePolicies: true }));
        }
        else {
            this.setState(Object.assign(this.state.PartnerMdl, { multiplePolicies: false }));
        }
    }
    handleDbPaymentMethodChange = (method) => {
        this.setState(prevState => ({
            PartnerMdl: {
                ...prevState.PartnerMdl,
                DbpaymentMethod: method
            }
        }));
        this.setPaymentMethod(method);
    };
    handlePfPaymentMethodChange = (method) => {
        this.setState(prevState => ({
            PartnerMdl: {
                ...prevState.PartnerMdl,
                PfpaymentMethod: method
            }
        }));
        this.setPaymentMethod(method);
    };

    setPaymentMethod = (method) => {
        if (method.includes('DB')) {
            this.setState(Object.assign(this.state.PartnerMdl, { isAISDB: ('AISDB' == method) ? true : false }));
        }
        else {
            this.setState(Object.assign(this.state.PartnerMdl, { isAISPF: ('AISPF' == method) ? true : false, isBDSecurePF: ('BDSecurePF' == method) ? true : false, isFinanceProPF: ('FinanceProPF' == method) ? true : false }));
        }
    };
    // call api for add and edit new partner by xgen owner

    _AddEditPartner = async () => {
        const formData = new FormData();
        formData.append("paymentProcessId", this.state.selectedPaymentProcessType);
        formData.append("brandName", this.refs.BrandName.value);
        formData.append("color", this.refs.Color.value);
        formData.append("hoverFocus", this.refs.HoverFocus.value);
        formData.append("fontFamily", this.refs.FontFamily.value);
        formData.append("label1", this.refs.Label1.value);
        formData.append("label2", this.refs.Label2.value);
        formData.append("emailAddress", this.refs.EmailAddress.value);
        formData.append("url", this.refs.Url.value);
        formData.append("blobImage", this.state.croppedImageUrl);
        formData.append("PremiumFinance", this.state.PartnerMdl.premiumFinance);
        formData.append("DirectBillingFinance", this.state.PartnerMdl.directBillingFinance);
        formData.append("MultiplePolicies", this.state.PartnerMdl.multiplePolicies);
        formData.append("isAISDB", (this.state.PartnerMdl.DbpaymentMethod === 'AISDB') ? true : false);
        formData.append("isAISPF", (this.state.PartnerMdl.PfpaymentMethod === 'AISPF') ? true : false);
        formData.append("isBDSecurePF", (this.state.PartnerMdl.PfpaymentMethod === 'BDSecurePF') ? true : false);
        formData.append("isFinanceProPF", (this.state.PartnerMdl.PfpaymentMethod === 'FinanceProPF') ? true : false);
        //if (this.state.PartnerMdl.paymentMethod === "AIS") {
        //    formData.append("isAIS", true);
        //    formData.append("isBDSecure", false);
        //    formData.append("isFinancePro", false);
        //} else if (this.state.PartnerMdl.paymentMethod === "BDSecure") {
        //    formData.append("isAIS", false);
        //    formData.append("isBDSecure", true);
        //    formData.append("isFinancePro", false);
        //}
        //else if (this.state.PartnerMdl.paymentMethod === "FinancePro") {
        //    formData.append("isAIS", false);
        //    formData.append("isBDSecure", false);
        //    formData.append("isFinancePro", true);
        //}


        if (this.state.croppedImageUrl !== null) {
            const config = { responseType: 'blob' };
            const myfile = await fetch(this.state.croppedImageUrl)
                .then(r => r.blob())
                .then(blobFile => new File([blobFile], "crop_image", { type: blobFile.type })).then((response) => {
                    formData.append("formFile", response);
                    formData.append("fileName", response.name);
                });;
        }
        else {
            if (this.state.croppedImageUrl === null && this.state.image !== null) {
                formData.append("formFile", this.state.image);
                formData.append("fileName", this.state.image.name);
            }
        }
        if (this.state.partnerId !== 0) {
            formData.append("id", this.state.partnerId);
            this._UpdatePartner(formData);
        } else {
            this._AddPartner(formData);
        }
    }
    //validation for registration 
    AddEditPartnerValidation = (formData) => {
        let errors = {};
        let count = 0;

        if (formData.get('emailAddress') === null || formData.get('emailAddress') === undefined || formData.get('emailAddress').trim() === "") {
            errors['IsEmpty_EmailAddress'] = true;
            errors['EmailAddressEmptyMsg'] = "Please fill the required fields : Email Address";
            count++;
        }
        else {
            if (!validator.isEmail(formData.get('emailAddress'))) {
                errors['IsEmpty_EmailAddress'] = true;
                errors['EmailAddressEmptyMsg'] = "Please fill the Valid fields : Email Address";
                count++;
            }
        }

        if (formData.get('brandName') === null || formData.get('brandName') === undefined || formData.get('brandName').trim() === "") {
            errors['IsEmpty_BrandName'] = true;
            errors['BrandNameEmptyMsg'] = "Please fill the required fields : Brand Name";
            count++;
        }

        if (formData.get('url') === null || formData.get('url') === undefined || formData.get('url').trim() === "") {
            errors['IsEmpty_Url'] = true;
            errors['UrlEmptyMsg'] = "Please fill the required fields : Url Name";
            count++;
        }
        else {
            if (this.CheckSpecialCharacters(formData.get('url'))) {
                errors['IsEmpty_Url'] = true;
                errors['UrlEmptyMsg'] = "No Special character allowed : Url Name";
                count++;
            }
        }

        if (formData.get('color') === null || formData.get('color') === undefined || formData.get('color').trim() === "") {
            errors['IsEmpty_Color'] = true;
            errors['ColorEmptyMsg'] = "Please fill the required fields : Color";
            count++;
        }

        if (formData.get('hoverFocus') === null || formData.get('hoverFocus') === undefined || formData.get('hoverFocus').trim() === "") {
            errors['IsEmpty_HoverFocus'] = true;
            errors['HoverFocusEmptyMsg'] = "Please fill the required fields : Hover and Focus";
            count++;
        }

        if (this.state.partnerId === 0 && (formData.get('fileName') !== null || formData.get('fileName') !== undefined)) {
            if (formData.get('fileName') === null || formData.get('fileName') === undefined) {
                errors['IsEmpty_Image'] = true;
                errors['ImageEmptyMsg'] = "Please fill the required fields : Image";
                count++;
            }
        }


        if (formData.get('fontFamily') === null || formData.get('fontFamily') === undefined || formData.get('fontFamily').trim() === "") {
            errors['IsEmpty_FontFamily'] = true;
            errors['FontFamilyEmptyMsg'] = "Please fill the required fields : Font Family";
            count++;
        }

        if (formData.get('label1') === null || formData.get('label1') === undefined || formData.get('label1').trim() === "") {
            errors['IsEmpty_Label1'] = true;
            errors['Label1EmptyMsg'] = "Please fill the required fields : First Payment Label";
            count++;
        }

        if (formData.get('label2') === null || formData.get('label2') === undefined || formData.get('label2').trim() === "") {
            errors['IsEmpty_Label2'] = true;
            errors['Label2EmptyMsg'] = "Please fill the required fields : Monthly Payment Label";
            count++;
        }

        if (formData.get('isAISDB') == 'false' && (formData.get('isAISPF') =='false' && formData.get('isBDSecurePF') == 'false' && formData.get('isFinanceProPF') == 'false')) {
            errors['IsEmpty_PaymentMethod'] = true;
            errors['PaymentMethodEmptyMsg'] = "Please fill the required fields :Payment Method Label";
            count++;
        }


        if (parseInt(formData.get('paymentProcessId')) === 0) {
            errors['IsEmpty_PaymentProcessId'] = true;
            errors['PaymentProcessIdEmptyMsg'] = "Please Select the required fields : Payment Process";
            count++;
        }


        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    resetPartnerProfileMdl = () => {
        this.setState({
            Errors: [],
            reponseMessage: '',
            ModelTitle: "Add New Partner",
            brandName: null,
            color: null,
            hoverFocus: null,
            image: null,
            fontFamily: null,
            label1: null,
            label2: null,
            emailAddress: null,
            premiumFinance: false,
            directBillingFinance: false,
            multiplePolicies: false,
            blobImage: null,
            url: null,
            paymentProcess: null,
            paymentProcessId: null,
            PartnerMdl: {
                id: 0,
                brandName: null,
                color: null,
                hoverFocus: null,
                logo: null,
                fontFamily: null,
                label1: null,
                label2: null,
                emailAddress: null,
                url: null,
                blobImage: null,
                image: null,
                paymentProcess: null,
                paymentProcessId: null,
                premiumFinance: false,
                directBillingFinance: false,
                multiplePolicies: false,
                isAISDb: false,
                isAISPF: false,
                isBDSecurePF: false,
                isFinanceProPF: false,
            },
            value: null,
            croppedImageUrl: null,
            src: null,
            partnerId: 0,
            selectedPaymentProcess: null,
            selectedPaymentProcessType: 0
        });
        $("#frmaddnewpartner").trigger("reset");
        window.$('#modalAddNewPartner').modal('show');
    }
    editPartnerInfo = (partnerId) => {
        this.resetPartnerProfileMdl();
        this.setState({ SaveLoaderStatus: true }); // show loader before  data edit
        GetPartnerById(partnerId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ PartnerMdl: response.data });
                    this.setState({ value: this.state.PartnerMdl.url });
                    this.setState({ src: this.state.PartnerMdl.logo });
                    this.setState({ selectedPaymentProcessType: this.state.PartnerMdl.paymentProcessId });
                    this.setPaymentMethodName(response.data)
                    window.$('#modalAddNewPartner').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
            }
        });
    }

    setPaymentMethodName = (data) => {
        if (data.isAISDB) {
            this.setState(Object.assign(this.state.PartnerMdl, { DbpaymentMethod: 'AISDB' }));
        }
        if (data.isAISPF) {
            this.setState(Object.assign(this.state.PartnerMdl, { PfpaymentMethod: 'AISPF' }));
        }
        else if(data.isBDSecurePF){
            this.setState(Object.assign(this.state.PartnerMdl, { PfpaymentMethod: 'BDSecurePF' }));
        }
        else if(data.isFinanceProPF){
            this.setState(Object.assign(this.state.PartnerMdl, { PfpaymentMethod: 'FinanceProPF' }));
        }
    }
    handlePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this._GetAllPartner(this.state.searchMdl);
    }
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this._GetAllPartner(this.state.searchMdl);
    }
    handleSearchData = (event) => {

        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this._GetAllPartner(this.state.searchMdl);
    }

    _GetAllPartner = (searchMdl) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllPartners(searchMdl).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllPartnerData: response.data.data });
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data

                } else {
                    showError(response.message);
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
                }
                this.setState({ PageLoaderStatus: false });
            }
        });
    }

    _GetAllPaymentProcess = () => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        let paymentProcessList = [];
        GetAllPaymentProcess().then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    for (let i = 0; i < response.data.length; i++) {
                        paymentProcessList.push({ 'value': response.data[i].paymentProcessId, 'label': response.data[i].paymentProcess });
                    }
                    this.setState({ AllPaymentProcess: paymentProcessList });
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data

                } else {
                    showError(response.message);
                    this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
                }
                this.setState({ PageLoaderStatus: false });
            }
        });
    }

    handleChange = (selectedPaymentProcess) => {
        this.setState({ selectedPaymentProcess });
        this.setState({ selectedPaymentProcessType: selectedPaymentProcess.value });
    };

    render() {
        const { AllPartnerData, Pagecount, crop, croppedImageUrl, src, selectedPaymentProcess, AllPaymentProcess } = this.state;
        let data = AllPartnerData.slice(this.state.searchMdl.PageNumber * this.state.searchMdl.LimitTo, (this.state.searchMdl.PageNumber + 1) * this.state.searchMdl.LimitTo);
        return (
            <>
                <div className="main-wrapper-newDesign">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-5 col-12">
                                <h1 className="h3 mb-0 fw-normal"><strong>Manage Partners</strong></h1>
                            </div>
                            <div className="col-xl-4 col-xxl-4  col-lg-4 col-sm-7 col-12 text-end">
                                <button type="button" id="addNewPartner" className="btn-newDesign btn-header center-aligned" onClick={() => this.resetPartnerProfileMdl()}>
                                    Add New Partner
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search..." />
                                                {this.state.ContentsLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "200px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>

                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Brand Name</th>
                                                        <th>Email Address</th>
                                                        <th>Url Name</th>
                                                        <th>Color</th>
                                                        <th>Hover And Focus</th>
                                                        <th>logo</th>
                                                        <th>Font Family</th>
                                                        <th>First Payment Label</th>
                                                        <th>Monthly Payment Label</th>
                                                        <th>Premium Finance</th>
                                                        <th>DirectBilling Finance</th>
                                                        <th>Multiple Policies</th>
                                                        <th>Payment Process</th>
                                                        <th colSpan="2">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AllPartnerData.map((items, index) => (
                                                        <tr key={index}>
                                                            <td>{items.brandName}</td>
                                                            <td>{items.emailAddress}</td>
                                                            <td>{items.url}</td>
                                                            <td>{items.color}</td>
                                                            <td>{items.hoverFocus}</td>
                                                            <td><img src={items.logo} alt="brand logo" height="50" width="100" /></td>
                                                            <td>{items.fontFamily}</td>
                                                            <td>{items.label1}</td>
                                                            <td>{items.label2}</td>
                                                            <td>{(items.premiumFinance == true) ? "Yes" : "No"}</td>
                                                            <td>{(items.directBillingFinance == true) ? "Yes" : "No"}</td>
                                                            <td>{(items.multiplePolicies == true) ? "Yes" : "No"}</td>
                                                            <td>{items.paymentProcess}</td>
                                                            <td>{items.id !== 1 && <button type="button" id={items.id} className="btn-newDesign center-aligned copylinkbutton" onClick={() => this.editPartnerInfo(items.id)}>Edit</button>}</td>
                                                            <td>{items.id !== 1 && <button type="button" id={items.id} className="btn-newDesign-foil center-aligned copylinkbutton" data-bs-toggle="modal" data-bs-target="#confirmdelete" onClick={() => this.setState({ partnerId: items.id })}>Delete</button>}</td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
    
                                            <p className="text-center">
                                                {(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
                                            </p>
                                            <div className="pt20px">
                                                { /*loader show befoure load contents*/}
                                                {this.state.PageLoaderStatus && <LoaderContents />}
                                            </div>
                                        </div>
                                        <div className="d-flex paginate-div">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                onPageChange={this.handlePageClick}
                                                pageCount={Pagecount ?? 0}
                                                containerClassName={"pagination justify-content-start"}
                                                pageClassName={"page-item page-item-none"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item page-item-none"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledLinkClassName={"disabled"} />
                                                <div className="pagination-page">
                                                    Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>
                { /*model for create payment link */}
                <div className="modal fade" tabIndex="-1" id="modalAddNewPartner" aria-labelledby="AddNewPartner" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-3 border-0">
                                <h5 className="modal-title">{this.state.ModelTitle ?? ""}</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="position-relative step-register w-75 m-auto" id="frmaddnewpartner" onKeyDown={this._AddEditPartner_EnterButton}>
                                    <fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
                                        <p className="modal-header-newDesign">Current Email</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input type="email" className={(this.state.Errors['IsEmpty_EmailAddress'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="EmailAddress" id="EmailAddress" placeholder="Email Address" defaultValue={this.state.PartnerMdl.emailAddress
                                                ?? ''} required />
                                            <label htmlFor="EmailAddress">Email Address</label>
                                            {this.state.Errors['IsEmpty_EmailAddress'] === true ? <div className="has-err-msg">{this.state.Errors['EmailAddressEmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">Brand Name</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_BrandName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="BrandName" type="text" defaultValue={this.state.PartnerMdl.brandName
                                                ?? ''} placeholder="Brand Name" id="BrandName" required />
                                            <label htmlFor="BrandName">Brand name</label>
                                            {this.state.Errors['IsEmpty_BrandName'] === true ? <div className="has-err-msg">{this.state.Errors['BrandNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="input-wrapper mx-auto mb-3">
                                            <p>{this.state.value === null || this.state.value === "" || this.state.PartnerMdl.url === "" ? "" : "https://" + window.location.hostname + "/" + this.state.value}</p>
                                        </div>
                                        <p className="modal-header-newDesign">Url Name</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input type="text" className={(this.state.Errors['IsEmpty_Url'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="Url" id="Url" defaultValue={this.state.PartnerMdl.url
                                                ?? ''} placeholder="Url Name" onChange={this.onUrlChange} required />
                                            <label htmlFor="Url">Url Name</label>
                                            {this.state.Errors['IsEmpty_Url'] === true ? <div className="has-err-msg">{this.state.Errors['UrlEmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">Color</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_Color'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Color" ref="Color" type="text" defaultValue={this.state.PartnerMdl.color ?? ''} id="Color" required />
                                            <label htmlFor="Color">Color</label>
                                            {this.state.Errors['IsEmpty_Color'] === true ? <div className="has-err-msg">{this.state.Errors['ColorEmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">Hover and Focus</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_HoverFocus'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Hover and Focus" ref="HoverFocus" type="text" defaultValue={this.state.PartnerMdl.hoverFocus ?? ''} id="HoverFocus" required />
                                            <label htmlFor="HoverFocus">Hover and Focus</label>
                                            {this.state.Errors['IsEmpty_HoverFocus'] === true ? <div className="has-err-msg">{this.state.Errors['HoverFocusEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="input-wrapper mx-auto mb-3">
                                            {/*<input className={this.state.Errors['IsEmpty_Image'] === true ? 'has-err' : 'uploadLogo'} type="file" name="myImage" onChange={this.onImageChange} />*/}
                                            {/*<img src={this.state.image} />*/}
                                            {/*{this.state.Errors['IsEmpty_Image'] === true ? <div className="has-err-msg">{this.state.Errors['ImageEmptyMsg']}</div> : ''}*/}
                                            <div>
                                                <p>Please upload a Logo with less than 300 * 300 Pixels</p>
                                                <input className={this.state.Errors['IsEmpty_Image'] === true ? 'has-err' : 'uploadLogo'} type="file" accept="image/*" onChange={this.onSelectFile} />
                                                {this.state.Errors['IsEmpty_Image'] === true ? <div className="has-err-msg">{this.state.Errors['ImageEmptyMsg']}</div> : ''}
                                            </div>
                                            <div>
                                                {src && (
                                                    <ReactCrop
                                                        src={src}
                                                        crop={crop}
                                                        ruleOfThirds
                                                        /*onImageLoaded={e =>this.onImageLoaded(e)}*/
                                                        onComplete={this.onCropComplete}
                                                        onChange={this.onCropChange}
                                                    >
                                                        {this.state.partnerId !== 0 && <img
                                                            alt="Crop Edit"
                                                            src={src}
                                                            className="CroppedImage"
                                                            onLoad={this.onImageLoaded}
                                                        />}
                                                        {this.state.partnerId === 0 && <img
                                                            alt="Crop Add"
                                                            src={src}
                                                            className="CroppedImage"
                                                            onLoad={this.onImageLoaded}
                                                        />}
                                                    </ReactCrop>
                                                )}
                                            </div>

                                            <div>
                                                {croppedImageUrl && (
                                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                                                )}
                                            </div>

                                        </div>
                                        <p className="modal-header-newDesign">Font Family</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_FontFamily'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Font Family" ref="FontFamily" type="text" defaultValue={this.state.PartnerMdl.fontFamily ?? ''} id="FontFamily" required />
                                            <label htmlFor="FontFamily">Font Family</label>
                                            {this.state.Errors['IsEmpty_FontFamily'] === true ? <div className="has-err-msg">{this.state.Errors['FontFamilyEmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">First Payment Label</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_Label1'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="First Payment Label" ref="Label1" type="text" defaultValue={this.state.PartnerMdl.label1 ?? ''} id="Label1" required />
                                            <label htmlFor="Label1">First Payment Label</label>
                                            {this.state.Errors['IsEmpty_Label1'] === true ? <div className="has-err-msg">{this.state.Errors['Label1EmptyMsg']}</div> : ''}
                                        </div>
                                        <p className="modal-header-newDesign">Monthly Payment Label</p>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_Label2'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Monthly Payment Label" ref="Label2" type="text" defaultValue={this.state.PartnerMdl.label2 ?? ''} id="Label2" required />
                                            <label htmlFor="Label2">Monthly Payment Label</label>
                                            {this.state.Errors['IsEmpty_Label2'] === true ? <div className="has-err-msg">{this.state.Errors['Label2EmptyMsg']}</div> : ''}
                                        </div>

                                        {/*<div className="mx-auto mb-3" >*/}
                                        {/*    <div className='input-wrapper-radio' >*/}
                                        {/*        <div className="col-md-6 col-sm-6 col-12">*/}
                                        {/*            <input type="radio" id="radio-ACH" name="PaymentType" value="1" required className="position-absolute top-50 translate-middle-y" />*/}
                                        {/*            <label htmlFor="radio-ACH" className="p-3 ps-5">*/}
                                        {/*                <svg viewBox="0 0 21 21">*/}
                                        {/*                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>*/}
                                        {/*                </svg>*/}
                                        {/*                <h6 className="mb-0">ACH</h6>*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="col-md-6 col-sm-6 col-12">*/}
                                        {/*            <input type="radio" id="radio-CreditCard" name="PaymentType" value="2" required className="position-absolute top-50 translate-middle-y" />*/}
                                        {/*            <label htmlFor="radio-CreditCard" className="p-3 ps-5">*/}
                                        {/*                <svg viewBox="0 0 21 21">*/}
                                        {/*                    <polyline points="5 10.75 8.5 14.25 16 6"></polyline>*/}
                                        {/*                </svg>*/}
                                        {/*                <h6 className="mb-0">Credit Card</h6>*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        <div className="mx-auto mb-3">
                                            <div className="col-12 TermsandConditions-modal-check">
                                                <input type="checkbox" id="isPF" name="paymentMethod" checked={this.state.PartnerMdl.premiumFinance} className={this.state.Errors['IsEmpty_PaymentMethod'] === true ? 'has-err form-check-input form-control' : 'form-check-input form-control'} onChange={this.IsPremiumFinance} />
                                                <label className="form-check-label" style={{ "marginTop": "9px" }}>
                                                    <h6 className="mb-0 AutoPay-label-text">Premium Finance</h6>
                                                </label>
                                            </div>
                                            {this.state.PartnerMdl.premiumFinance &&
                                                <>
                                                    <div className="mx-auto mb-12 text-align-left input-wrapper-payment">
                                                        <div className='input-wrapper-radio remove-bg'>
                                                            <div className="col-12 TermsandConditions-modal-check">
                                                            <input type="radio" id="premium-finance" name="PremiumFinance" checked={this.state.PartnerMdl.isAISPF} className={'form-check-input form-control'} onChange={() => this.handlePfPaymentMethodChange('AISPF')} />
                                                                <label className="form-check-label">
                                                                    <h6 className="mb-0 AutoPay-label-text">AIS Premium Finance</h6>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mx-auto mb-12 text-align-left input-wrapper-payment">
                                                        <div className='input-wrapper-radio remove-bg'>
                                                            <div className="col-12 TermsandConditions-modal-check">
                                                            <input type="radio" id="premium-finance" name="PremiumFinance" checked={this.state.PartnerMdl.isBDSecurePF} className={'form-check-input form-control'} onChange={() => this.handlePfPaymentMethodChange('BDSecurePF')} />
                                                                <label className="form-check-label">
                                                                    <h6 className="mb-0 AutoPay-label-text">BDSecure Premium Finance</h6>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mx-auto mb-12 text-align-left input-wrapper-payment">
                                                        <div className='input-wrapper-radio remove-bg'>
                                                            <div className="col-12 TermsandConditions-modal-check">
                                                            <input type="radio" id="premium-finance" name="PremiumFinance" checked={this.state.PartnerMdl.isFinanceProPF} className={'form-check-input form-control'} onChange={() => this.handlePfPaymentMethodChange('FinanceProPF')} />
                                                                <label className="form-check-label">
                                                                    <h6 className="mb-0 AutoPay-label-text">FinancePro Premium Finance</h6>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-12 TermsandConditions-modal-check">
                                                <input type="checkbox" id="isDB" name="paymentMethod" checked={this.state.PartnerMdl.directBillingFinance} className={this.state.Errors['IsEmpty_PaymentMethod'] === true ? 'has-err form-check-input form-control' : 'form-check-input form-control'} onChange={this.IsDirectBillingmFinance} />
                                                <label className="form-check-label" style={{ "marginTop": "9px" }}>
                                                    <h6 className="mb-0 AutoPay-label-text">Direct Billing</h6>
                                                </label>
                                            </div>
                                            {this.state.PartnerMdl.directBillingFinance &&
                                                <>
                                                    <div className="mx-auto mb-12 text-align-left input-wrapper-payment">
                                                        <div className='input-wrapper-radio remove-bg'>
                                                            <div className="col-12 TermsandConditions-modal-check">
                                                            <input type="radio" id="directBilling-finance" name="DirectBillingFinance" checked={this.state.PartnerMdl.isAISDB} className={'form-check-input form-control'} onChange={() => this.handleDbPaymentMethodChange('AISDB')} />
                                                                <label className="form-check-label">
                                                                    <h6 className="mb-0 AutoPay-label-text">AIS Direct Billing</h6>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {this.state.Errors['IsEmpty_PaymentMethod'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentMethodEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="input-wrapper-payment text-start mx-auto mb-3">
                                            <Select
                                                value={this.state.PartnerMdl.paymentProcessId !== null ? { label: this.state.PartnerMdl.paymentProcess, value: 0 } : selectedPaymentProcess}
                                                defaultValue={this.state.PartnerMdl.paymentProcessId !== null ? { label: this.state.PartnerMdl.paymentProcess, value: 0 } : ''}
                                                onChange={this.handleChange}
                                                options={AllPaymentProcess}
                                                placeholder={'Payment Process'}
                                                closeMenuOnSelect={false}
                                                isSearchable
                                                isDisabled={this.state.PartnerMdl.paymentProcessId !== null ? true : false}
                                                className={(this.state.Errors['IsEmpty_PaymentProcessId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
                                            />
                                            {this.state.Errors['IsEmpty_PaymentProcessId'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentProcessIdEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="d-flex justify-content-center mt-4">
                                            <div className="text-center d-flex justify-content-center">
                                                <button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                <button type="button" className="btn btn-newDesign" onClick={() => this._AddEditPartner()}>Submit</button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                { /*model for delete partner  */}
                <div className="modal fade" id="confirmdelete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title" id="staticBackdropLabel">Delete partner</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body text-center">
                                Do you want to delete this partner ? {this.state.deleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="button-container">
                                <button type="button" id="btncanclemail" className="btn-newDesign-reverse" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" id="btnsendmail" className="btn-newDesign" onClick={() => this._DeletePartnerById()}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default ManagePartnersByXgenOwner;