import React from 'react';
import 'font-awesome/css/font-awesome.min.css'
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
ReactDOM.render(
   <BrowserRouter basename={baseUrl}> 
    <App />
  </BrowserRouter>,
  rootElement);

