import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import ReactPaginate from 'react-paginate';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	GetAllHeaders, GetAllBillingAccountsDetailsByInsured, GetBillingAccountsPending, GetBillingAccountsDue, GetBillingAccountsLate, GetBillingAccountsCancelled, GetAllCommissionPaidandDue,
	GetBillingAccountsByPaymentTerm, GetAgentMasterLists, GetPaymentTermSummaryByInsuredAndAgency, GetBillingAccountDetailsbyAgent, PayoutInfoByPaymentId,
	UpdatePaymentStatus, UpdatePaymentAmount, GetAllPaymentHistory, GetAllPaymentLog
} from '../../../services/ReportService';
import { GetAllReportUsers } from '../../../services/UserServices';
import { Reportsfilters } from '../../../common/Enum';
import { subDomain, Button, showError } from '../../../common/Common';
import enGB from "date-fns/locale/en-GB";
import { LoaderContents } from "../../layout/LoaderContents";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { DateFormat, CustomCurrencyFormat } from '../../../common/Setting';
import CurrencyFormat from 'react-currency-format';

class ReportsFilter extends Component {
	//this for agent user
	constructor(props) {
		super(props);
		this.state = {
			Errors: {},
			AllUserData: [],
			AllUserCommissionData: [],
			reponseMessage: '',
			PageLoaderStatus: false,
			SaveLoaderStatus: false,
			selectedUsers: null,
			searchMdl: {
				GeneralSearch: null,
				LimitTo: 10,
				PageNumber: 1
			},
			Pagecount: 0,
			ModelTitle: null,
			UserProfileMdl: {
				id: null,
				email: null,
				confirmEmail: null,
				flowGroupId: 0,
				userRoleIds: [],
				userInfoMdl: {
					fullName: null
				}
			},
			reportId: 0,
			AllHeadersList: [],
			AllDataLists: [],
			FromDate: null,
			ToDate: null,
			PaymentTerm: 0,
			UsersList: [],
			AgencyAgentId: 0,
			exlorPdf: 0,
			ShowDetails: null,
			PayoutHeaderList: [],
			AllPayoutData: [],
			PaidStatus: "",
			Amount: null,
			PaymentId: null,
			UpdateStatus: [],
			UpdateAmount:[]
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeShowDetails = this.handleChangeShowDetails.bind(this)
	}

	// this is a onload method
	componentDidMount() {
		this.setState({ PageLoaderStatus: true }); // show loader befour load data
		registerLocale("en-GB", enGB);
		const url = new URL(window.location.href)
		const repId = url.searchParams.get('filter')
		this.setState({ reportId: repId });
		let SpName = Reportsfilters[repId];
		if (repId == 11 && subDomain().SubDomainId == 2) {
			SpName = 'RTPaymentHistoryStetson'
		}
		
		this._GetAllHeaders(SpName);
		this._SwitchConditios(this.state.searchMdl, repId, null, null, 0, "0");
	}

	//start funstions for pagination
	handlePageClick = (data) => {
		let PageNumber = data.selected + 1;
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
		this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, this.state.ToDate, this.state.PaymentTerm, this.state.AgencyAgentId);
	}

	handleSearchData = (event) => {
		this.setState({ SaveLoaderStatus: true }); // show loader befour load data
		this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
		this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, this.state.ToDate, this.state.PaymentTerm, this.state.AgencyAgentId);

	}

	handleShowChange = (event) => {
		this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
		this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, this.state.ToDate, this.state.PaymentTerm, this.state.AgencyAgentId);
	}

	//end pagination function
	handleChange = (selectedUsers) => {
		this.setState({ selectedUsers });
	};

	_SwitchConditios = (searchMdl, Id, start, end, paymentterm, agencyagentid) => {
		switch (Id) {
			case '1':
				this._GetAllBillingAccountsDetailsByInsured(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '2':
				this._GetAllBillingAccountsPending(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '3':
				this._GetAllBillingAccountsDue(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '4':
				this._GetAllBillingAccountsLate(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '5':
				this._GetAllBillingAccountsCancelled(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '7':
				this._GetAllCommissionPaidandDue(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '6':
				this._GetAllBillingAccountsByPaymentTerm(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString(), paymentterm);
				return '';
			case '9':
				this._GetAllAgentMasterLists(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '8':
				this._GetAllUsers(subDomain().SubDomainId);
				this._GetPaymentTermSummaryByInsuredAndAgency(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString(), paymentterm, agencyagentid);
				return '';
			case '10':
				this._GetAllUsers(subDomain().SubDomainId);
				this._GetAllBillingAccountDetailsbyAgent(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString(), agencyagentid);
				return '';
			case '11':
				this._GetAllPaymentHistory(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			case '12':
				this._GetAllPaymentLog(searchMdl, subDomain().SubDomainId, new Date(start).toISOString(), new Date(end).toISOString());
				return '';
			default:
				return 'foo';
		}
	}

	_GetAllHeaders = (name) => {
		GetAllHeaders(name).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllHeadersList: response.data });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllBillingAccountsDetailsByInsured = (searchMdl, SubDomainId, start, end) => {
		GetAllBillingAccountsDetailsByInsured(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}
	_GetAllBillingAccountsPending = (searchMdl, SubDomainId, start, end) => {
		GetBillingAccountsPending(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllBillingAccountsDue = (searchMdl, SubDomainId, start, end) => {
		GetBillingAccountsDue(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllBillingAccountsLate = (searchMdl, SubDomainId, start, end) => {
		GetBillingAccountsLate(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllBillingAccountsCancelled = (searchMdl, SubDomainId, start, end) => {
		GetBillingAccountsCancelled(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllCommissionPaidandDue = (searchMdl, SubDomainId, start, end) => {
		GetAllCommissionPaidandDue(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllBillingAccountsByPaymentTerm = (searchMdl, SubDomainId, start, end, paymentterm) => {
		GetBillingAccountsByPaymentTerm(searchMdl, SubDomainId, start, end, paymentterm).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllAgentMasterLists = (searchMdl, SubDomainId, start, end) => {
		GetAgentMasterLists(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetPaymentTermSummaryByInsuredAndAgency = (searchMdl, SubDomainId, start, end, paymenterm, agencyagentid) => {
		GetPaymentTermSummaryByInsuredAndAgency(searchMdl, SubDomainId, start, end, paymenterm, agencyagentid).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}

			}
		});
	}

	_GetAllBillingAccountDetailsbyAgent = (searchMdl, SubDomainId, start, end, agencyagentid) => {
		GetBillingAccountDetailsbyAgent(searchMdl, SubDomainId, start, end, agencyagentid).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllPaymentHistory = (searchMdl, SubDomainId, start, end) => {
		GetAllPaymentHistory(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllDataLists: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetAllPaymentLog = async (searchMdl, SubDomainId, start, end) => {
		await GetAllPaymentLog(searchMdl, SubDomainId, start, end).then(res => {
			let response = res;
			if (response.status === true) {
				this.setState({ AllDataLists: response.data.data });
				this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
				this.setState({ PageLoaderStatus: false }); // hide loader after load data
				this.setState({ SaveLoaderStatus: false });
			} else {
				showError(response.message);
			}
		});
	}

	_PaymentTermChange = (event) => {
		this.setState({ PaymentTerm: event.target.value });
		this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, this.state.ToDate, event.target.value, this.state.AgencyAgentId);
	}

	_ExportExlorPdf = (event) => {
		this.setState({ exlorPdf: event.target.value });
	}

	exportPDF = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "landscape"; // portrait or landscape
		//"pt" | "px" | "in" | "mm" | "cm" | "ex" | "em" | "pc"
		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);
		doc.setFontSize(15);
		doc.autoTable({
			html: '#table-to-xls', columnStyles: {
				0: {
					columnWidth: 63
				},
				1: {
					columnWidth: 63
				},
				2: {
					columnWidth: 63
				},
				3: {
					columnWidth: 63
				},
				4: {
					columnWidth: 63
				},
				5: {
					columnWidth: 63
				},
				6: {
					columnWidth: 63
				},
				7: {
					columnWidth: 63
				},
				8: {
					columnWidth: 63
				},
				9: {
					columnWidth: 63
				},
				10: {
					columnWidth: 63
				},
				11: {
					columnWidth: 63
				},
				12: {
					columnWidth: 63
				},
				13: {
					columnWidth: 63
				},
				14: {
					columnWidth: 63
				},
				15: {
					columnWidth: 63
				}
			}
		})
		doc.save('Report.pdf')
		doc.save("report.pdf")
	}

	_AgencyAgentChange = (event) => {
		this.setState({ AgencyAgentId: event.target.value });
		this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, this.state.ToDate, this.state.PaymentTerm, event.target.value);
	}

	_GetAllUsers = (subDomainId) => {
		GetAllReportUsers(subDomainId).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ UsersList: response.data.data });
				} else {
					showError(response.message);
				}
			}
		});
	}

	handleChangeShowDetails = (event, status) => {
		this.setState({ ShowDetails: event.currentTarget.value });
		this.setState({ PaidStatus: status });
		this._GetHeaders("PayoutInfoByPaymentId")
		this._GetPaymentInfoByPaymentId(event.currentTarget.value);
	}

	_GetHeaders = (name) => {

		GetAllHeaders(name).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ PayoutHeaderList: response.data });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_GetPaymentInfoByPaymentId = (paymentId) => {
		PayoutInfoByPaymentId(paymentId).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ AllPayoutData: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
					this.setState({ PageLoaderStatus: false }); // hide loader after load data
					this.setState({ SaveLoaderStatus: false });
				} else {
					showError(response.message);
				}
			}
		});
	}

	_HandleSelectedChange = (event, Paymentid, amount) => {
		if (event.target.value === "Edit_" + Paymentid) {
			this.setState({ Amount: amount });
			this.setState({ PaymentId: Paymentid });
			window.$("#modalEditAmount").modal('show');
			
		}
		else if (event.target.value === "Approve_" + Paymentid) {
			let statusModel = {
				Id: Paymentid,
				Status:2
			}
			UpdatePaymentStatus(statusModel).then(res => {
				let response = res;
				if (response.status === true) {
					this._GetPaymentInfoByPaymentId(Paymentid.split('-')[1]);
				}
			});
		}
		else if (event.target.value === "Delete_" + Paymentid) {
			let statusModel = {
				Id: Paymentid,
				Status: 1
			}
			UpdatePaymentStatus(statusModel).then(res => {
				let response = res;
				if (response.status === true) {
					this._GetPaymentInfoByPaymentId(Paymentid.split('-')[1]);
				}
			});
		}

	};

	_SetAmount = () => {
		let amountModel = {
			Id: this.state.PaymentId,
			Amount: this.refs.Amount.value
		}
		UpdatePaymentAmount(amountModel).then(res => {
			let response = res;
			if (response.status === true) {
				window.$("#modalEditAmount").modal('hide');
				this._GetPaymentInfoByPaymentId(this.state.PaymentId.split('-')[1]);
			}
		});
	}


	createFileName = () => {
		const testPrefix = "TEST_AISSTETSON995_OTB06";
		const prodPrefix = "AISSTETSON995_OTB06";

		const hostname = window.location.hostname;
		const isProduction = hostname === "app.xgenbilling.com"; // Production environment

		const prefix = isProduction ? prodPrefix : testPrefix;

		const now = new Date();
		const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const day = String(now.getDate()).padStart(2, '0');
		const year = now.getFullYear();
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
		const milliseconds = String(now.getMilliseconds()).padStart(3, '0').slice(0, 2); // First 2 digits of milliseconds

		const date = `${month}${day}${year}`;
		const time = `${hours}${minutes}${seconds}${milliseconds}`;

		const newFileName = `${prefix}_${date}_${time}`; // Unified file name format

		console.log("Generated File Name:", newFileName);
		return newFileName;
	};


	downloadCSV = () => {
		const table = document.getElementById('table-to-xls');
		const rows = Array.from(table.rows);
		const csv = [];

		// Loop through each row and get cell data
		rows.forEach((row) => {
			const cells = Array.from(row.cells);
			const rowData = cells.map((cell) => `"${cell.textContent}"`);
			csv.push(rowData.join(','));
		});

		// Create a Blob and trigger download
		const blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = `${this.createFileName()}.csv`;
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};


	render() {
		const { UsersList } = this.state;
		return (
			<>
				<div className="main-wrapper-newDesign w-100">
					<InternalLayoutHeader />
					<div className="content-main p-lg-4 p-3">
						<div className="row">
							<div className="col-xl-8 col-xxl-8 col-lg-6 col-md-6 col-sm-5 col-12">
								{this.state.reportId === "1" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Account Details By Insured</strong></h1>
								}
								{this.state.reportId === "2" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Pending Billing Accounts</strong></h1>
								}
								{this.state.reportId === "7" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Wallet Reconciliation Report</strong></h1>
								}
								{this.state.reportId === "3" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Accounts Due</strong></h1>
								}
								{this.state.reportId === "4" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Accounts Late</strong></h1>
								}
								{this.state.reportId === "5" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Accounts Cancelled</strong></h1>
								}
								{this.state.reportId === "6" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Accounts Summary By Payment Term</strong></h1>
								}
								{this.state.reportId === "9" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Agent Master List</strong></h1>
								}
								{this.state.reportId === "8" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Payment Term Summary By Insured And Agency</strong></h1>
								}
								{this.state.reportId === "10" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Billing Account Details By Agent</strong></h1>
								}
								{this.state.reportId === "11" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Payment History</strong></h1>
								}
								{this.state.reportId === "12" &&
									<h1 className="h3 mb-0 fw-normal"><strong>Payment Log</strong></h1>
								}
							</div>
							<div className="col-xl-4 col-xxl-4 col-lg-6 col-md-6 col-sm-7 col-12 text-end">
								{/*<button type="button" id="setUserCommission" data-bs-toggle="modal" data-bs-target="#modalSetUserCommission" onClick={() => this._GetAllAgentUser()} className="btn-next center-aligned">*/}
								{/*    <span className="label">Set Commission (%)</span>*/}
								{/*</button>*/}
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12 col-xxl-12">
								<div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign" style={{ overflowX: 'scroll' }}>
									<div className="card-body-tripleRow" style={{ overflow: 'visible' }}>
										<div className="row">
											<div className="col-md-12">
												<div className="card-header bg-white border-white p-2 d-flex flaotcard flex-wrap">
													<select className="selectXL form-select text-search-newDesign" defaultValue="0" onChange={this._ExportExlorPdf}>
														<option value="0" disabled>Export</option>
														<option value="1">PDF</option>
														<option value="2" id="export-excel">EXCEL</option>
													</select>
													{this.state.exlorPdf === "2" &&
													<button type="button" onClick={() => this.downloadCSV()} className="button-download">
															<span className="label">Download</span>
													</button>
													}
													{this.state.exlorPdf === "1" &&
														<button type="button" onClick={() => this.exportPDF()} className="button-download">
															<span className="label">Download</span>
														</button>
													}
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-3 col-md-6 col-sm-6 col-12">
												<div className="card-header bg-white border-white p-2">
													<DatePicker
														selected={this.state.FromDate ?? ''}
														onChange={(fdate) => {
															this.setState({ FromDate: fdate });
															this._SwitchConditios(this.state.searchMdl, this.state.reportId, fdate, this.state.ToDate, this.state.PaymentTerm, this.state.AgencyAgentId)
														}}
														dateFormat="MMM dd, yyyy"
														locale="en-GB"
														ref="FromDate"
														placeholderText="From"
														id="fromdate"
														autoComplete="off"
														className="w-100 form-control text-search-newDesign"
													/>
	
	
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-12">
												<div className="card-header bg-white border-white p-2">
	
													<DatePicker
														selected={this.state.ToDate ?? ''}
														onChange={(tdate) => {
															this.setState({ ToDate: tdate });
															this._SwitchConditios(this.state.searchMdl, this.state.reportId, this.state.FromDate, tdate, this.state.PaymentTerm, this.state.AgencyAgentId)
														}}
														dateFormat="MMM dd, yyyy"
														locale="en-GB"
														ref="ToDate"
														placeholderText="To"
														id="todate"
														autoComplete="off"
														className="w-100 form-control text-search-newDesign"
													/>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-12">
												<div className="card-header bg-white border-white p-2">
													<input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search..." />
													{this.state.SaveLoaderStatus &&
														<span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
													}
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-6 col-12">
												<div className="card-header bg-white border-white p-2">
													<select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
														<option value={this.state.searchMdl.LimitTo} disabled>Show</option>
														<option value="10">10</option>
														<option value="25">25</option>
														<option value="50">50</option>
														<option value="100">100</option>
													</select>
												</div>
											</div>
											{this.state.reportId === "6" &&
												<div className="col-lg-3 col-md-6 col-sm-6 col-12">
													<div className="card-header bg-white border-white p-2">
														<select className="form-select" defaultValue="0" onChange={this._PaymentTermChange}>
															<option value="0" disabled>Payment Term</option>
															<option value="3">3</option>
															<option value="6">6</option>
															<option value="9">9</option>
															<option value="12">12</option>
														</select>
													</div>
												</div>
											}
											{this.state.reportId === "8" &&
												<div className="col-lg-3 col-md-6 col-sm-6 col-12">
													<div className="card-header bg-white border-white p-2">
														<select className="form-select" defaultValue="0" onChange={this._PaymentTermChange}>
															<option value="0" disabled>Payment Term</option>
															<option value="3">3</option>
															<option value="6">6</option>
															<option value="9">9</option>
															<option value="12">12</option>
														</select>
													</div>
												</div>
											}
											{this.state.reportId === "8" &&
												<div className="col-lg-3 col-md-6 col-sm-6 col-12">
													<div className="card-header bg-white border-white p-2">
														<select className="form-select" id="role" defaultValue="0" onChange={this._AgencyAgentChange}>
															<option value="0" disabled>Agency/Agent</option>
															{UsersList.map((items, index) => (
																<option key={index} value={items.id}>{items.userName}</option>
															))}
														</select>
													</div>
												</div>
											}
											{this.state.reportId === "10" &&
												<div className="col-lg-3 col-md-6 col-sm-6 col-12">
													<div className="card-header bg-white border-white p-2">
														<select className="form-select" id="role" defaultValue="0" onChange={this._AgencyAgentChange}>
															<option value="0" disabled>Agency/Agent</option>
															{UsersList.map((items, index) => (
																<option key={index} value={items.id}>{items.userName}</option>
															))}
														</select>
													</div>
												</div>
											}
										</div>
										<div className="row">
											<div className="w-100 table-responsive">
                                            	<table className="table insured-table mb-0" id="table-to-xls">
													<thead>
														<tr>
															{this.state.reportId !== "7" && this.state.reportId !== "11" && this.state.AllHeadersList.map((items) => (
																<th>{items.replaceAll("_", " ")}</th>
															))}
															{this.state.reportId === "11" && this.state.AllHeadersList.map((items) => (
																<th>{items}</th>
															))}
															{this.state.reportId === "7" && this.state.AllHeadersList.map((items) => (
																(items !== 'ConfirmationNumber' && items !== 'Payout_Date' && items !== 'Amount_Payable')
																	? (<th>{items.replaceAll("_", " ")}</th>) : null
															))
															}
															{this.state.reportId === "7" &&
																<th>Action</th>
															}
														</tr>
													</thead>
													<tbody>
														{this.state.reportId === "1" &&
															this.state.AllDataLists.map((items) => (
																<tr>
																	<td>{items.insuredName}</td>
																	<td>{items.insuredEmail}</td>
																	<td>{items.phoneNumber}</td>
																	<td>{items.policyNumber}</td>
																	<td>{items.paymentId}</td>
																	<td>{CustomCurrencyFormat(items.premiumAmount ?? 0)}</td>
																	<td>{items.createdDate}</td>
																	<td>{CustomCurrencyFormat(items.remainingBalance ?? 0)}</td>
																	<td>{items.remainingPayments}</td>
																</tr>
															))
														}
														{this.state.reportId === "11" &&
															this.state.AllDataLists.map((items) => (
																<tr>
																	{subDomain().SubDomainId == 2 &&
																		<>
																			<td>{(items.o_AIS_AGENT ?? 0)}</td>
																			<td>{items.o_INSURED_NAME}</td>
																			<td>{items.o_INSURED_EMAIL}</td>
																			<td>{items.o_POLICY_NUMBER}</td>
																			<td>{items.o_PAYMENT_ID}</td>
																			<td>{items.o_POLICY_PREM_AMT}</td>
																			<td>{items.o_PAYMENT_DATE}</td>
																			<td>{items.o_PAYMENT_AMT}</td>
																			<td>{items.o_PAYMENT_DESC}</td>
																			<td>{items.o_POLICY_FEES_1_DESC}</td>
																			<td>{items.o_POLICY_FEES_1_AMOUNT}</td>
																			<td>{items.o_POLICY_FEES_2_DESC}</td>
																			<td>{items.o_POLICY_FEES_2_AMOUNT}</td>
																			<td>{items.o_PAYMENT_STATUS}</td>
																		</>
																	}
																	{subDomain().SubDomainId == 3 &&
																		<>
																			<td>{items.insuredName}</td>
																			<td>{items.insuredEmail}</td>
																			<td>{items.phoneNumber}</td>
																			<td>{items.policyNumber}</td>
																			<td>{items.paymentId}</td>
																			<td>{CustomCurrencyFormat(items.premiumAmount ?? 0)}</td>
																			<td>{(items.datePaid)}</td>
																			<td>{CustomCurrencyFormat(items.amountPaid ?? 0)}</td>
																			<td>{items.paymentType}</td>
																			<td>{items.methodOfPayment}</td>
																			<td>{CustomCurrencyFormat(items.paymentFees ?? 0)}</td>
																			<td>{items.paymentStatus}</td>
																		</>
																	}
																</tr>
															))
														}
														{this.state.reportId === "12" &&
															
															this.state.AllDataLists.map((items) => (
																<tr>
																	<td>{items.entityId}</td>
																	<td>{items.entityName}</td>
																	<td>{items.prinBrokEntityId}</td>
																	<td>{items.procId}</td>
																	<td>{items.contractId}</td>
																	<td>{items.contractSetId}</td>
																	<td>{items.paymentId}</td>
																	<td>{items.payorName}</td>
																	<td>{items.payorEmail}</td>
																	<td>{CustomCurrencyFormat(items.amountPaid)}</td>
																	<td>{items.methodOfPayment}</td>
																	<td>{DateFormat(items.datePaid)}</td>
																	<td>{items.paymentStatus}</td>
																	<td>{CustomCurrencyFormat(items.premiumAmount)}</td>
																	<td>{CustomCurrencyFormat(items.serviceFee)}</td>
																	<td>{items.paymentStatusId}</td>
																	<td>{items.billingType}</td>
																	<td>{items.billingTypeId}</td>
																	<td>{items.transactionType}</td>
																	<td>{items.transactionTypeId}</td>
																	<td>{items.paymentLogId}</td>
																</tr>
															))
														}

														{(this.state.reportId === "2" || this.state.reportId === "3" || this.state.reportId === "4" || this.state.reportId === "5" || this.state.reportId === "6" || this.state.reportId === "8" || this.state.reportId === "10") &&
															this.state.AllDataLists.map((items) => (
																<tr>
																	<td>{items.insuredName}</td>
																	<td>{items.insuredEmail}</td>
																	<td>{items.phoneNumber}</td>
																	{/*<td>{items.policy_Number}</td>*/}
																	<td>{items.paymentId}</td>
																	<td>{items.termLength}</td>
																	<td>{CustomCurrencyFormat(items.premiumAmount ?? 0)}</td>
																	<td>{items.dueDateOfPayment}</td>
																	{/*<td>{CustomCurrencyFormat(items.monthly_Amount ?? 0)}</td>*/}
																	{/*<td>{CustomCurrencyFormat(items.down_Payment ?? 0)}</td>*/}
																	{/*<td>{items.term_Length}</td>*/}
																	<td>{items.createdDate}</td>
																	<td>{items.createdBy}</td>
																</tr>
															))
	
														}
	
														{(this.state.reportId === "7") &&
															this.state.AllDataLists.map((items) => (
																<>
																	<tr>
																		<td>{items?.transactionId}</td>
																		<td>{items?.agentPhoneNumber}</td>
																		<td>{`${items?.billingAddress}`}</td>
																		<td>{CustomCurrencyFormat(items.amountPaid ?? 0)}</td>
																		<td>{CustomCurrencyFormat(items.amountPayable ?? 0)}</td>
																		<td>{items.status}</td>
																		<td>{items.transactionType}</td>
																		{/*<td>{items?.carrier}</td>*/}
																		{/*<td>{items?.policy_Type}</td>*/}
																		<td>
																			<select onChange={(e) => this.handleChangeShowDetails(e, items.status)}>
																				<option value="">Select</option>
																				<option value={items.transactionId}>View Details</option>
																			</select>
	
																		</td>
																	</tr>
																	{(this.state.ShowDetails === items.transactionId) &&
																		<tr>
																		<td colSpan={10} className="text-start">
																			<table className="table table-borderless">
																					<thead>
																						<tr>
																							{this.state.PayoutHeaderList.map((items) => (
																								<>
																									{items !== "HasAccountSetup" &&
																										<th>{items !== "PayoutDate" ? items.replaceAll("_", " ") : "Payout/Denied Date"}</th>
																									}
	
																								</>
	
																							))
																							}
																							<th>Actions</th>
																						</tr>
																					</thead>
																					<tbody>
	
																						<>
																							{
																								this.state.AllPayoutData.length > 0 ? <>
																									{this.state.AllPayoutData.map((payoutitems, index) => (
	
																										<>
																											<tr>
	
																												<td>{payoutitems.transaction_Id}</td>
																												<td>{payoutitems.payee}</td>
																												{/*<td>{CustomCurrencyFormat(parseFloat(payoutitems.amount_Payable).toFixed(2))}</td>*/}
																												<td>{payoutitems.status == "0" ? "Pending Deposit" : payoutitems.status == 1 ? "Denied" : "Approve"}</td>
																												<td>{payoutitems.payout_Date !== null ? new Date(payoutitems.payout_Date).toLocaleDateString('en-US') : ""}</td>
																												<td>
																													<div className="border-0 d-flex" style={{ width: '160px' }}>
																														<select onChange={(e) => this._HandleSelectedChange(e, payoutitems.transaction_Id, parseFloat(payoutitems.amount_Payable).toFixed(2))}
																															defaultValue={0}
																														>
																															<option value="">Select</option>
																															<option value={"Edit_" + payoutitems.transaction_Id} disabled={payoutitems.status == "2" ? true : false}>Edit</option>
																															<option value={"Delete_" + payoutitems.transaction_Id} disabled={(payoutitems.status != "0") ? true : false}>Denied</option>
																															{this.state.PaidStatus === "Paid" ? <option value={"Approve_" + payoutitems.transaction_Id} disabled={payoutitems.status == "2" ? true : false}>Approve</option> : ""}
																														</select>
																													</div>
																												</td>
	
																											</tr>
	
																											{payoutitems.hasAccountSetup == true ? "" : <>
																												<tr>
																													<td colSpan={6} >*Please setup your deposit account details.</td>
																												</tr>
																											</>
																											}
	
																										</>
																									))
																									}
																								</> : <>
																									<tr>
																										<td colSpan={6} className="table-text-center-align">No Data Found</td>
																									</tr>
																								</>
																							}
	
																						</>
																					</tbody>
																				</table>
																			</td>
																		</tr>
																	}
																</>
															))
														}
														{(this.state.reportId === "9") &&
															this.state.AllDataLists.map((items) => (
																<tr>
																	<td>{items.fullName}</td>
																	<td>{items.emailAddress}</td>
																	<td>{items.phoneNumber}</td>
																	<td>{items.address}</td>
																	<td>{items.createdBy}</td>
																	<td>{items.createdDate}</td>
																	<td>{items.role}</td>
																</tr>
															))
														}
	
													</tbody>
												</table>
												<p className="text-center mt-5">
													{(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
												</p>
												<div className="pt20px">
													{ /*loader show befoure load contents*/}
													{this.state.PageLoaderStatus && <LoaderContents />}
												</div>
											</div>
											<div className="d-flex paginate-div">
												<ReactPaginate
													previousLabel={"Previous"}
													nextLabel={"Next"}
													onPageChange={this.handlePageClick}
													containerClassName={"pagination justify-content-start"}
													pageCount={this.state.Pagecount ?? 0}
													pageClassName={"page-item page-item-none"}
													pageLinkClassName={"page-link"}
													previousClassName={"page-item"}
													previousLinkClassName={"page-link"}
													nextClassName={"page-item"}
													nextLinkClassName={"page-link"}
													breakClassName={"page-item page-item-none"}
													breakLinkClassName={"page-link"}
													activeClassName={"active"}
													disabledLinkClassName={"disabled"} />
													<div className="align-items-end">
														Page {this.state.searchMdl.PageNumber > 0 ? this.state.searchMdl.PageNumber : 1} of {this.state.Pagecount > 0 ? this.state.Pagecount :  1}
													</div>
											</div>	
										</div>
										
									</div>
								</div>
							</div>
						</div>

					</div>
					<SiteFooter /> {/*this call for site footer */}
				</div>
				<div className="modal fade" tabIndex="-1" id="modalEditAmount" aria-labelledby="setUserCommission" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header justify-content-center pt-4 border-0">
								<h5 className="modal-title text-center">{this.state.ModelTitle ?? ""}</h5>
								<button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<div className="pt20px">
									{ /*loader show befoure load contents*/}
									{this.state.SaveLoaderStatus && <LoaderContents />}
								</div>

								<form className="position-relative step-register" id="frmaditamount">
									<fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">

										<div className="input-wrapper mx-auto mb-3">
											<input className={'form-control' + (this.state.Errors['IsEmpty_Amount'] === true ? ' has-err' : '')} placeholder="Amount" ref="Amount" type="Amount" id="Amount" defaultValue={this.state.Amount} required />
											<label htmlFor="amount">Amount</label>
											{this.state.Errors['IsEmpty_Amount'] === true ? <div className="has-err-msg">{this.state.Errors['TypeEmptyMsg']}</div> : ''}
										</div>

										<div className="d-flex justify-content-center mt-4">
											<div className="d-flex ps-3">
												<Button type="button" className="btn-next center-aligned" onClick={this._SetAmount} >
													<span className="label">Submit</span>
												</Button>
											</div>
										</div>
									</fieldset>
								</form>

							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ReportsFilter;