import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

export class ExternalPopupModel extends Component {
   
    
    render() {
        return (
            <>
                <NavLink to="#" className="btn btn-secondary rounded rounded-pill position-fixed btn-bottom-help " title="Help" data-bs-toggle="modal" data-bs-target="#modalHelp"><svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="20" height="20">
                    <g>
                        <path fillRule="evenodd" className="s0" fill="#FFFFFF" d="m20 0c11 0 20 9 20 20c0 11-9 20-20 20c-11 0-20-9-20-20c0-11 9-20 20-20zm0 2.22c-9.78 0-17.78 8-17.78 17.78v0c0 9.78 8 17.78 17.78 17.78h0c9.78 0 17.78-8 17.78-17.78v0c0-9.78-8-17.78-17.78-17.78h0z" />
                    </g>
                    <g>
                        <path className="s0" fill="#FFFFFF" d="m17.9 31.45v-3.59c0-0.2 0.17-0.37 0.37-0.37h3.59c0.2 0 0.37 0.17 0.37 0.37v3.59c0 0.2-0.17 0.37-0.37 0.37h-3.59c-0.2 0-0.37-0.17-0.37-0.37z" />
                        <path className="s0" fill="#FFFFFF" d="m21.91 26.68h0h-4.1c-0.2 0-0.37-0.17-0.37-0.37c0-0.01 0-0.02 0-0.03c-0.01-0.28-0.01-0.51-0.01-0.68c0-0.24-0.01-0.37-0.01-0.37c0-0.68 0.06-1.31 0.17-1.89c0.12-0.58 0.29-1.1 0.53-1.56l0 0l0 0l0 0c0 0 0-0.01 0.01-0.01c0.23-0.46 0.58-0.93 1.04-1.44c0.45-0.49 1.02-1.02 1.69-1.57c0.65-0.53 1.17-0.97 1.57-1.32c0.38-0.34 0.62-0.58 0.73-0.73l0 0c0.01-0.01 0.02-0.02 0.03-0.03c0.16-0.22 0.29-0.45 0.37-0.69c0.09-0.25 0.13-0.52 0.13-0.79c0-0.77-0.31-1.43-0.93-1.99l0 0l0 0l0 0c0 0-0.01-0.01-0.01-0.01c-0.31-0.27-0.68-0.48-1.09-0.62c-0.44-0.14-0.93-0.22-1.49-0.22c-0.53 0-1.02 0.08-1.46 0.23c-0.44 0.15-0.83 0.37-1.18 0.67c-0.35 0.3-0.65 0.67-0.89 1.13c-0.25 0.47-0.44 1.02-0.58 1.66l0 0c-0.04 0.19-0.23 0.32-0.42 0.29l-4.14-0.51l0 0c-0.2-0.03-0.35-0.21-0.32-0.41l0 0c0.06-1 0.31-1.93 0.74-2.78c0.43-0.86 1.04-1.64 1.84-2.34l0.24 0.28l-0.24-0.28c0 0 0.01-0.01 0.01-0.01c0.79-0.7 1.71-1.22 2.75-1.57c1.04-0.35 2.19-0.52 3.46-0.52c1.33 0 2.54 0.18 3.61 0.53c1.08 0.36 2.03 0.89 2.83 1.6c0.8 0.71 1.41 1.48 1.81 2.32c0.41 0.84 0.61 1.74 0.61 2.7c0 0.53-0.08 1.05-0.23 1.56c-0.15 0.5-0.38 0.99-0.68 1.47l-0.31-0.2l0.31 0.2c0 0.01-0.01 0.01-0.01 0.01c-0.3 0.46-0.75 1.01-1.37 1.63c-0.61 0.61-1.38 1.3-2.31 2.08c-0.46 0.39-0.84 0.73-1.13 1.04c-0.27 0.29-0.45 0.54-0.55 0.75c-0.1 0.21-0.17 0.5-0.22 0.89c-0.05 0.42-0.07 0.93-0.06 1.53l0 0c0 0.2-0.16 0.37-0.36 0.38h-0.01z" />
                    </g>
                </svg> Help</NavLink>
                <div className="modal fade" tabIndex="-1" id="modalHelp" aria-labelledby="ModalHelp" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">ASK US ANYTHING</h5>
                                <button type="button" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form className="w-75 m-auto">
                                    <div className="input-wrapper mb-3">
                                        <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Full Name" required />
                                        <label htmlFor="fullName">Full name</label>
                                    </div>
                                    <div className="input-wrapper mb-3">
                                        <input type="email" className="form-control" id="eMail" placeholder="Email Address" required />
                                        <label htmlFor="eMail">Email Address</label>
                                    </div>
                                    <div className="input-wrapper mb-3">
                                        <textarea className="form-control" rows="3" placeholder="How can we help?" id="question" autoComplete="nope" autoCorrect="off" spellCheck="off" name="question" required></textarea>
                                        <label htmlFor="question">How can we help?</label>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-lg px-5">Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
              
            </>
        );
    }
}
