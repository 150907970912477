import {
    PaymentElement
} from '@stripe/react-stripe-js'
import React, { useState, useEffect } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { authHeader, subDomain, showError, ExternalDomain } from '../../../common/Common';
import {AddAgaveCustomer, AgaveAuthRequest, UpdatePaymentIntent} from '../../../services/PaymentServices';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const UserDetails = authHeader();
    const SubDomain = subDomain();
    const [isLoading, setIsLoading] = useState(false);
    const confirmationNumber = Math.floor((Math.random() * 1000000) + 1);
    const subDomainUrl = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
    const isAutoPay = props.props.isAutoPay;
    const isIframe = props.props.isIframe;
    const pi = props.props.payment_intent;
    const pics = props.props.payment_intent_client_secret;
    const PaymentId = props.props.paymentId;
    const policyNumber = props.props.policyNumber;
    const fullName = props.props.fullName;
    const invoiceNumber = props.props.invoiceNumber;
    const isPFPaid = props.props.isPFPaid
    const isDBPaid = props.props.isDBPaid
    const isPaidInFull = props.props.isPaidInFull
    const isPayFull = props.props.isPayFull
    const isDownPayment = props.props.isDownPayment
    const isMonthlyPayment = props.props.isMonthlyPayment
    const isAgavePayment = props.props.isAgavePayment
    const amount = props.props.amount
    const textActive = props.props.textActive
    const emailActive = props.props.emailActive
    const transferGroup = props.props.transferGroup
    const customerId = props.props.customerId
    const insuredId = props.props.insuredId
    const subdomainId = props.props.subdomainId
    const [paymentType, setPaymentType] = useState('CARD');
    const [cardInfo, setCardInfo] = useState({
        cardNumber: '',
        expiry: '',
        cvc: '',
        country: '',
        zip: ''
    });
    const [bankAccountNumber, setBankAccountNumber] = useState();
    const [bankRoutingNumber, setBankRoutingNumber] = useState();
    const [brand, setBrand] = useState('');
    const visa = './assets/Content/images/visa.png';
    const masterCard = './assets/Content/images/masterCard.png';
    const americanExpress = './assets/Content/images/americanExpress.png';
    const discover = '../assets/Content/images/discover.png';
    const JCB = './assets/Content/images/JCB.png';
    const dinersClub = './assets/Content/images/dinersClub.png';
    const allCreditCard = './assets/Content/images/allCreditCard.jpg';
    const [cardLogo, setCardLogo] = useState(allCreditCard);
    const [validationStates, setValidationStates] = useState({
        cardnumber: { isValid: true, message: '' },
        cvc: { isValid: true, message: '' },
        expiry: { isValid: true, message: '' }
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);
        if (isIframe === false) {
            if (subDomainUrl.toLowerCase() === "xgen") {
                let params1 = {
                    pN: policyNumber,
                    fN: fullName,
                    paymentId: PaymentId,
                    textActive: props.props.textActive,
                    emailActive: props.props.emailActive,
                    cN: confirmationNumber,
                    tG: props.props.transferGroup,
                    cId: props.props.customerId,
                    iAp: props.props.isAutoPay,
                    invoiceNumber: invoiceNumber,
                    isAgavePayment: isAgavePayment,
                    uId: insuredId,
                    
                };
                let paymentIntentId = elements._commonOptions.clientSecret.id;
                await UpdatePaymentIntent(paymentIntentId, amount, paymentType);
                let queryString = Object.keys(params1)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
                    .join("&");
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url:  (subDomainUrl.toLowerCase() === "xgen") ?
                    `${window.location.origin}/payment-status?${queryString}` :
                    `${window.location.origin}/${subDomainUrl.trim().toLowerCase()}/payment-status?${queryString}`
                    },
                });

                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                setIsLoading(false);
            }
            else {
                let params1 = {
                    pN: policyNumber,
                    fN: fullName,
                    paymentId: PaymentId,
                    textActive: props.props.textActive,
                    emailActive: props.props.emailActive,
                    cN: confirmationNumber,
                    tG: props.props.transferGroup,
                    cId: props.props.customerId,
                    iAp: props.props.isAutoPay,
                    invoiceNumber: invoiceNumber,
                    isPFPaid: isPFPaid,
                    isDBPaid: isDBPaid,
                    isPaidInFull: isPaidInFull,
                    isPayFull: isPayFull,
                    isDownPayment: isDownPayment,
                    isMonthlyPayment: isMonthlyPayment,
                    isAgavePayment: isAgavePayment,
                    uId: insuredId,
                    Last4: cardInfo.cardNumber.slice(-4),
                    paymentType: paymentType == "CARD" ? "card" : "us_bank_account",
                    bankAccountNumber: bankAccountNumber,
                    bankRoutingNumber: bankRoutingNumber,
                    insuredId: insuredId,
                    amount: amount, //Amount in cents
                };
                let paymentIntentId = elements._commonOptions.clientSecret.id;
                params1.amount = await UpdatePaymentIntent(paymentIntentId, (amount * 100).toFixed(0), paymentType);
                let queryString = Object.keys(params1)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
                    .join("&");
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: (subDomainUrl.toLowerCase() === "xgen") ?
                            `${window.location.origin}/payment-status?${queryString}` :
                            `${window.location.origin}/${subDomainUrl.trim().toLowerCase()}/payment-status?${queryString}`
                    },
                });
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, customer will be redirected to
                // `return_url`. For some payment methods like iDEAL, customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                setIsLoading(false);
            }
        }
        else {
            if (subDomainUrl.toLowerCase() === "xgen") {
                let paymentIntentId = elements._commonOptions.clientSecret.id;
                await UpdatePaymentIntent(paymentIntentId, (amount * 100).toFixed(0), paymentType);
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: '',
                    },
                    redirect: 'if_required'
                });
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                parent.$("iframe#paymentresult").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#paymentresult").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodewoparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodewoparameters").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodeparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodeparameters").attr("src", window.location.origin + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
            }
            else {
                let paymentIntentId = elements._commonOptions.clientSecret.id;
                await UpdatePaymentIntent(paymentIntentId, (amount * 100).toFixed(0), paymentType);
                const { error } = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        // return customer to payment status after a payment,
                        return_url: '',
                    },
                    redirect: 'if_required'
                });
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Otherwise, customer will be redirected to
                // `return_url`. For some payment methods like iDEAL, customer will
                // be redirected to an intermediate site first to authorize the payment, then
                // redirected to the `return_url`.
                if (error) {
                    showError(error.message);
                } else {
                    //showError("An unexpected error occured.");
                }

                parent.$("iframe#paymentresult").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#paymentresult").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodewoparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodewoparameters").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
                parent.$("iframe#iframecodeparameters").each(function (iel, el) {
                    if (el.contentWindow === window) {
                        /*setIsLoading(false);*/
                        parent.$("iframe#iframecodeparameters").attr("src", window.location.origin + '/' + subDomainUrl.toLowerCase() + '/payment-widget-status?pN=' + props.props.policyNumber + '&fN=' + props.props.fullName + '&paymentId=' + props.props.paymentId + '&textActive=' + props.props.textActive + '&emailActive=' + props.props.emailActive + '&cN=' + confirmationNumber + '&tG=' + props.props.transferGroup + '&cId=' + props.props.customerId + '&iAp=' + props.props.isAutoPay + '&payment_intent=' + pi + '&payment_intent_client_secret=' + pics + '&email_address=' + props.props.emailAddress + '&agentId=' + props.props.agentId + '&invoiceNumber=' + props.props.invoiceNumber + '&redirect_status=succeeded');
                    }
                });
            }
        }

    }

    const handleCardInfoChange = (e) => {
        const { name, value } = e.target;

        if (name === 'expiry') {
            let formattedValue = value.replace(/\D/g, ''); // Remove all non-numeric characters

            if (formattedValue.length >= 3) {
                let month = parseInt(formattedValue.slice(0, 2), 10);
                const year = formattedValue.slice(2, 4);

                if (month > 12) {
                    month = (month.toString().charAt(0)).padStart(2, '0') % 12 || 12; // Map values like 13 -> 01, 35 -> 11
                }

                formattedValue = `${String(month).padStart(2, '0')}/${year}`;
            }

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: formattedValue,
            }));
        } else if (name === 'zip') {
            const zipValue = value.replace(/\D/g, '').slice(0, 5);

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: zipValue,
            }));
        } else if (name === 'cardNumber') {
            let formattedValue = value.replace(/\D/g, "");

            formattedValue = formattedValue.replace(/(.{4})/g, "$1 ").trim();
            if (formattedValue.length > 18) {
                formattedValue = formattedValue.substring(0, 19);
            }
            const cardNumberWithoutSpaces = formattedValue.replace(/\s+/g, "");
            setBrand(detectBrand(cardNumberWithoutSpaces));

            // Update the state with the formatted value
            setCardInfo({
                ...cardInfo,
                [name]: formattedValue,
            });
        }else if (name === 'cvc') {
            const cvcValue = value.replace(/\D/g, '').slice(0, 3); // Limit CVC to 3 characters

            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: cvcValue,
            }));
        } else {
            setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                [name]: value,
            }));
        }
    };


    const handlePay = () => {
        _AgavePaymentMdl();

        // Add payment submission logic here
    };

    const detectBrand = (number) => {
        // Visa
        if (/^4/.test(number)) {
            setCardLogo(visa);
            return 'Visa';
        }
        // MasterCard
        if (/^5[1-5]/.test(number) || /^(222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)/.test(number)) {
            setCardLogo(masterCard);
            return 'MasterCard';
        }
        // American Express
        if (/^3[47]/.test(number)) {
            setCardLogo(americanExpress);
            return 'American Express';
        }
        // Discover
        if (/^6(?:011|5[0-9]{2}|4[4-9][0-9]|22[1-9][0-9]{2})/.test(number)) {
            setCardLogo(discover);
            return 'Discover';
        }
        // JCB
        if (/^(?:2131|1800|35\d{3})/.test(number)) {
            setCardLogo(JCB);
            return 'JCB';
        }
        // Diners Club
        if (/^3(?:0[0-5]|[68]\d)/.test(number)) {
            setCardLogo(dinersClub);
            return 'Diners Club';
        }

        setCardLogo(allCreditCard);
        return 'Unknown';
    };

    const _AgavePaymentMdl = async () => {
        const expiryParts = cardInfo.expiry.split('/');
        let cardInformation = {
            cardNumber: (cardInfo.cardNumber).replace(/\s+/g, ""),
            exp_month: expiryParts[0],
            exp_year: expiryParts.length <= 1 ? "" : '20' + expiryParts[1],
            country: cardInfo.country,
            zip: cardInfo.zip,
            cvvNumber: cardInfo.cvc,
            cardBrand: (brand == 'Unknown') ? 'Visa' : brand
        }
        let agavePaymentMdl = {
            amount: paymentType == "CARD" ? amount : amount + 5 , //Amount in cents
            paymentId: PaymentId,
            paymentIntentMethod: paymentType,
            policyNumber: policyNumber,
            fullName: fullName,
            isTextActive: textActive,
            isEmailActive: emailActive,
            confirmationNumber: confirmationNumber.toString(),
            transferGroup: transferGroup,
            logo: null,
            subDomain: null,
            subDomainId: subdomainId,
            customerId: customerId,
            isAutoPay: isAutoPay,
            invoiceNumber: invoiceNumber,
            isPFPaid: isPFPaid,
            isDBPaid: isDBPaid,
            isPaidInFull: isPaidInFull,
            isPayFull: isPayFull,
            isDownPayment: isDownPayment,
            isMonthlyPayment: isMonthlyPayment,
            cardInfo: cardInformation,
            Last4: cardInfo.cardNumber.slice(-4),
            paymentType: paymentType == "CARD" ? "card" : "us_bank_account",
            bankAccountNumber: bankAccountNumber,
            bankRoutingNumber: bankRoutingNumber,
            insuredId: insuredId
        }
        if (UserDetails !== null) {
            agavePaymentMdl.subDomain = SubDomain.SubDomain;
            agavePaymentMdl.logo = SubDomain.LogoPath;
            agavePaymentMdl.subDomainId = parseInt(SubDomain.SubDomainId);
        }
        else {
            agavePaymentMdl.subDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl;
            agavePaymentMdl.logo = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
            agavePaymentMdl.subDomainId = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId;
        }
        if (agavePaymentMdl.paymentType === "us_bank_account") {
            let params1 = {
                pN: policyNumber,
                fN: fullName,
                paymentId: PaymentId,
                cN: confirmationNumber,
                cId: props.props.customerId,
                iAp: props.props.isAutoPay,
                isAgavePayment: isAgavePayment,
                uId: insuredId,
                last4: agavePaymentMdl.Last4,
                bankAccountNumber: agavePaymentMdl.bankAccountNumber,
                amount: agavePaymentMdl.amount,
                paymentType: agavePaymentMdl.paymentType,
            };
            let queryString = Object.keys(params1)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
                .join("&");
            console.log("queryString", queryString)
            AddAgaveCustomer(agavePaymentMdl);
            if (subDomainUrl.toLowerCase() === "xgen") {
                window.location.href = `${window.location.origin}/payment-status?${queryString}`
            }
            else {
                window.location.href = `${window.location.origin}/${subDomainUrl.trim().toLowerCase()}/payment-status?${queryString}`
            }
        }
        else {
            let response = await _agaveAuthRequest(agavePaymentMdl);
            if (response.data.status) {
                let params1 = {
                    pN: policyNumber,
                    fN: fullName,
                    paymentId: PaymentId,
                    cN: confirmationNumber,
                    cId: props.props.customerId,
                    iAp: props.props.isAutoPay,
                    isAgavePayment: isAgavePayment,
                    uId: insuredId,
                    last4: agavePaymentMdl.Last4,
                    bankAccountNumber: agavePaymentMdl.bankAccountNumber,
                    amount: agavePaymentMdl.amount,
                    paymentType: agavePaymentMdl.paymentType,
                };
                let queryString = Object.keys(params1)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params1[key])}`)
                    .join("&");
                console.log("queryString", queryString)
                AddAgaveCustomer(agavePaymentMdl);
                if (subDomainUrl.toLowerCase() === "xgen") {
                    window.location.href = `${window.location.origin}/payment-status?${queryString}`
                }
                else {
                    window.location.href = `${window.location.origin}/${subDomainUrl.trim().toLowerCase()}/payment-status?${queryString}`
                }
            }
            //else {
            //    showError(response.data?.data)
            //}
            AgaveValidation(response.data.data);
        }
    }
    const resetValidation = () => {
        setValidationStates({
            expiry: { isValid: true, message: '' },
            cvc: { isValid: true, message: '' },
            cardnumber: { isValid: true, message: '' }
        });
    };
    
    const _agaveAuthRequest = async (agavePaymentMdl) => {
        resetValidation();
        setIsLoading(true);
        let response = null;
        if (agavePaymentMdl.cardInfo.cardNumber === "" || agavePaymentMdl.cardInfo.exp_month === "" || agavePaymentMdl.cardInfo.exp_year === "" ||  agavePaymentMdl.cardInfo.cvvNumber === "") {
            let error = "";
            if (agavePaymentMdl.cardInfo.cardNumber === "") {
                error += "Credit Card "
            }
            if (agavePaymentMdl.cardInfo.exp_month === "" || agavePaymentMdl.cardInfo.exp_year === "") {
                error += "expired "
            }
            if (agavePaymentMdl.cardInfo.cvvNumber === "") {
                error += "Verification "
            }
            response = {
                data: {
                    data: error,
                    status: false
                }
            }
        }
        else {
            response = await AgaveAuthRequest(agavePaymentMdl);
        }

        setIsLoading(false);
        return response;
    }
    const AgaveValidation = (error) => {
        if(error) {
            if (error.includes("Credit Card") &&  !error.includes("Verification")) {
                setValidationStates(prev => ({
                    ...prev,
                    cardnumber: { isValid: false, message: 'Please check your credit card number and try again' }
                }));
            }
            if (error.includes("expired")) {
                setValidationStates(prev => ({
                    ...prev,
                    expiry: { isValid: false, message: 'Please check your expiration date and try again' }
                }));
            }
            if (error.includes("Verification")) {
                setValidationStates(prev => ({
                    ...prev,
                    cvc: { isValid: false, message: 'Please check your security code and try again' }
                }));
            }
        }
    }
    
    
    const handleChange = (event) => {
        const parsedData = JSON.parse(event.data);
        if (parsedData.message.payload.data.value.type === 'us_bank_account') {
            setPaymentType('ACH');
            props.onPaymentMethodSelect('ACH');
        }
        else {
            setPaymentType('CARD');
            props.onPaymentMethodSelect('CARD');
        }
    }

    var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);

    return (
        <>
            {!isAgavePayment ? (
                <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement  onChange={() => handleChange(event)} id="payment-element" />
                    <Button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary btn-large mt-2">
                        <span id="button-text">
                            {isLoading ? <div className="spinner-border"></div> : "Pay now"}
                        </span>
                    </Button>
                </form>
            ) : (
                <>
                    <div className="payment-types button-container">
                        <button
                            className={paymentType === 'CARD' ? 'active btn-newDesign' : 'btn-newDesign-reverse'}
                            onClick={() => {setPaymentType('CARD')
                            props.onPaymentMethodSelect('CARD');}}
                        >
                            Card
                        </button>
                        <button
                            className={paymentType === 'ACH' ? 'active btn-newDesign' : 'btn-newDesign-reverse'}
                            onClick={() => {setPaymentType('ACH')
                             props.onPaymentMethodSelect('ACH');}}
                        >
                            ACH
                        </button>
                    </div>

                    {paymentType === 'CARD' && (
                        <div className="card-info">
                            <div className="payment-method-top">
                                <div className="col-left">
                                    <p className="modal-header-newDesign">Card Number</p>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            placeholder="Card Number"
                                            name="cardNumber"
                                            value={cardInfo.cardNumber}
                                            onChange={handleCardInfoChange}
                                            className="form-control input-newDesign" 
                                            required
                                        />
                                        <label>Card Number</label>
                                        {!validationStates.cardnumber.isValid && (
                                            <div className="invalid-feedback">
                                                {validationStates.cardnumber.message}
                                            </div>
                                        )}
                                        {/*<div className="cc-logo">*/}
                                        {/*    {cardLogo && <img src={cardLogo} alt="Card logo" className="card-logo" />}*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="payment-method-checkout-forms">
                                <div className="col-left">
                                    <p className="modal-header-newDesign">Expiration Date</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input 
                                            className="form-control input-newDesign" 
                                            placeholder="MM/YY"                                    
                                            type="text"
                                            name="expiry"
                                            maxLength="5"
                                            value={cardInfo.expiry}
                                            onChange={handleCardInfoChange}
                                            required />
                                        <label>Expiration Date</label>
                                        {!validationStates.expiry.isValid && (
                                            <div className="invalid-feedback">
                                                {validationStates.expiry.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-right">
                                    <p className="modal-header-newDesign">Security Code</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input 
                                            className="form-control input-newDesign" 
                                            placeholder="CVC" 
                                            type="text"
                                            name="cvc"
                                            value={cardInfo.cvc}
                                            onChange={handleCardInfoChange}
                                            required
                                        />
                                        <label>CVC</label>
                                        {!validationStates.cvc.isValid && (
                                            <div className="invalid-feedback">
                                                {validationStates.cvc.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="payment-method-checkout-forms">
                                <div className="col-left">
                                    <p className="modal-header-newDesign">Country</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <select
                                            name="country"
                                            value={cardInfo.country}
                                            onChange={handleCardInfoChange}
                                            className="form-select input-newDesign"
                                            required
                                        >
                                            <option value="United States">United States</option>
                                            {/* Add more country options here */}
                                            </select>
                                        {/*<label>Expiration Date</label>*/}
                                    </div>
                                </div>
                                <div className="col-right">
                                    <p className="modal-header-newDesign">Zip Code</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input 
                                            className="form-control input-newDesign" 
                                            placeholder="Zip Code" 
                                            type="text"
                                            name="zip"
                                            value={cardInfo.zip}
                                            onChange={handleCardInfoChange}
                                            required
                                            
                                        />
                                        <label>Zip Code</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {paymentType === 'ACH' && (
                        <div className="card-info">
                            <p className="modal-header-newDesign">Bank Account Number</p>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control input-newDesign" 
                                    placeholder="Bank Account Number"
                                    name="bankAccountNumber"
                                    value={bankAccountNumber}
                                    onChange={(e) => setBankAccountNumber(e.target.value.replace(/\D/g, ''))}
                                    required
                                    />
                                <label>Bank Account Number</label>
                            </div>
                            <p className="modal-header-newDesign">Bank Routing Number</p>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    placeholder="123456789"
                                    className="form-control input-newDesign" 
                                    name="bankRoutingNumber"
                                    value={bankRoutingNumber}
                                    onChange={(e) => setBankRoutingNumber(e.target.value.replace(/\D/g, ''))}
                                    required
                                />
                                <label>Bank Routing Number</label>
                            </div>
                        </div>
                    )}
                    <div className="button-container">
                        <button className="pay-button make-payment-btn btn btn-newDesign-foil"
                                onClick={handlePay}>                        <span id="button-text">
                            {isLoading ? <div className="spinner-border"></div> : "Pay now"}
                        </span></button>

                    </div>

                    <p className="disclaimer">
                        By providing your card information, you allow Automated Installment Solutions to charge your card for future payments in accordance with their terms.
                    </p>
                </>

            )}
        </>
    )
}