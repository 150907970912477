import React, { Component } from 'react';
import { SiteFooter } from '../../layout/SiteFooter';
import { PaymentTypeList } from '../../../common/Enum';
import { GetUserInfoByMailLink } from '../../../services/UserServices';
import validator from 'validator';
import $ from 'jquery';
import { LoaderContents } from "../../layout/LoaderContents";
import { TestMerchandID, TestMerchandURL, CustomCurrencyFormat, DateFormat, stripePromise } from "../../../common/Setting";
import { GetPaymentIntent, SavePaymentLinkIframe, GetPaymentLinksById, CheckPremiumFinance, UpdateAutoPay, GetFPPaymentModel } from '../../../services/PaymentServices';
import { GetAllPartnersByUrl } from '../../../services/PartnerServices';
import CurrencyFormat from 'react-currency-format';
import { showError, authHeader, subDomain, ExternalDomain } from "../../../common/Common";
import { FlowGroups } from '../../../common/Enum';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';

import { GetStripeCustomerNonAuthorized } from '../../../services/PaymentServices';
import { GetAISResponseByPaymentId } from '../../../services/AISServices';
import { Buffer } from 'buffer';
class PaymentWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			UserDetails: authHeader(), // by local storage 
			queryParams: new URLSearchParams(window.location.search),
			mdl: [],
			fullNameParam: '',
			SubDomain: subDomain(),
			Errors: {},
			emailParam: '',
			policyNoParam: '',
			isShowPolicyNumberParam: true,
			isShowPolicy: true,
			invoiceNoParam: '',
			paymentAmountParam: 0.00,
			totalPremium: 0.00,
			MonthlyAmountParam: 0.00,
			DownPaymentParam: 0.00,
			InsuranceCompanyParam: '',
			CoverageTypeParam: '',
			AddressParam: '',
			TermLengthParam: 0,
			AprParam: 0.00,
			ServiceFeeParam: 0.00,
			PaymentExpiryDateParam: '',
			CityParam: '',
			StateParam: '',
			ZipParam: '',
			PhoneNumberParam: '',
			DueDateParam: '',
			PolicyEffectiveDateParam: '',
			UserIdParam: '',
			CreatedByName: '',
			InvoiceNumber: '',
			PaymentAmount: 0.00,
			IsTextActive: false,
			IsTermsandConditions: false,
			isTextAct: false,
			IsAutoPay: true,
			UpdateAutoPayMdl: {
				IsAutoPay: true,
				PaymentId: ''
			},
			SaveLoaderStatus: false,
			LoaderStatus: false,
			paymentdetailsvalues: {
				policyNumber: null,
				textActive: null,
				paymentId: null,
				fullName: null,
				transferGroup: null,
				subDomainUrl: null,
				color: null,
				hoverFocus: null,
				subdomainid: null,
				customerId: null,
				isAutoPay: null,
				isIframe: true,
				payment_intent: null,
				payment_intent_client_secret: null
			},
			clientSecret: '',
			transferGroup: '',
			customerId: '',
			PaymentDetailsMdl: {},
			Getstripecustomer: true,
			subDomainUrl: null,
			color: null,
			hoverFocus: null,
			subdomainid: null,
			label1: null,
			label2: null,
			PaymentIdResponse: '',
			CustomerDetailMdl: {},
			premiumFinanceAgreementLink: null,
			LogoPathExternal: null,
			SubDomainIdExternal: null,
			ispremiumfinance: false,
			paymentProcessId: 1,
			achMemoId: Math.random().toString().substring(2, 8),
			urlEncodedValue: "",
			fppUrl:""
		}
		this.handleChangePaymentData = this.handleChangePaymentData.bind(this);
	}

	// this is a onload method
	componentDidMount() {
		this.setState({ SaveLoaderStatus: true });
		//check valid phone number formate
		$("input[name='phoneNumber']").keyup(function () {
			$(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1)$2-$3"));
		});
		var iframe = window.location.toString();
		this.setState({ emailParam: new URLSearchParams(iframe).get('user.email') });
		this.setState({ UserIdParam: new URLSearchParams(iframe).get('user.userId') });
		this.setState({ InvoiceNumber: new URLSearchParams(iframe).get('user.invoice_number') });
		this.setState({ PaymentAmount: new URLSearchParams(iframe).get('user.payment_amount') });
		this.setState({ MonthlyAmountParam: new URLSearchParams(iframe).get('user.monthly_amount') });
		this.setState({ DownPaymentParam: new URLSearchParams(iframe).get('user.down_payment') });
		this.setState({ TermLengthParam: new URLSearchParams(iframe).get('user.term_length') });
		this.setState({ fullNameParam: new URLSearchParams(iframe).get('user.full_name') });
		this.setState({ policyNoParam: new URLSearchParams(iframe).get('user.policy_number') });
		this.setState({ CoverageTypeParam: new URLSearchParams(iframe).get('user.coverage_type') });
		this.setState({ AprParam: new URLSearchParams(iframe).get('user.apr') });
		this.setState({ ServiceFeeParam: new URLSearchParams(iframe).get('user.service_fee') });
		this.setState({ PaymentExpiryDateParam: new URLSearchParams(iframe).get('user.payment_expiry_date') });
		this.setState({ paymentAmountParam: new URLSearchParams(iframe).get('user.payment_amount') });
		this.setState({ totalPremium: new URLSearchParams(iframe).get('user.payment_amount') });
		this.setState({ InsuranceCompanyParam: new URLSearchParams(iframe).get('user.insurance_company') });
		this.setState({ AddressParam: new URLSearchParams(iframe).get('user.address') });
		this.setState({ invoiceNoParam: new URLSearchParams(iframe).get('user.invoice_number') });
		this.setState({ CityParam: new URLSearchParams(iframe).get('user.city') });
		this.setState({ StateParam: new URLSearchParams(iframe).get('user.state') });
		this.setState({ ZipParam: new URLSearchParams(iframe).get('user.zip') });
		this.setState({ PhoneNumberParam: new URLSearchParams(iframe).get('user.phone_number') });
		this.setState({ DueDateParam: new URLSearchParams(iframe).get('user.due_date') });
		this.setState({ PolicyEffectiveDateParam: new URLSearchParams(iframe).get('user.policy_effective_date') });


		this.setState({ isShowPolicy: new URLSearchParams(iframe).get('user.isShowPolicy') });
		if (new URLSearchParams(iframe).get('user.policy_number') === null && new URLSearchParams(iframe).get('user.isShowPolicy') === true) {
			this.setState({ isShowPolicyNumberParam: true });
		}
		if (new URLSearchParams(iframe).get('user.policy_number') === null && new URLSearchParams(iframe).get('user.isShowPolicy') === null) {
			this.setState({ isShowPolicyNumberParam: false });
		}
		this._GetUserInfoByMailLink(new URLSearchParams(iframe).get('user.userId'));




	}

	_GetAISResponseByPaymentId(PaymentId) {
		GetAISResponseByPaymentId(PaymentId).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					let res = response.data;
					this.setState({ SaveLoaderStatus: false });
					this.setState({ premiumFinanceAgreementLink: res.premiumFinanceAgreement });
				} else {
					showError(response.message);
				}
			}
		});
	}
	_GetUserInfoByMailLink(userId) {
		GetUserInfoByMailLink(userId).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					let res = response.data;
					this.setState({
						CreatedByName: res.userInfoMdl.fullName, color: res.color, hoverFocus: res.hoverFocus, subdomainid: res.subDomainId,
						subDomainUrl: res.url, label1: res.label1, label2: res.label2, paymentProcessId: res.paymentProcessId
					});
					this._CheckPremiumFinance(res.subDomainId);
					this._CallAISAccountSetupMethod();
				} else {
					showError(response.message);
				}
			}
		});
	}

	_CheckPremiumFinance = async (subDomainId) => {
		CheckPremiumFinance(subDomainId).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					let res = response.data;
					this.setState({ ispremiumfinance: res });
				} else {
					showError(response.message);
				}
			}
		})
	}
	_GetAllPartnersByUrl = (pathname) => {
		GetAllPartnersByUrl(pathname).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					if (response.data !== null) {
						this.setState({ LogoPathExternal: response.data.logo, SubDomainIdExternal: response.data.id });
					}
					else {
						this.setState(Object.assign(this.state.SubDomain, {
							LogoPathExternal: "assets/Content/images/ais-logo.png",
							SubDomainIdExternal: 1
						}));
					}
				}
			}
		});
	}

	_CallAISAccountSetupMethod() {
		var iframe = window.location.toString();
		if (this.state.SubDomain === null) {
			this._GetAllPartnersByUrl((window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "process-payment" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
		}
		let PaymentLinksMdl = {
			InvoiceNumber: new URLSearchParams(iframe).get('user.invoice_number'),
			TermLength: parseInt(new URLSearchParams(iframe).get('user.term_length')),
			Name: new URLSearchParams(iframe).get('user.full_name'),
			PolicyNumber: new URLSearchParams(iframe).get('user.policy_number'),
			CoverageType: new URLSearchParams(iframe).get('user.coverage_type'),
			Address: new URLSearchParams(iframe).get('user.address'),
			City: new URLSearchParams(iframe).get('user.city'),
			State: new URLSearchParams(iframe).get('user.state'),
			Zip: new URLSearchParams(iframe).get('user.zip'),
			InsuranceCompany: new URLSearchParams(iframe).get('user.insurance_company'),
			PhoneNumber: new URLSearchParams(iframe).get('user.phone_number'),
			EmailAddress: new URLSearchParams(iframe).get('user.email'),
			PaymentAmount: new URLSearchParams(iframe).get('user.payment_amount'),
			MonthlyAmount: new URLSearchParams(iframe).get('user.monthly_amount'),
			ServiceFee: new URLSearchParams(iframe).get('user.service_fee'),
			APR: new URLSearchParams(iframe).get('user.apr'),
			DownPayment: new URLSearchParams(iframe).get('user.down_payment'),
			DueDateOfPayment: new URLSearchParams(iframe).get('user.due_date'),
			PolicyEffectiveDate: new URLSearchParams(iframe).get('user.policy_effective_date'),
			SentBy: this.state.CreatedByName,
			SentTo: new URLSearchParams(iframe).get('user.email'),
			SubDomain: (this.state.SubDomain === null ? (window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') === "process-payment" ? "Xgen" : window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')) : this.state.SubDomain.SubDomain),
			Logo: (this.state.SubDomain === null ? this.state.LogoPathExternal : this.state.SubDomain.LogoPath),
			IsExpired: this.state.isExpired,
			SubDomainId: (this.state.SubDomain === null ? this.state.SubDomainIdExternal : this.state.SubDomain.SubDomainId),
			createdBy: new URLSearchParams(iframe).get('user.userId')
		}

		this.setState({ SaveLoaderStatus: true }); // show loader befour  data save
		SavePaymentLinkIframe(PaymentLinksMdl).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState({ PaymentIdResponse: response.data.paymentId });
					GetPaymentLinksById(response.data.paymentId).then(data => {
						let response = data;
						if (response.message !== Error) {
							if (response.status === true) {
								let res = response.data;
								this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: res.isAutoPay }));

								GetStripeCustomerNonAuthorized(response.data.createdFor).then(data => {

									this.setState({ CustomerDetailMdl: data });
									if (this.state.CustomerDetailMdl.cardDetails !== null && this.state.CustomerDetailMdl.cardDetails !== "") {
										this.setState({ Getstripecustomer: true });
									}
									else if (this.state.CustomerDetailMdl.usBankDetails !== null && this.state.CustomerDetailMdl.usBankDetails !== "") {
										this.setState({ Getstripecustomer: true });
									}
									else if (this.state.CustomerDetailMdl.usBankDetails === "" && this.state.CustomerDetailMdl.cardDetails === "") {
										this.setState({ Getstripecustomer: false });
									}
								});
								//Get Payment Intent
								//this._GetPaymentIntent();
							} else {
								showError(response.message);
							}
						}
					});
					this._GetAISResponseByPaymentId(response.data.paymentId);
				}
				else {
					showError(response.message);
				}
				this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
			}
		});
	}
	//form submit on enter key down (Address Next)
	_AddressNext_EnterButton = (event) => {
		let keyCode = event.which || event.keyCode;
		if (keyCode === 13) {
			this.onButtonEvent("AddressNext");
		};
	};
	//form submit on enter key down (Pay Next)
	_PayNext_EnterButton = (event) => {
		let keyCode = event.which || event.keyCode;
		if (keyCode === 13) {
			this.onButtonEvent("PayNext");
		};
	};
	//form submit and open mdoel on enter key down (Pay)
	_ModelPayment_EnterButton = (event) => {
		let keyCode = event.which || event.keyCode;
		if (keyCode === 13) {
			this._SelectPaymentMethod();

		};
	};

	_encodeUrlValues = () => {
		var urlparamenters = {
			amount: this.state.paymentAmountParam,
			paymentId: this.state.PaymentIdResponse,
			id: this.state.achMemoId,
			policyNumber: this.state.mdl.policyNoParam,
			fullName: this.state.fullNameParam,
			invoiceNumber: this.state.mdl.InvoiceNumber
		};

		const jsonString = JSON.stringify(urlparamenters)
		this.state.urlEncodedValue = Buffer.from(jsonString).toString('base64');
	};

	_GetPaymentIntent = async () => {
		//converting pmtAmount to cents in parameter
		this._encodeUrlValues();
		if (this.state.paymentProcessId === 1) {
			await GetPaymentIntent(this.state.paymentAmountParam * 100, this.state.fullNameParam, this.state.emailParam, "", this.state.UpdateAutoPayMdl).then((res) => {
				this.setState({ clientSecret: { clientSecret: res.clientSecret }, transferGroup: res.transferGroup, customerId: res.customerId });
				this.setState({ SaveLoaderStatus: false });
				this.setState({ LoaderStatus: false });
			});
		}
		else {

			await GetFPPaymentModel(this.state.paymentAmountParam * 100, this.state.fullNameParam, this.state.emailParam, "", this.state.UpdateAutoPayMdl, this.state.UpdateAutoPayMdl, this.state.PaymentIdResponse, this.state.InvoiceNumber, this.state.policyNoParam).then((res) => {
				this.setState.fppUrl = res.url;
				$('#fppmodel').attr('src', this.setState.fppUrl);
				this.setState({ SaveLoaderStatus: false });
				this.setState({ LoaderStatus: false });

			});
		}

	}
	IsAutoPay = (event) => {
		this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: !this.state.IsAutoPay }));
		this.setState(Object.assign(this.state.UpdateAutoPayMdl, { PaymentId: this.state.PaymentIdResponse }));
		this._UpdateAutoPay(this.state.UpdateAutoPayMdl);
	}
	_UpdateAutoPay = (updateAutoPayMdl) => {
		UpdateAutoPay(updateAutoPayMdl).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					this.setState(Object.assign(this.state.UpdateAutoPayMdl, { IsAutoPay: response.data.isAutoPay }));
				} else {
					showError(response.message);
				}
			}
		});
	}
	_SelectPaymentMethod = async () => {
		this.setState({ clientSecret: '' });
		this.setState({ SaveLoaderStatus: true });
		this.setState({ LoaderStatus: true });
		if (this.state.policyNoParam === null) {
			this.setState(Object.assign(this.state.PaymentDetailsMdl, {
				PolicyNumber: ""
			}));
		}
		//else {
		//    this.setState(Object.assign(this.state.PaymentDetailsMdl, {
		//        PolicyNumber: this.refs.PolicyNumber.value
		//    }));
		//}

		let status = this.Validation();
		if (status) {
			await this._GetPaymentIntent();
			if (this.state.paymenetProcessId === 1) {
				var pi = this.state.clientSecret.clientSecret.split("_secret");
				this.setState(Object.assign(this.state.paymentdetailsvalues, {
					//  //policyNumber: this.state.PaymentDetailsMdl.PolicyNumber,
					policyNumber: this.state.policyNoParam,
					textActive: this.state.IsTextActive,
					paymentId: this.state.PaymentIdResponse,
					fullName: this.state.fullNameParam,
					transferGroup: this.state.transferGroup,
					subDomainUrl: this.state.subDomainUrl,
					color: this.state.color,
					hoverFocus: this.state.hoverFocus,
					subdomainid: this.state.subdomainid,
					customerId: this.state.customerId,
					isAutoPay: (this.state.Getstripecustomer === true ? false : this.state.UpdateAutoPayMdl.IsAutoPay),
					payment_intent: pi[0],
					payment_intent_client_secret: this.state.clientSecret.clientSecret,
					emailAddress: this.state.emailParam,
					agentId: this.state.UserIdParam,
					invoiceNumber: this.state.invoiceNoParam,
					isIframe: true,
				}));
			}
			window.$('#modalPayment').modal('show');

		}
	}
	//for select payment amount option
	onChangePaymentAmountOption = (event) => {
		let SelectedOption = parseInt(event.target.value);
		this.setState({ PaymentAmountOption: SelectedOption });
	}
	Validation = () => {
		let errors = {};
		let count = 0;

		if (!this.state.ispremiumfinance) {
			if (!this.state.IsTermsandConditions) {
				errors['IsEmpty_IsTermsandConditions'] = true;
				errors['IsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
				count++;
			}
		}

		if (!this.state.IsTextActive) {
			errors['IsEmpty_IsTextActive'] = true;
			errors['IsTextActiveEmptyMsg'] = "Please agree to recieve automated promotional messages";
			count++;
		}
		this.setState({ Errors: errors });
		if (count === 0) {
			return true;
		}
	}

	// data bind
	handleChangePaymentData(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState(Object.assign(this.state, { [name]: value }));
	}
	//for select payment type
	onChangeValue = (event) => {
		let PaymentType = parseInt(event.target.value);
		this.setState({ PaymentTypeId: PaymentType });
		this.setState({ Errors: {} });
	}

	TermsandConditions = (event) => {

		if (event.target.checked) {
			//window.$('#modalTermsandConditions').modal('show');
			this.setState({ IsTermsandConditions: true });
		}
		else {
			this.setState({ IsTermsandConditions: false });
		}
	}
	PFIsTermsandConditions = (event) => {
		if (event.target.checked) {
			window.$('#modalTermsandConditions').modal('show');
			this.setState({ PFIsTermsandConditions: true });
		}
		else {
			this.setState({ PFIsTermsandConditions: false });
		}
	}

	//select month
	changeMonth = (event) => {
		this.setState({ CreditCardExpiryMonth: event.target.value });
	}
	//check number only
	checkNumber = (event) => {
		let errors = {};
		let value = event.target.value;
		let fld = event.target.name;
		if (!validator.isNumeric(value)) {
			errors['IsEmpty_' + fld] = true;
			errors[fld + 'Msg'] = "Not a valid number";
		}
		this.setState(Object.assign(this.state.Errors, errors));
	}
	//checkNotNull = (event) => {
	//	let errors = {};
	//	let fld = event.target.name;
	//		errors['IsEmpty_' + fld] = false;
	//		errors[fld + 'Msg'] = "";
	//	this.setState(Object.assign(this.state.Errors, errors));
	//}
	//form steps 

	onButtonEvent = (NextStep) => {

		if (NextStep === 'NameBack') {

			$('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
			$('.step-makepayment-fullname').removeClass('form-dismiss').addClass('form-selected');
			$('.step-makepayment-fullname').addClass('form-selected-Out');
			// document.getElementById("step-makepayment-pay").style.display = 'none';
			// document.getElementById("step-makepayment-fullname").style.display = 'block';
		}

		if (NextStep === 'PayNext') {

			this.setState({ paymentAmountParam: parseInt(this.state.DownPaymentParam) + parseInt(this.state.ServiceFeeParam) });
			$('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
			$('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
			// document.getElementById("step-makepayment-pay").style.display = 'block';
			// document.getElementById("step-makepayment-fullname").style.display = 'none';
		}
		if (NextStep === 'PayFull') {
			this.setState({ Errors: {} });
			this.setState({ paymentAmountParam: parseFloat(this.state.totalPremium) + parseFloat(this.state.ServiceFeeParam) });
			$('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
			$('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
		}
		if (NextStep === 'PayMonthly') {
			let errors = {};
			let count = 0;
			if (!this.state.PFIsTermsandConditions) {
				errors['IsEmpty_PFIsTermsandConditions'] = true;
				errors['PFIsTermsandConditionsEmptyMsg'] = "Please agree to terms and condition";
				count++;
			}

			this.setState({ Errors: errors });
			if (count === 0) {
				this.setState({ paymentAmountParam: parseFloat(this.state.DownPaymentParam) + parseFloat(this.state.ServiceFeeParam) });
				$('.step-makepayment-paymentType').removeClass('form-selected').addClass('form-dismiss');
				$('.step-makepayment-pay').removeClass('form-dismiss').addClass('form-selected');
				return true;
			}
		}
		if (NextStep === 'NextPaymentType') {
			$('.step-makepayment-fullname').removeClass('form-selected').addClass('form-dismiss');
			$('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');

			// document.getElementById("step-makepayment-pay").style.display = 'block';
			// document.getElementById("step-makepayment-fullname").style.display = 'none';
		}
		if (NextStep === 'PaymentTypeBack') {
			this.setState({ paymentAmountParam: this.state.totalPremium });
			this.setState({ IsTextActive: false });
			this.setState({ SaveLoaderStatus: false });
			this.setState({ IsTermsandConditions: false });
			this.setState({ PFIsTermsandConditions: false });
			$('.step-makepayment-pay').removeClass('form-selected').addClass('form-dismiss');
			$('.step-makepayment-paymentType').removeClass('form-dismiss').addClass('form-selected');
			$('.step-makepayment-paymentType').addClass('form-selected-Out');
		}
	}

	IsTermsandConditions = (event) => {
		if (event.target.checked) {
			window.$('#modalTermsandConditions').modal('show');
			//this.setState({ IsTermsandConditions: true });
		}
		else {
			this.setState({ IsTermsandConditions: false });
		}
	}

	onTermsandConditionsAccept = () => {
		window.$('#modalTermsandConditions').modal('hide');
		this.setState({ IsTermsandConditions: true });
	}

	IsTextActive = (event) => {
		if (event.target.checked) {
			this.setState({ IsTextActive: true });
		}
		else {
			this.setState({ IsTextActive: false });
		}
	}

	render() {
		var Button = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
		if (this.state.ispremiumfinance) {
			return (
				<>
					<main>
						<div className="container form-space p-space frame-space">
							<div className="row justify-content-md-center">
								<div className="col-md-auto">
									{this.PFPaymentForm()}
								</div>
							</div>
						</div>
					</main>
					<div className="modal fade" tabIndex="-1" id="modalPayment" aria-labelledby="modal Payment methode selection" aria-hidden="true">
						<div className={"modal-dialog modal-dialog-centered " + (this.state.paymentProcessId === 1 ? '' : 'fppwidth')}>
							<div className="modal-content">
								<div className="modal-header justify-content-center pt-4 border-0">
									<h5 className="modal-title text-center">Select Payment Type</h5>
									<button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<div className="pt20px">
										{ /*loader show befoure load contents*/}
										{this.state.LoaderStatus && <LoaderContents />}
									</div>
									<div className="mx - auto mb-3 row Invoicedetails">
										<div className="col-6"><span>Invoice Number : {this.state.invoiceNoParam ?? ''}</span></div>
										<div className="col-6 pull-right"><span>Amount : {CustomCurrencyFormat(this.state.paymentAmountParam) ?? ''}</span></div>
									</div>
									<div className="w-75 m-auto modal-size" id="frmpaymentstatus">
										<div className="mx - auto mb-3" >
											<div className='row payment-element-form'>
												<div className="col-md-12 col-sm-12 col-12 p-0 position-relative">
													{this.state.clientSecret && this.state.paymentProcessId === 1 ?
														<Elements stripe={stripePromise} options={this.state.clientSecret}>
															<CheckoutForm props={this.state.paymentdetailsvalues} />
														</Elements>
														:
														<>
															<div className="card p-3">
																<nav>
																	<div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
																		<button className="nav-link w-50 active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"> Card Payment</button>
																		<button className="nav-link w-50" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">ACH Payment</button>

																	</div>
																</nav>
																<div className="tab-content p-3 border bg-light" id="nav-tabContent">
																	<div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
																		<iframe id="fppmodel" src={this.state.fppUrl}>
																		</iframe>
																	</div>
																	<div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
																		
																		<form id="fppachmodel" action="https://sandbox.fppdashboard.com/ach/public/form" method="GET">
																			<input type="hidden" name="id" value="123" />
																			<div className="py-2">
																				<label className="ach-label">Confirm Amount</label>
																				<input type="text" name="amount" value={this.state.paymentAmountParam} placeholder="" className="form-control ps-1" />
																			</div>
																			<div className="py-2">
																				<label className="ach-label">Memo</label>
																				<input type="text" name="memo" value={this.state.achMemoId} placeholder="Memo" className="form-control ps-1" />
																			</div>


																			<input type="hidden" name="returnURL" value={`https://xgenpayments-new-design.azurewebsites.net/flux/fpp-payment-status?status=complete&params=${this.state.urlEncodedValue}`} />
																			<input type="hidden" name="theme" value="light" />
																			<div className="d-flex justify-content-center mt-2 w-100">
																				<button className="ach-payment-btn" type="submit">Make Payment</button>
																			</div>

																		</form>
																	</div>

																</div>
															</div>
														</>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<>
					<main>
						<div className="container form-space p-space frame-space">
							<div className="row justify-content-md-center">
								<div className="col-md-auto">
									{this.PaymentForm()}
								</div>
							</div>
						</div>
					</main>
					<div className="modal fade" tabIndex="-1" id="modalPayment" aria-labelledby="modal Payment methode selection" aria-hidden="true">
						<div className={"modal-dialog modal-dialog-centered " + (this.state.paymentProcessId === 1 ? '' : 'fppwidth')}>
							<div className="modal-content">
								<div className="modal-header justify-content-center pt-4 border-0">
									<h5 className="modal-title text-center">Select Payment Type</h5>
									<button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<div className="pt20px">
										{ /*loader show befoure load contents*/}
										{this.state.LoaderStatus && <LoaderContents />}
									</div>
									<div className="mx - auto mb-3 row Invoicedetails">
										<div className="col-6"><span>Invoice Number : {this.state.invoiceNoParam ?? ''}</span></div>
										<div className="col-6 pull-right"><span>Amount : {CustomCurrencyFormat(this.state.paymentAmountParam) ?? ''}</span></div>
									</div>
									<div className="w-75 m-auto modal-size" id="frmpaymentstatus">
										<div className="mx - auto mb-3" >
											<div className='row payment-element-form'>
												<div className="col-md-12 col-sm-12 col-12 p-0 position-relative">
													{this.state.clientSecret && this.state.paymentProcessId === 1 ?
														<Elements stripe={stripePromise} options={this.state.clientSecret}>
															<CheckoutForm props={this.state.paymentdetailsvalues} />
														</Elements>
														:
														<>
															<div className="card p-3">
																<nav>
																	<div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
																		<button className="nav-link w-50 active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"> Card Payment</button>
																		<button className="nav-link w-50" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">ACH Payment</button>
																	</div>
																</nav>
																<div className="tab-content p-3 border bg-light" id="nav-tabContent">
																	<div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
																		<iframe id="fppmodel" src={this.state.fppUrl}>
																		</iframe>
																	</div>
																	<div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
																		<form id="fppachmodel" action="https://sandbox.fppdashboard.com/ach/public/form" method="GET">
																			<input type="hidden" name="id" value="123" />
																			<div className="py-2">
																				<label className="ach-label">Confirm Amount</label>
																				<input type="text" name="amount" value={this.state.paymentAmountParam} placeholder="" className="form-control ps-1" />
																			</div>
																			<div className="py-2">
																				<label className="ach-label">Memo</label>
																				<input type="text" name="memo" value={this.state.achMemoId} placeholder="Memo" className="form-control ps-1" />
																			</div>
																			<input type="hidden" name="returnURL" value={`https://xgenpayments-new-design.azurewebsites.net/flux/fpp-payment-status?status=complete&params=${this.state.urlEncodedValue}`} />
																			<input type="hidden" name="theme" value="light" />
																			<div className="d-flex justify-content-center mt-2 w-100">
																				<button className="ach-payment-btn" type="submit">Make Payment</button>
																			</div>

																		</form>
																	</div>

																</div>
															</div>
														</>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade" tabIndex="-1" id="modalTermsandConditions" aria-labelledby="modal Terms and Condtion selection" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modal-content">
								<div className="modal-header pt-4 border-0">
									<h4 className="modal-title text-center">Terms & Conditions</h4>
									<button type="button" id="btncloseMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
								</div>
								<div className="modal-body">
									<div>
										<h5>The fees associated with the payment option and payment method will be disclosed on the portal portrayed as a monthly installment fee.</h5>
										<ul>
											<li>
												If you select the monthly installment option and default on your payment obligation, you hereby irrevocably appoint {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} the power of attorney to cancel your insurance policy and collect
												any unearned premiums or other amounts under your policy directly from the insurance carrier and apply any proceeds from the insurance carrier to any payment obligations that are rightly due to {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain}.</li>
											<li> You agree that {JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain} may assign or transfer any and all of its interest of this agreement and your power of attorney to any other person or entity</li>
										</ul>
									</div>
									<div className="mt-5 d-flex justify-content-center mt-4"><div className="d-flex ps-3"><Button type="button" className="btn-next center-aligned" onClick={() => this.onTermsandConditionsAccept()}><span className="label">Accept</span></Button></div></div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	}


	PaymentForm = () => {
		var Button = ExternalDomain(this.state.color, this.state.hoverFocus, this.state.subdomainid);
		return (
			<>
				<form className="step-form-wrapper position-relative w-75 m-auto step-makepayment-paymentType form-selected">
					<fieldset className="step-form" data-field="paymentType">
						{this.state.MonthlyAmountParam !== 0.00 &&
							<>
								{this.state.mdl.SubDomain === 'Xgen' &&
									<h2 className="mb-5 text-center">How much would you like to pay?</h2>
								}
								<div className="pt20px">
									{ /*loader show befoure load contents*/}
									{this.state.SaveLoaderStatus && <LoaderContents />}
								</div>
								<div className="form-floating input-wrapper-payment input-wrapper-paymentcard mx-auto mb-3">
									<div className="card_wrap row">
										<div className="col-12 col-sm-9 col-md-7 col-lg-6 card p-0">
											<div className="card-header">
												Available until {DateFormat(this.state.PaymentExpiryDateParam ?? '')}
											</div>
											<div className="card-body">
												<div className="card-body-flex">
													<h4 className="card-title">{JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLabel1}</h4>
													<h5 className="card-title">{CustomCurrencyFormat(parseInt(this.state.DownPaymentParam) + parseInt(this.state.ServiceFeeParam))}</h5>
													{this.state.mdl.SubDomain === 'Xgen' &&
														<h4 className="card-title">Pay monthly with Fairmatic</h4>
													}
													<>
														{this.state.mdl.SubDomain !== 'Xgen' &&
															<h4 className="card-title">{this.state.label2}</h4>
														}
														<h5 className="card-title">{CustomCurrencyFormat(this.state.MonthlyAmountParam)}</h5>
														<p className="card-text">per mo. for {this.state.TermLengthParam} mo.</p>
													</>
													<hr />
													<div className="row mb-3">
														{this.state.AprParam !== "0.00" && this.state.AprParam !== null &&
															<><div className="col-md-6 col-sm-12 col-6">APR</div><div className="col-md-6 col-sm-12 col-6">{this.state.AprParam}%</div></>
														}
														{this.state.serviceFee !== "0" && this.state.serviceFee !== null &&
															<><div className="col-md-6 col-sm-12 col-6">Service Fee</div><div className="col-md-6 col-sm-12 col-6">{CustomCurrencyFormat(this.state.ServiceFeeParam)}</div></>
														}
														<div className="col-md-6 col-sm-12 col-6">Full Amount</div>
														<div className="col-md-6 col-sm-12 col-6">{CustomCurrencyFormat(this.state.paymentAmountParam)}</div>
													</div>
												</div>
												<div className="card-body-flex button-flex">
													<Button type="button" className={`btn-next center-aligned `} onClick={() => this.onButtonEvent('PayNext')}>
														<span className="label">Pay Now</span>
														<svg className="icon-arrow after">
															<use xlinkHref="#arrow"></use>
														</svg>
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}


						{/*{this.state.MonthlyAmountParam === 0.00 &&*/}
						{/*    <>*/}
						{/*        {this.state.mdl.SubDomain === 'Xgen' &&*/}
						{/*            <h2 className="mb-5 text-center">How much would you like to pay?</h2>*/}
						{/*        }*/}

						{/*        <div className="form-floating input-wrapper-payment mx-auto mb-3 pay-fullcard">*/}
						{/*            <div className="card_wrap row">*/}
						{/*                <div className="col-12 col-sm-9 col-md-7 col-lg-5 card p-0">*/}
						{/*                    <div className="card-header">*/}
						{/*                        Available until {DateFormat(this.state.PaymentExpiryDateParam ?? '')}*/}
						{/*                    </div>*/}
						{/*                    <div className="card-body">*/}
						{/*                        <div className="card-body-flex">*/}
						{/*                            {this.state.mdl.SubDomain === 'Xgen' &&*/}
						{/*                                <h4 className="card-title">Pay monthly with Fairmatic</h4>*/}
						{/*                            }*/}
						{/*                            <h4 className="card-title">Pay in Full</h4>*/}
						{/*                            <h5 className="card-title">{CustomCurrencyFormat(this.state.paymentAmountParam)}</h5>*/}
						{/*                            <hr />*/}
						{/*                            <div className="row mb-3">*/}
						{/*                                {this.state.AprParam !== 0.00 && this.state.AprParam !== null &&*/}
						{/*                                    <><div className="col-6">APR</div><div className="col-6">{this.state.AprParam}%</div></>*/}
						{/*                                }*/}
						{/*                                */}{/*{this.state.serviceFee !== 0 && this.state.serviceFee !== null &&*/}
						{/*                                */}{/*    <><div className="col-6">Service Fee</div><div className="col-6">{CustomCurrencyFormat(this.state.ServiceFeeParam)}</div></>*/}
						{/*                                */}{/*}*/}
						{/*                            </div>*/}
						{/*                        </div>*/}
						{/*                        <div className="card-body-flex button-flex">*/}
						{/*                            <Button type="button" className={`btn-next center-aligned `} onClick={() => this.onButtonEvent('PayFull')}>*/}
						{/*                                <span className="label">Pay in full</span>*/}
						{/*                                <svg className="icon-arrow after">*/}
						{/*                                    <use xlinkHref="#arrow"></use>*/}
						{/*                                </svg>*/}
						{/*                            </Button>*/}
						{/*                        </div>*/}
						{/*                    </div>*/}
						{/*                </div>*/}
						{/*            </div>*/}
						{/*        </div>*/}
						{/*    </>*/}
						{/*}*/}

						{/*{this.state.mdl.SubDomain === 'Xgen' &&*/}
						{/*    <h2 className="mb-5 text-center">How much would you like to pay?</h2>*/}
						{/*}*/}

					</fieldset>
				</form>


				<form className="step-form-wrapper position-relative w-75 m-auto step-makepayment-pay form-dismiss" onKeyDown={this._ModelPayment_EnterButton}>
					<fieldset className="step-form" data-field="verify" data-next="payement">
						<h2 className="mb-4 text-center card-header">Payment Details</h2>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Insured Name</b></label>: <label>{this.state.fullNameParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Insurance Company</b></label>: <label>{this.state.InsuranceCompanyParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Address</b></label>: <label>{this.state.AddressParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>General Agent</b></label>: <label>{this.state.CreatedByName ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Coverage Type</b></label>: <label>{this.state.CoverageTypeParam ?? ""}</label>
						</div>
						{(this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.Insured) &&
							<div className="mx-auto mb-3 labelInsured">
								<label><b>Phone Number</b></label>: <label>{this.state.mdl.phoneNumber ?? ""}</label>
							</div>
						}
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Email Address</b></label>: <label>{this.state.emailParam ?? ""}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Policy Status</b></label>: <label>{"Pending"}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Policy Effective Date</b></label>: <label>{DateFormat(this.state.PolicyEffectiveDateParam) ?? ""}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Payment Amount</b></label>: <label>{CustomCurrencyFormat(this.state.paymentAmountParam) ?? ''}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Billing Account Number</b></label>: <label>{this.state.invoiceNoParam ?? ""}</label>
						</div>

						<div className="mx-auto mb-12 text-align-left input-wrapper-payment">
							{this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}
							<div className='input-wrapper-radio remove-bg'>
								<div className="col-12 promotional-modal-check">
									<input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err' : '')} onChange={this.IsTextActive} />
									<svg viewBox="0 0 21 21" className="promotion-svg">
										<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
									</svg>
									<label className="form-check-label" style={{ "margin-top": "2px" }}>
										<h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You
											also agree to the <a href={this.state.subDomainUrl + "/termsofuse"}>  Terms of Service</a> and <a href={this.state.subDomainUrl + "/privacypolicy"}> Privacy Policy</a>.
											This agreement isn't a condition of any purchase. 4 Msgs/Month.
											Msg & Data rates may apply. Reply STOP to end or HELP for help.</h6>
									</label>
								</div>
							</div>
						</div>
						<div className="mx-auto mb-12 text-align-left input-wrapper-payment agree-check remove-bg">
							{this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['IsTermsandConditionsEmptyMsg']}</div> : ''}
							<div className='input-wrapper-radio remove-bg' style={{ background: "transparent" }}>
								<div className="col-12 TermsandConditions-modal-check">
									<input type="checkbox" id="radio-Active" name="IsTermsandConditions" checked={this.state.IsTermsandConditions} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_IsTermsandConditions'] === true ? ' has-err' : '')} onChange={this.IsTermsandConditions} />
									<svg viewBox="0 0 21 21" className="promotion-svg">
										<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
									</svg>
									<label className="form-check-label" style={{ "margin-top": "9px" }}>
										<h6 className="mb-0 AutoPay-label-text">You agree to the terms and conditions.</h6>
									</label>
								</div>
							</div>
						</div>
						{this.state.Getstripecustomer === false &&
							<div className="mx-auto mb-12 text-align-left input-wrapper-payment">
								<div className='input-wrapper-radio remove-bg'>
									<div className="col-12 TermsandConditions-modal-check">
										<input type="checkbox" id="radio-AutoPay" name="IsAutoPay" checked={this.state.UpdateAutoPayMdl.IsAutoPay} required className={'form-check-input form-control'} onChange={this.IsAutoPay} />
										<svg viewBox="0 0 21 21" className="promotion-svg">
											<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
										</svg>
										<label className="form-check-label" style={{ "margin-top": "9px" }}>
											<h6 className="mb-0 AutoPay-label-text">Autopay</h6>

										</label>
									</div>
								</div>
							</div>
						}
						<div className="d-flex justify-content-center mt-4 btn-wrap">
							<div className="d-flex pe-3 p-space-0">
								<button type="button" id="PayBack" className={`btn btn-outline-dark center-aligned `} onClick={() => this.onButtonEvent('PaymentTypeBack')}>
									<svg className="icon-arrow before">
										<use xlinkHref="#arrow-left"></use>
									</svg>
									<span className="label">Back</span>
								</button>
							</div>
							<div className="d-flex ps-3  p-space-0">
								<Button type="button" id="MethodofPayment" className={`btn-next center-aligned `} onClick={() => this._SelectPaymentMethod()}>
									<span className="label">Continue to payment</span>
									<svg className="icon-arrow after">
										<use xlinkHref="#arrow"></use>
									</svg>
								</Button>
							</div>
						</div>
					</fieldset>
				</form>
				<div className="d-flex justify-content-center mt-4">
					<svg style={{ display: "none" }}>
						<defs>
							<symbol id="arrow-left" viewBox="0 0 35 15">
								<title>Arrow Left</title>
								<path id="Layer" className="s0" d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
							</symbol>
						</defs>
					</svg>
					<svg style={{ display: "none" }}>
						<defs>
							<symbol id="arrow" viewBox="0 0 35 15">
								<title>Arrow</title>
								<path d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z " />
							</symbol>
						</defs>
					</svg>
				</div>

			</>
		);
	}
	PFPaymentForm = () => {
		var Button = ExternalDomain(this.state.color, this.state.hoverFocus, this.state.subdomainid);
		return (
			<>
				<form className="step-form-wrapper position-relative w-75 m-auto step-makepayment-paymentType form-selected">
					<fieldset className="step-form" data-field="paymentType">
						<div className="pt20px">
							{ /*loader show befoure load contents*/}
							{this.state.SaveLoaderStatus && <LoaderContents />}
						</div>
						{this.state.MonthlyAmountParam !== 0.00 &&
							<>
								{this.state.mdl.SubDomain === 'Xgen' &&
									<h2 className="mb-5 text-center">How much would you like to pay?</h2>
								}

								<div className="form-floating input-wrapper-payment input-wrapper-paymentcard mx-auto mb-3">
									<div className="card_wrap row">
										<div className="col-12 col-sm-9 col-md-7 col-lg-6 card p-0">
											<div className="card-header">
												Available until {DateFormat(this.state.PaymentExpiryDateParam ?? '')}
											</div>
											<div className="card-body">
												<div className="card-body-flex">
													{this.state.mdl.SubDomain === 'Xgen' &&
														<h4 className="card-title">Pay monthly with Fairmatic</h4>
													}
													<>
														{this.state.mdl.SubDomain !== 'Xgen' &&
															<>
																<h4 className="card-title">{this.state.label1}</h4>
																<h5 className="card-title">{CustomCurrencyFormat(parseFloat(this.state.DownPaymentParam) + parseFloat(this.state.ServiceFeeParam))}</h5>
																<h4 className="card-title">{this.state.label2}</h4>
																<h5 className="card-title">{CustomCurrencyFormat(parseFloat(this.state.MonthlyAmountParam) + parseFloat(this.state.ServiceFeeParam))}</h5>
															</>
														}
														<p className="card-text">per mo. for {this.state.TermLengthParam} mo.</p>
													</>
													<hr />
													<div className="row mb-3">
														{this.state.AprParam !== "0.00" && this.state.AprParam !== null &&
															<><div className="col-md-6 col-sm-12 col-12">APR</div><div className="col-md-6 col-sm-12 col-12">{this.state.AprParam}%</div></>
														}
														{this.state.serviceFee !== "0" && this.state.serviceFee !== null &&
															<><div className="col-md-6 col-sm-12 col-12">Service Fee</div><div className="col-md-6 col-sm-12 col-12">{CustomCurrencyFormat(this.state.ServiceFeeParam)}</div></>
														}
														<div className="col-md-6 col-sm-12 col-12">Full Amount</div>
														<div className="col-md-6 col-sm-12 col-12">{CustomCurrencyFormat(this.state.paymentAmountParam)}</div>
													</div>
												</div>
												<div className="col-12">
													{this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? <div className="has-err-msg">{this.state.Errors['PFIsTermsandConditionsEmptyMsg']}</div> : ''}
													<div className='input-wrapper-radio remove-bg'>
														<div className="col-12 TermsandConditions-modal-check">
															<input type="checkbox" id="radio-Active" name="PFIsTermsandConditions" checked={this.state.PFIsTermsandConditions} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_PFIsTermsandConditions'] === true ? ' has-err' : '')} onChange={this.PFIsTermsandConditions} />
															<svg viewBox="0 0 21 21" className="promotion-svg">
																<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
															</svg>
															<label className="form-check-label" style={{ "margin-top": "9px" }}>
																<h6 className="mb-0 AutoPay-label-text">You agree to the terms and conditions described in <a href={this.state.premiumFinanceAgreementLink} target="_blank">Premium Finance Disclosure Agreement</a>.</h6>
															</label>
														</div>
													</div>
												</div>
												<div className="card-body-flex button-flex">
													<Button type="button" className={`btn-next center-aligned `} onClick={() => this.onButtonEvent('PayMonthly')}>
														<span className="label">Pay monthly</span>
														<svg className="icon-arrow after">
															<use xlinkHref="#arrow"></use>
														</svg>
													</Button>
												</div>
											</div>
										</div>
										<div className="col-12 col-sm-9 col-md-7 col-lg-6 card p-0">
											<div className="card-header">
												Available until {DateFormat(this.state.PaymentExpiryDateParam ?? '')}
											</div>
											<div className="card-body">
												<div className="card-body-flex">
													{this.state.mdl.SubDomain === 'Xgen' &&
														<h4 className="card-title">Pay monthly with Fairmatic</h4>
													}
													<h4 className="card-title">Pay in Full</h4>
													<h5 className="card-title">{CustomCurrencyFormat(parseFloat(this.state.paymentAmountParam) + parseFloat(this.state.ServiceFeeParam))}</h5>
													<br />
													<hr />
													<div className="row mb-3">
														{this.state.AprParam !== "0.00" && this.state.AprParam !== null &&
															<><div className="col-md-6 col-sm-12 col-6">APR</div><div className="col-md-6 col-sm-12 col-6">{this.state.AprParam}%</div></>
														}
														{this.state.serviceFee !== "0" && this.state.serviceFee !== null &&
															<><div className="col-md-6 col-sm-12 col-6">Service Fee</div><div className="col-md-6 col-sm-12 col-6">{CustomCurrencyFormat(this.state.ServiceFeeParam)}</div></>
														}
													</div>
												</div>
												<div className="card-body-flex button-flex">
													<Button type="button" className={`btn-next center-aligned `} onClick={() => this.onButtonEvent('PayFull')}>
														<span className="label">Pay in full</span>
														<svg className="icon-arrow after">
															<use xlinkHref="#arrow"></use>
														</svg>
													</Button>
												</div>
											</div>

										</div>
									</div>
								</div>
							</>
						}

						{this.state.MonthlyAmountParam === 0.00 &&
							<>
								{this.state.mdl.SubDomain === 'Xgen' &&
									<h2 className="mb-5 text-center">How much would you like to pay?</h2>
								}
								<div className="form-floating input-wrapper-payment mx-auto mb-3 pay-fullcard">
									<div className="card_wrap row">
										<div className="col-12 col-sm-9 col-md-7 col-lg-6 card p-0">
											<div className="card-header">
												Available until {DateFormat(this.state.PaymentExpiryDateParam ?? '')}
											</div>
											<div className="card-body">
												<div className="card-body-flex">
													{this.state.mdl.SubDomain === 'Xgen' &&
														<h4 className="card-title">Pay monthly with Fairmatic</h4>
													}
													<h4 className="card-title">Pay in Full</h4>
													<h5 className="card-title">{CustomCurrencyFormat(this.state.paymentAmountParam)}</h5>
													<hr />
													<div className="row mb-3">
														{this.state.AprParam !== 0.00 && this.state.AprParam !== null &&
															<><div className="col-md-6 col-sm-12 col-12">APR</div><div className="col-md-6 col-sm-12 col-12">{this.state.AprParam}%</div></>
														}
														{/*{this.state.serviceFee !== 0 && this.state.serviceFee !== null &&*/}
														{/*    <><div className="col-6">Service Fee</div><div className="col-6">{CustomCurrencyFormat(this.state.ServiceFeeParam)}</div></>*/}
														{/*}*/}
													</div>
												</div>
												<div className="card-body-flex button-flex">
													<Button type="button" className={`btn-next center-aligned `} onClick={() => this.onButtonEvent('PayFull')}>
														<span className="label">Pay in full</span>
														<svg className="icon-arrow after">
															<use xlinkHref="#arrow"></use>
														</svg>
													</Button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
						{this.state.mdl.SubDomain === 'Xgen' &&
							<h2 className="mb-5 text-center">How much would you like to pay?</h2>
						}
					</fieldset>
				</form>


				<form className="step-form-wrapper position-relative w-75 m-auto step-makepayment-pay form-dismiss" onKeyDown={this._ModelPayment_EnterButton}>
					<fieldset className="step-form" data-field="verify" data-next="payement">
						<h2 className="mb-4 text-center card-header">Payment Details</h2>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Insured Name</b></label>: <label>{this.state.fullNameParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Insurance Company</b></label>: <label>{this.state.InsuranceCompanyParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Address</b></label>: <label>{this.state.AddressParam ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>General Agent</b></label>: <label>{this.state.CreatedByName ?? ""}</label>
						</div>
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Coverage Type</b></label>: <label>{this.state.CoverageTypeParam ?? ""}</label>
						</div>
						{(this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.Insured) &&
							<div className="mx-auto mb-3 labelInsured">
								<label><b>Phone Number</b></label>: <label>{this.state.mdl.phoneNumber ?? ""}</label>
							</div>
						}
						<div className="mx-auto mb-3 labelInsured">
							<label><b>Email Address</b></label>: <label>{this.state.emailParam ?? ""}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Policy Status</b></label>: <label>{"Pending"}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Policy Effective Date</b></label>: <label>{DateFormat(this.state.PolicyEffectiveDateParam) ?? ""}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Payment Amount</b></label>: <label>{CustomCurrencyFormat(this.state.paymentAmountParam) ?? ''}</label>
						</div>

						<div className="mx-auto mb-3 labelInsured">
							<label><b>Billing Account Number</b></label>: <label>{this.state.invoiceNoParam ?? ""}</label>
						</div>

						<div className="mx-auto mb-12 text-align-left input-wrapper-payment">
							{this.state.Errors['IsEmpty_IsTextActive'] === true ? <div className="has-err-msg">{this.state.Errors['IsTextActiveEmptyMsg']}</div> : ''}
							<div className='input-wrapper-radio remove-bg'>
								<div className="col-12 promotional-modal-check">
									<input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className={'form-check-input form-control' + (this.state.Errors['IsEmpty_IsTextActive'] === true ? ' has-err' : '')} onChange={this.IsTextActive} />
									<svg viewBox="0 0 21 21" className="promotion-svg">
										<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
									</svg>
									<label className="form-check-label" style={{ "margin-top": "2px" }}>
										<h6 className="mb-0 AutoPay-label-text">You agree to recieve automated promotional messages. You
											also agree to the <a href={this.state.subDomainUrl + "/termsofuse"}>  Terms of Service</a> and <a href={this.state.subDomainUrl + "/privacypolicy"}> Privacy Policy</a>.
											This agreement isn't a condition of any purchase. 4 Msgs/Month.
											Msg & Data rates may apply. Reply STOP to end or HELP for help.</h6>

									</label>

								</div>
							</div>
						</div>
						{this.state.getstripecustomer === false &&
							<div className="mx-auto mb-12 text-align-left input-wrapper-payment">
								<div className='input-wrapper-radio remove-bg'>
									<div className="col-12 TermsandConditions-modal-check">
										<input type="checkbox" id="radio-AutoPay" name="IsAutoPay" checked={this.state.UpdateAutoPayMdl.IsAutoPay} required className={'form-check-input form-control'} onChange={this.IsAutoPay} />
										<svg viewBox="0 0 21 21" className="promotion-svg">
											<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
										</svg>
										<label className="form-check-label" style={{ "margin-top": "9px" }}>
											<h6 className="mb-0 AutoPay-label-text">Autopay</h6>
										</label>
									</div>
								</div>
							</div>
						}

						<div className="d-flex justify-content-center mt-4 btn-wrap">
							<div className="d-flex pe-3 p-space-0">
								<button type="button" id="PayBack" className={`btn btn-outline-dark center-aligned `} onClick={() => this.onButtonEvent('PaymentTypeBack')}>
									<svg className="icon-arrow before">
										<use xlinkHref="#arrow-left"></use>
									</svg>
									<span className="label">Back</span>
								</button>
							</div>
							<div className="d-flex ps-3  p-space-0">
								<Button type="button" id="MethodofPayment" className={`btn-next center-aligned `} onClick={() => this._SelectPaymentMethod()}>
									<span className="label">Continue to payment</span>
									<svg className="icon-arrow after">
										<use xlinkHref="#arrow"></use>
									</svg>
								</Button>
							</div>
						</div>
					</fieldset>
				</form>
			</>
		);
	}
}

export default PaymentWidget;