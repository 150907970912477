import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { NavLink } from 'react-router-dom';
import {InternalLayoutHeader} from "../../layout/InternalLayoutHeader";

class TermsOfUse extends Component {
    //this for agent user
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        var SubDomain = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomain;
        return (
            <>
                <main>
                    <InternalLayoutHeader />
                    <div className="main-wrapper-newDesign padding-space">
                        <div className="row justify-content-md-center">
                            <div className="col-md-auto">
                                <div className="contain-main">
                                    <div className="section-gapping">
                                        <div className="container">
                                            <h1 className="termsofuseheader">
                                                {SubDomain} Terms of Use
                                            </h1>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p>Please read this document and our <NavLink to={`${JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainUrl}/privacypolicy`}>Privacy Policy</NavLink> carefully. Your access to the Site is subject to the following terms and conditions (the "Terms"), our Privacy Policy, as well as all applicable laws.</p>
                                                    <p>By visiting and using our Site, you indicate that you agree to be bound by these Terms, and you accept the practices described in our Privacy Policy.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Changes to These Terms</p>
                                                    <p>We may at any time revise these Terms from time to time. Each time changes are made, they will be posted prominently on our Site. Your continued use of the Site following the posting of any changes to the Terms constitutes your acceptance of these changes.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Intellectual Property Rights</p>
                                                    <p>The text, graphics, photographs, videos, sounds, music, logos, trademarks, services marks and other material contained on this Site or presented to this Site (“Material”) are protected by copyright, trademarks, trade secrets, patents, and other intellectual property (“Intellectual Property”) laws, and other applicable laws.</p>
                                                    <p>You are permitted to use the Material only as expressly stated in these Terms.</p>
                                                    <p>We reserve all right, title and interest (including all Intellectual Property Rights) in the Site and it’s Material. In addition, nothing contained in these Terms or on the Site should be construed as granting by implication, estoppel or otherwise, any license or right to use any Material without our written permission.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Non-Commercial Use Only</p>
                                                    <p>You may download, view, use, copy, print, distribute, modify, or transmit, any Material for personal, non-commercial use only.</p>
                                                    <p>You must retain all copyright, trademark, and other proprietary notices included in the Material, on any download, copy, or printout of the Material. Any copy you distribute for any purpose must also include these Terms.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Modifications of Site</p>
                                                    <p>We reserve the right, without notice and for any reason, to remove any Material on this Site, and to deny access of any user to all or any part of the Site.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Inbound Links</p>
                                                    <p>You may link to the homepage of the Site. You may not create any other link to our Site from any other site and you may not frame any portion of our Site, unless you first obtain our written permission. We reserve the right to request that you remove a link to our Site.</p>
                                                    <p>If you link to our Site, the link may not be used in any way that suggests that we endorse you or your website; or that disparages us or affiliates of {SubDomain} Insurance Services. The link may not be used on any website that displays objectionable contents or links.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Prohibited Uses</p>
                                                    <p>Except as specifically stated in these Terms, you may not use, download, upload, copy, modify, print, display, perform, reproduce, republish, license, rent, lease, loan, sell, assign, post, transmit, distribute, reverse engineer, create derivative works or exploit any Material or other part of the Site.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">You may not under any circumstance:</p>
                                                    <ul>
                                                        <li>Send unsolicited commercial emails to the email addresses provided on the Site;</li>
                                                        <li>Delete or modify any of the Material on the Site;</li>
                                                        <li>Use any device or software that interferes with the proper functioning of the Site or servers or networks connected to the Site, or take any other action that interferes with other parties’ use of the Site;</li>
                                                        <li>Use any robot, spider or other process to compile information on the Site for purposes other than for a generally available search engine; or</li>
                                                        <li>Use any of our names, trademarks or service marks without our prior written consent, including, without limitation, as meta tags or hidden text.</li>
                                                    </ul>
                                                    <p>We reserve the right to enforce our Intellectual Property Rights through civil and criminal proceedings.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Privacy and Security</p>
                                                    <p>We will treat any information we collect from you in accordance with our Privacy Notice, which is hereby incorporated by reference.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Submissions</p>
                                                    <p>Apart from your personal information (addressed in our Privacy Notice), any and all communications or materials you send to our Site, electronically or otherwise, including but not limited to data, questions, comments, suggestions, or other submissions (“Submissions”), will be treated as non-confidential.</p>
                                                    <p>
                                                        By sending Submissions to our Site, you grant us a perpetual, royalty free, irrevocable license to (a) use, reproduce, transmit,
                                                        disclose to third parties, display, broadcast, post, or create derivative works from, your Submissions; and (b) use any ideas, concepts, techniques, or know-how contained in your Submissions for any purpose, including but not limited to developing, manufacturing, and marketing products and services. You agree never to assert against us, our successors or assigns, any rights that you may have in such Submissions.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">No Warranties</p>
                                                    <p>There is no warranty that (a) any Material, product or service provided or referenced by this Site will be complete or accurate on a particular date, will not contain typographical errors, will fulfill any of your particular purposes or needs, or will not infringe on any third party rights; (b) the operation of the Site will be error-free or uninterrupted; (c) defects will be corrected; (d) the Site or any emails sent by us or on our behalf will be free of viruses or other harmful devices; (e) the Site will be available to you; (f) you will qualify for products or services offered on the Site; or (g) the products or services offered through the Site are available in all jurisdictions.</p>
                                                    <p>The material, products, and services provided or referenced on this Site are provided “as is” and without any warranties or representations of any kind, express or implied. The entire risk as to satisfactory quality, performance, accuracy and effort is with you.</p>
                                                    <p>Please note that some jurisdictions may not allow the exclusion of implied warranties. Consequently, some of the above exclusions may not apply to you. Please seek the advice of professionals, as appropriate, regarding the evaluation of any specific information, opinion, advice, or other content.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Limitation of Liability</p>
                                                    <p>We assume no liability or responsibility for any errors or omissions in the material or any part of the Site; or any damages from any viruses that may affect your computer equipment or other property; or any other damages on account of your access to, use of, or downloading from, our Site.</p>
                                                    <blockquote><p>IN NO EVENT SHALL WE, OUR AFFILIATES, OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THIS SITE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR DAMAGES FOR COST OF PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES, LOSS OF PROFIT, LOSS OF REVENUE, DATA, GOODWILL OR USE INCURRED BY YOU OR ANY THIRD PARTY, ARISING OUT OF, OR CONNECTED WITH YOUR ACCESS TO, USE OR INABILITY TO USE, OR DELAY IN USE OF OUR SITE, ANY DEFECT, OMISSION, ERROR, INTERRUPTION, OR VIRUS, OR ANY MATERIAL, PRODUCTS, OR SERVICES OBTAINED THROUGH OUR SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY THEORIES, OR OTHERWISE, EVEN IF WE OR ANY OF OUR SERVICE PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</p></blockquote>
                                                    <p>Please note that some jurisdictions may not allow certain limitations or exclusions of liability. Consequently some of the above exclusions may not apply to you. Please seek the advice of professionals, as appropriate, regarding the evaluation of any specific information, opinion, advice, or other content.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Outbound Links</p>
                                                    <p>The Site may provide links to the websites, content, products and services of affiliates, strategic partners, and other third parties. You should refer to the separate terms of use, privacy policies, and other rules posted on these websites before you use them. The existence of such links does not indicate our approval or endorsement of any material contained on any linked site.</p>
                                                    <p>We are not responsible for the content of any website linked to our Site; for any loss or damage you may incur from dealing with any third party found on or through our Site, including your participation in promotions, the payment for and delivery of goods or services, or any request for products or services presented on our site. We are not responsible for any terms, conditions, warranties, or representations associated with such dealings. Your connection to any such third party website is at your own risk.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Availability of Products</p>
                                                    <p>We do not imply that the products or services we present are available for use in jurisdictions in which we are not licensed to do business, or that we are soliciting business in any such jurisdictions. We do not represent that these product or services will remain available to you or that you will qualify to acquire them.</p>
                                                    <p>Anyone interested in a particular product or service should email service@{SubDomain}insurance.com or contact a local {SubDomain} Insurance Representative to find out whether a service or product is available in their area.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Rate Quotes</p>
                                                    <p>Our insurance quotes are verified through extensive testing. Our quotes use information consumers provide on our detailed questionnaires. However, these quotes could change based on additional or contradictory information that emerges during the final insurance underwriting process.</p>
                                                    <p>With respect to insurance purchased through us, your insurance policy, not the statements on our Site, form the contract between you and the company issuing the policy.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Prohibited Uses</p>
                                                    <p>Quotes obtained from our online insurance quote webpage are provided to consumers free of charge. All of the content of the insurance quote pages is made available only for personal, lawful, non-commercial use in connection with seeking insurance quotes and insurance related information.</p>
                                                    <p>Competitor companies and other non-consumer third parties are strictly prohibited from (a) using our Site; (b) posting, transmitting, copying, modifying, creating derivative works from, distributing, selling, or republishing any Material or information obtained or downloaded from the Site; or (c) engaging in systematic retrieval of data or Material from the Site.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="lblHeader">Miscellaneous</p>
                                                    <p>The Terms will be governed by and construed under the laws of the State of California, excluding only its conflict of law provisions.</p>
                                                    <p>You consent to the exclusive jurisdiction and venue of the courts within the City and County of Sacramento, State of California, U.S.A. and waive any jurisdictional venue or inconvenient forum objections to such courts.</p>
                                                    <p>Use of our Site is not permitted in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this paragraph.</p>
                                                    <p>Any failure on our part to enforce any of our rights under the Terms or applicable laws shall not constitute a waiver of such right.</p>
                                                    <p>We may assign our rights and obligations under these Terms to a third party without this assignment being deemed a change in these Terms, and without notice to you.</p>
                                                    <p>No joint venture, partnership, employment, or agency relationship exists between you and us as a result of your use of the Site.</p>
                                                    <p>Our performance under these Terms is subject to existing laws and legal process. We will comply with law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by {SubDomain} Insurance with respect to such use.</p>
                                                    <p>If a court or arbitrator of competent jurisdiction finds any part of these Terms to be invalid, illegal or unenforceable, then the court shall endeavor to give effect to the intent reflected in that provision in a manner that most closely matches the intent of the original provision and the remainder of the Terms shall continue in effect.</p>
                                                    <p>These Terms (including the Privacy Notice) constitute the entire agreement between you and us with respect to our Site. They supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to our Site.</p>
                                                    <p>A printed version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
                                                    <p>Any rights not expressly granted herein are reserved.</p>
                                                    <p><strong>© Copyright 2022, {SubDomain} Insurance Services. All rights reserved.</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <SiteFooter /> {/*this call for site footer */}
                </main>
            </>
        );
    }
}
export default TermsOfUse;