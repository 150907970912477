import React, { Component } from 'react';
import { CheckTokenValidate, ForgetPassword, VerifyLinkByRequestToken } from '../../../services/UserServices';
import { SiteFooter } from '../../layout/SiteFooter';
import { LoaderContents } from "../../layout/LoaderContents";
import { NavLink } from 'react-router-dom';
import validator from 'validator';
import { showError } from '../../../common/Common';
import $ from 'jquery';

class EnterPassCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams : new URLSearchParams(window.location.search),
            email: null,
            RequestToken: null,
            passcode: null,
            SendLoader: false,
            SendingLoader: false,
            IsEmpty_PassCode: '',
            IsEmpty_PassCodeMsg: ''

        }      
    }
    componentWillMount() {
        this.setState({ email: null, RequestToken: null });
    }
    //form submit on enter key down
    _CheckTokenValidate_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._CheckTokenValidate();
        };
    };
    // this is a onload method
    componentDidMount() {   
 // get payment links by id
    if (this.state.queryParams.has("RequestToken")) {
        this.setState({ RequestToken: this.state.queryParams.get("RequestToken") });
        this._VerifyLinkByRequestToken(this.state.queryParams.get("RequestToken") );
    }
    else {
        this.props.history.push('/');
        }

    /* start for 6 digit opt input for passcode*/
        $('.digit-group').find('input').each(function () {
            $(this).attr('maxlength', 1);
            $(this).on('keyup', function (e) {
                var parent = $($(this).parent());
                if (e.keyCode === 8 || e.keyCode === 37) {
                    var prev = parent.find('input#' + $(this).data('previous'));
             
                    if (prev.length) {
                        $(prev).select();
                    }
                } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                    var next = parent.find('input#' + $(this).data('next'));
                    if (next.length) {
                        $(next).select();
                    } else {
                        if (parent.data('autosubmit')) {
                            parent.submit();
                        }
                    }
                }
            });
        });
        /* end for 6 digit opt input for pascode*/
    }
    _VerifyLinkByRequestToken = async (requestToken) => {
        VerifyLinkByRequestToken(requestToken).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ email: response.data.email })
                }
                else {
                    showError(response.message);
                    this.props.history.push('/');
                }
            }
        });
    }
    _ResendForgetPassword = async () => {
        let email = this.state.email;
        this.setState({ SendLoader: false, SendingLoader: true });
            ForgetPassword(email).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        $("#frmpasscode").trigger("reset");
                        this.props.history.replace(`/enter-passcode?RequestToken=${response.data.requestToken}`);
                        this.setState({ SendingLoader: false, SendLoader: true });
                    }
                    else
                    {
                        showError(response.message);
                    }    
                }
            });
    }
    _CheckTokenValidate = () => {
        let passcode = `${this.refs.digit1.value}${this.refs.digit2.value}${this.refs.digit3.value}${this.refs.digit4.value}${this.refs.digit5.value}${this.refs.digit6.value}`;
        if (passcode === null || passcode.trim() === "" || passcode === undefined) {
            this.setState({ IsEmpty_PassCode: true, IsEmpty_PassCodeMsg: 'Please fill the valid fields : PassCode' });
            $("#digit-1").focus();
            return false;
        }
        else if (passcode.length < 6) {
            this.setState({ IsEmpty_PassCode: true, IsEmpty_PassCodeMsg: 'Please enter valid passcode' });
            return false;
        }
        CheckTokenValidate(this.state.RequestToken, passcode).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.props.history.push(`/change-password?RequestToken=${this.state.RequestToken}`);
                } else {
                    this.setState({ IsEmpty_PassCode: true, IsEmpty_PassCodeMsg: response.message });
                   // showError(response.message);

                }  
            }
        });
    }

    render() {
        return (
            <>
                <main>
                    <div className="container form-space margin-top-passcode">
                        <div className="justify-content-md-center">
                            <div className="">

                                <form id="frmpasscode" className="step-form-wrapper position-relative pass-code-section form-selected digit-group" onKeyDown={this._CheckTokenValidate_EnterButton}>
                                    <fieldset id="step-login" className="step-form" data-field="Login" data-next="Dashboard">
                                        <h2 className="mb-3 text-center">Check your email inbox </h2>
                                        <div className="input-wrapper mx-auto mb-3">
                                            <p>We need to verify your email address. We`ve sent an email to {this.state.email} containing a 6 digit code which expires in 15 minutes. Please enter below. </p>
                                        </div>
                                        <div className='form-floating input-wrapper-payment mx-auto mb-3'>
                                            <input type="text" id="digit-1" ref="digit1" name="digit-1" data-next="digit-2" autoComplete="off" />
                                            <input type="text" id="digit-2" ref="digit2" name="digit-2" data-next="digit-3" data-previous="digit-1" autoComplete="off" />
                                            <input type="text" id="digit-3" ref="digit3" name="digit-3" data-next="digit-4" data-previous="digit-2" autoComplete="off" />
                                            <span className="splitter">&ndash;</span>
                                            <input type="text" id="digit-4" ref="digit4" name="digit-4" data-next="digit-5" data-previous="digit-3" autoComplete="off" />
                                            <input type="text" id="digit-5" ref="digit5" name="digit-5" data-next="digit-6" data-previous="digit-4" autoComplete="off" />
                                            <input type="text" id="digit-6" ref="digit6" name="digit-6" data-previous="digit-5" autoComplete="off" />


                                            {/*<input placeholder="Pass code" className="form-control" type="text" ref="passcode" name="passcode" autoComplete="off" id="pass-code" maxLength="6" required />*/}
                                            {/*<label htmlFor="pass-code">Passcode</label>*/}
                                        </div>
                                        {this.state.IsEmpty_PassCode === true ? <div className="has-err-msg input-wrapper-payment mx-auto">{this.state.IsEmpty_PassCodeMsg}</div> : ''}
                                                                            
                                        <div className="d-flex justify-content-center mt-4 mb-3">
                                             <div className="d-flex ps-3">
                                                <button type="button" id="Continue" className="btn btn-newDesign make-payment-btn" onClick={() => this._CheckTokenValidate()}>
                                                    <span className="label">Continue</span>
                                                    <svg className="icon-arrow after">
                                                        <use xlinkHref="#arrow"></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                       

                                    </fieldset>
                                </form>
                                <div className="input-wrapper mx-auto mb-3">
                                    <p>Didn`t receive the email? Try checking your junk or spam folders. </p>
                                    <button onClick={() => this._ResendForgetPassword()} className="button-as-link">Resend</button>
                                    {this.state.SendLoader && <i className="fa fa-check fa-1x ms-2 text-success"></i>}
                                    {this.state.SendingLoader && <i className="fa fa-refresh ms-2 fa-spin fa-1x"></i>}
                                </div>
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow-left" viewBox="0 0 35 15">
                                            <title>Arrow Left</title>
                                            <path id="Layer" className="s0" d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
                                        </symbol>
                                    </defs>
                                </svg>
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow" viewBox="0 0 35 15">
                                            <title>Arrow</title>
                                            <path d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z " />
                                        </symbol>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </main>
                <SiteFooter /> {/*this call for site footer */}
             
            </>
        );
    }
}
export default EnterPassCode;