import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import {LoaderContents} from "../../layout/LoaderContents";

class AISSupportViewBillingStatements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 25,
                PageNumber: 1
            },
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
        }
    }

    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
    }

    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
    }
    render() {
        return (
            <>
                <div className="main-wrapper-newDesign account-overview w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <h1 className="h3 mb-0 fw-normal"><strong>View Billing Statements</strong></h1>
                        <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                            <div className="card-body">
                                <div className="dashboard-options">
                                    <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                        <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Description" />
                                        {this.state.SaveLoaderStatus &&
                                            <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                        }
                                    </div>

                                    <div className="card-header bg-white border-white p-2">
                                        <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                            <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="w-100 table-responsive">
                                    <table className="table insured-table mb-0">
                                        <thead>
                                            <tr>
                                                <th className="Details-table-header">Date</th>
                                                <th className="Details-table-header">Description</th>
                                                <th className="Details-table-header">View Bill</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="Details-table-header">8/28/2023</td>
                                                <td className="Details-table-header">Notice of Intent to Cancel</td>
                                                <td className="Details-table-header">
                                                    <a target="_blank" rel="noopener noreferrer" href="./assets/Content/dummy/Notice-Intent-Cancel-Test.pdf">
                                                        <button type="button" className="btn btn-newDesign">View Bill</button>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="Details-table-header">8/03/2023</td>
                                                <td className="Details-table-header">Notice of Payment Due</td>
                                                <td className="Details-table-header">
                                                    <a target="_blank" rel="noopener noreferrer" href="./assets/Content/dummy/Payment-Due-08-03.pdf">
                                                        <button type="button" className="btn btn-newDesign">View Bill</button>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="Details-table-header">7/03/2023</td>
                                                <td className="Details-table-header">Notice of Payment Due</td>
                                                <td className="Details-table-header">
                                                    <a target="_blank" rel="noopener noreferrer" href="./assets/Content/dummy/Payment-Due-07-03.pdf">
                                                        <button type="button"className="btn btn-newDesign">View Bill</button>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="Details-table-header">6/02/2023</td>
                                                <td className="Details-table-header">Notice of Payment Due</td>
                                                <td className="Details-table-header">
                                                    <a target="_blank" rel="noopener noreferrer" href="./assets/Content/dummy/Payment-Due-06-02.pdf">
                                                        <button type="button" className="btn btn-newDesign">View Bill</button>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />
                </div>
            </>
        );
    }
}
export default AISSupportViewBillingStatements;
